import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaCheck, FaHandPointRight, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

// add hotel gallery

const InclusionExclusionSection = ({
  packageName,
  tourpolicy,
  cancelationpolicy,
  paymentpolicy,
}) => {
  return (
    <>
      <section id="inclu_exclu">
        <Container>
          <div className="common_section include">
            <h2>Inclusion</h2>
            <ul className="bullets">
              {packageName.package_inclusions.map((item, index) => (
                <div className="divvv" key={index}>
                  <span>
                    {" "}
                    <FaCheck className="inclusion_icon" />
                  </span>

                  <li key={index}>{item}</li>
                </div>
              ))}
            </ul>
          </div>
          <div id="exc_exclu" className="common_section exclude">
            <h2>Exclusion</h2>
            <ul className="bullets">
              {packageName.package_exclusions.map((item, index) => (
                <li key={index}>
                  <FaTimes className="exclusion_icon" /> {item}
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </section>
      <section id="hotels" className="common_section">
        <Container>
          <h2>Hotel Details</h2>
          {packageName.hotel.map((hotel) => (
            <div className={`hotel_row hotel_${hotel.id}`} key={hotel.id}>
              <input type="hidden" value="0" className="mprice" />
              <input type="hidden" value={hotel.id} className="hotelsid" />
              <input type="hidden" value="0" className="newhotelsid" />
              <input type="hidden" value="0" className="nprice" />
              <Col lg={12}>
                <Row>
                  <Col sm={2} xs={3} className="padding5px">
                    <div className="packagedethotelimg">
                      <div className="zoomicon">
                        <Image
                          className="lozad"
                          src="https://tripoholidays.com/public/img/zoom.png"
                          alt="zoom"
                          rounded
                        />
                      </div>
                      <Link to="javascript:;" className="gallerypopup">
                        <Image
                          alt={hotel.image_alt}
                          title=""
                          className="ng-scope"
                          src={hotel.image}
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col sm={10} xs={9} className="padding5px">
                    <Row className="row_margin_5">
                      <Col sm={8} className="padding5px">
                        <div className="inner_hotel_pack">
                          <div className="packagelistboxheading">
                            <h3>
                              <Link
                                to="javascript:void(0)"
                                data-id={hotel.id}
                                className="ng-binding hotelcontent">
                                {hotel.name}
                              </Link>
                            </h3>
                          </div>
                          <div className="starmargin ng-binding">
                            {Array.from({ length: hotel.category }, (_, i) => (
                              <Image
                                key={i}
                                src="https://tripoholidays.com/public/img/star.png"
                                alt="Star Rating"
                                title="Star Rating"
                              />
                            ))}
                          </div>
                          <div className="textblack13cont topmargin10px">
                            <span className="textblack13bold ng-scope">
                              City:
                            </span>
                            <span className="textblue13 optionalCategory ng-binding">
                              {hotel.address}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col
                        sm={4}
                        className="padding5px includedtxt text-right ng-binding">
                        <span>Included in trip</span>
                        <Link to=""></Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </div>
          ))}
        </Container>
      </section>
      {/* <section id="price" className="common_section">
        <Container>
          <h2>Price & Dates</h2>
          <div id="holder" className="row"></div>
        </Container>
      </section>
      <section id="flights" className="common_section">
        <Container>
          <h2>Flight Details</h2>

        </Container>
      </section> */}
      <section id="terms" className="common_section">
        <Container>
          {/* <h2>Terms & Condition</h2> */}
          <h2>Tour Policy</h2>
          <ul>
            {tourpolicy.map((term, index) => (
              <div className="divvv" key={index}>
                <span>
                  {" "}
                  <FaHandPointRight />
                </span>

                <li key={index}>{term}</li>
              </div>
            ))}
          </ul>
        </Container>
      </section>
      <section id="terms" className="common_section">
        <Container>
          {/* <h2>Terms & Condition</h2> */}
          <h2>Cancelation Policy</h2>
          <ul>
            {cancelationpolicy.map((term, index) => (
              <div className="divvv" key={index}>
                <span>
                  {" "}
                  <FaHandPointRight />
                </span>

                <li key={index}>{term}</li>
              </div>
            ))}
          </ul>
        </Container>
      </section>
      <section id="terms" className="common_section">
        <Container>
          {/* <h2>Terms & Condition</h2> */}
          <h2>Payment Policy</h2>
          <ul>
            {paymentpolicy.map((term, index) => (
              <div className="divvv" key={index}>
                <span>
                  {" "}
                  <FaHandPointRight />
                </span>

                <li key={index}>{term}</li>
              </div>
            ))}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default InclusionExclusionSection;
