import React, { useState } from "react";
import { Col, Container, Row, Nav, Form, Button } from "react-bootstrap";
import "./AgentRegistration.css";
import { Link } from "react-router-dom";

const AgentRegistration = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const levels = [
    "Internships",
    "Entry level",
    "Junior (1-2 years)",
    "Mid-level (3-4 years)",
    "Senior (5-8 years)",
    "Expert & Team lead (10+ years)",
  ];

  const [selectedLevel, setSelectedLevel] = useState("");

  const handleLevelChange = (level) => {
    setSelectedLevel(level);
  };

  return (
    <div className="registerpg">
      <div className="body-bg formify-quiz-layout-5">
        <section className="formify-form">
          <Container>
            <Row>
              <Col md={12} className="formify-form__acenter">
                <div className="formify-form__layout formify-form__100vh  formify-form__layout--quiz formify-form__layout--quiz--v5 p-0  formify-bg-none">
                  <div className="formify-form__quiz-banner formify-form__quiz-banner--v5 formify-bg-cover formify-form__jcenter formify-form__ccolumn">
                    {/* <Container> */}
                    {/* <Row className="align-items-center"> */}
                    {/* <div
                          // md={4}
                          className="formify-form__quiz-banner-logo formify-form__quiz-banner-logo--v5"
                        >
                          <a href="#">
                            <img
                              src="https://picmaticweb.com/html/form-demo/formify-v2/img/formify-logo.png"
                              alt="#"
                            />
                          </a>
                        </div> */}
                    <div className="formify-form__quiz-banner--content">
                      <h2 className="formify-form__quiz-banner--title">
                        Interested in working with us
                      </h2>
                      <p className="formify-form__quiz-banner--text">
                        We are a leading provider of digital solutions tailored
                        for the hospitality and travel industry. Whether you're
                        managing a hotel, offering transfer services, or
                        curating tour packages, our enterprise solutions are
                        designed to elevate your brand. Providing us with
                        detailed information allows us to offer you the most
                        accurate quotation tailored to your business needs.
                      </p>
                    </div>
                    {/* </Row> */}
                    {/* </Container> */}
                  </div>

                  <div className="formify-form__layout--quiz-main formify-form__layout--quiz-main--v5 formify-bg-cover formify-form__ccolumn">
                    <div class="formify-form__inner--quiz formify-form__inner--quiz--v5">
                      <div class="formify-form__form-box formify-form__form-box--v5">
                        <div
                          className="list-group formify-form__nav"
                          id="list-tab"
                          role="tablist"
                        >
                          <a
                            className={`list-group-item ${
                              step >= 1 && "active"
                            }`}
                            data-bs-toggle="list"
                            href="#step1"
                            role="tab"
                            aria-selected="false"
                            onClick={() => setStep(1)}
                          >
                            Step 1
                          </a>
                          <a
                            className={`list-group-item ${
                              step >= 2 && "active"
                            }`}
                            data-bs-toggle="list"
                            href="#step2"
                            role="tab"
                            aria-selected="false"
                            onClick={() => setStep(2)}
                          >
                            Step 2
                          </a>
                          <a
                            className={`list-group-item ${
                              step >= 3 && "active"
                            }`}
                            data-bs-toggle="list"
                            href="#step3"
                            role="tab"
                            aria-selected="false"
                            onClick={() => setStep(3)}
                          >
                            Step 3
                          </a>
                          <a
                            className={`list-group-item ${
                              step >= 4 && "active"
                            }`}
                            data-bs-toggle="list"
                            href="#step4"
                            role="tab"
                            aria-selected="false"
                            onClick={() => setStep(4)}
                          >
                            Step 4
                          </a>
                          <a
                            className={`list-group-item ${
                              step >= 5 && "active"
                            }`}
                            data-bs-toggle="list"
                            href="#step5"
                            role="tab"
                            aria-selected="false"
                            onClick={() => setStep(5)}
                          >
                            Step 5
                          </a>
                        </div>

                        <Form
                          id="multiStepForm"
                          className="formify-forms formify-forms__quiz formify-forms__quiz--v5 formify-forms--role-form"
                          action="#"
                        >
                          <div className="tab-content">
                            <div
                              className={`tab-pane fade ${
                                step === 1 ? "show active" : ""
                              }`}
                              id="step1"
                            >
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Choose your role
                                </h3>
                                <p className="formify-forms__quiz-text--v5 m-0">
                                  👋 Select Your Partnership Type
                                </p>
                                <div className="formify-forms__quiz-form formify-mg-top-40">
                                  <div className="formify-forms__quiz-form">
                                    <div className="form-group formify-mg-top-15">
                                      <div className="formify-forms__input formify-forms__input--quiz">
                                        <input
                                          className="formify-forms__input d-none"
                                          type="radio"
                                          value=""
                                          id="q-1"
                                          name="q-question"
                                          checked
                                        />
                                        <label
                                          className="formify-forms__input--quiz-label formify-forms__input--role"
                                          htmlFor="q-1"
                                        >
                                          <div className="formify-forms__role">
                                            <img
                                              src="https://picmaticweb.com/html/form-demo/formify-v2/img/formify-role1.svg"
                                              alt="Freelancer"
                                            />
                                            <div className="formify-forms__role-content">
                                              <h4 className="formify-forms__role-title">
                                                Agency Partner
                                              </h4>
                                              <p className="formify-forms__role-text">
                                                You can reach 1,000,000+ Hotels
                                                in 70,000+ destinations
                                              </p>
                                            </div>
                                          </div>
                                          <div className="formify-forms__quiz-check formify-forms__quiz-check--role" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group formify-mg-top-15">
                                      <div className="formify-forms__input formify-forms__input--quiz">
                                        <input
                                          className="formify-forms__input d-none"
                                          type="radio"
                                          value=""
                                          id="q-2"
                                          name="q-question"
                                        />
                                        <label
                                          className="formify-forms__input--quiz-label formify-forms__input--role"
                                          htmlFor="q-2"
                                        >
                                          <div className="formify-forms__role">
                                            <img
                                              src="https://picmaticweb.com/html/form-demo/formify-v2/img/formify-role2.svg"
                                              alt="Talent Hunter"
                                            />
                                            <div className="formify-forms__role-content">
                                              <h4 className="formify-forms__role-title">
                                                Hotel Partner
                                              </h4>
                                              <p className="formify-forms__role-text">
                                                Join a large network of travel
                                                agencies search our inventory.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="formify-forms__quiz-check formify-forms__quiz-check--role" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Row>
                                  <Col className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <div className="form-group">
                                      <div className="formify-forms__spaceb">
                                        <div className="formify-forms__checkbox">
                                          <label
                                            className="m-0"
                                            htmlFor="checkbox"
                                          >
                                            Already a member?{" "}
                                            <Link to="/agent/login">Sign In{" "}</Link>{" "}
                                            here{" "}
                                            {/* <a href="#">Terms of use</a> */}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                step === 2 ? "show active" : ""
                              }`}
                              id="step2"
                            >
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Create your account
                                </h3>
                                <p className="formify-forms__quiz-text--v5 m-0">
                                  Let’s start your dream journey
                                </p>
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <Row>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Agency Name <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Phone No <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter Phone No here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Email <span>*</span>
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              placeholder="Type Email"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      {/* <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input formify-forms__textarea">
                                            <label>
                                              Tell us about what you do
                                            </label>
                                            <textarea
                                              name="description"
                                              placeholder="Enter your tagline..."
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col> */}
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Password <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="first-name"
                                              placeholder="Password"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Confirm Password <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="last-name"
                                              placeholder="Confirm Password"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="form-group">
                                          <div className="formify-forms__spaceb">
                                            <div className="formify-forms__checkbox">
                                              <label
                                                className="m-0"
                                                htmlFor="checkbox"
                                              >
                                                By continuing the next level,
                                                you agree to{" "}
                                                <a href="#">Privacy Policy</a>{" "}
                                                and <a href="#">Terms of use</a>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* <div className="form-group formify-mg-top-40">
                                      <div className="formify-forms__button">
                                        <Button
                                          variant="primary"
                                          className="formify-btn prev-step"
                                          onClick={handlePrev}
                                        >
                                          Previous
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="formify-btn next-step"
                                          onClick={handleNext}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                step === 3 ? "show active" : ""
                              }`}
                              id="step3"
                            >
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Company Details
                                </h3>
                                {/* <p className="formify-forms__quiz-text--v5 m-0">
                                  Let’s start your dream journey
                                </p> */}
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <Row>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Company Name <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter Company Name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Website <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter Website Info here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Address <span>*</span>
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              placeholder="Type Company Address"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      {/* <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input formify-forms__textarea">
                                            <label>
                                              Tell us about what you do
                                            </label>
                                            <textarea
                                              name="description"
                                              placeholder="Enter your tagline..."
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col> */}
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Post Code <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="first-name"
                                              placeholder="Enter post code here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              City <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="last-name"
                                              placeholder="Enter City here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Country <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="last-name"
                                              placeholder="Enter Country here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* <Row>
                                      <Col>
                                        <div className="form-group">
                                          <div className="formify-forms__spaceb">
                                            <div className="formify-forms__checkbox">
                                              <label
                                                className="m-0"
                                                htmlFor="checkbox"
                                              >
                                                By continuing the next level,
                                                you agree to{" "}
                                                <a href="#">Privacy Policy</a>{" "}
                                                and <a href="#">Terms of use</a>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row> */}
                                    {/* <div className="form-group formify-mg-top-40">
                                      <div className="formify-forms__button">
                                        <Button
                                          variant="primary"
                                          className="formify-btn prev-step"
                                          onClick={handlePrev}
                                        >
                                          Previous
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="formify-btn next-step"
                                          onClick={handleNext}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                step === 4 ? "show active" : ""
                              }`}
                              id="step4"
                            >
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Contact Information
                                </h3>
                                {/* <p className="formify-forms__quiz-text--v5 m-0">
                                  Let’s start your dream journey
                                </p> */}
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <Row>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Title <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter title here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Position <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter Position here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              First Name <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter First Name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Last Name <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter Last Name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Email <span>*</span>
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              placeholder="Type Email"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      {/* <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input formify-forms__textarea">
                                            <label>
                                              Tell us about what you do
                                            </label>
                                            <textarea
                                              name="description"
                                              placeholder="Enter your tagline..."
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col> */}
                                      <Col lg={3} md={3}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Country Code <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="first-name"
                                              placeholder="Enter Country Code here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={9} md={9}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Phone No <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="last-name"
                                              placeholder="Enter Phone No here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* <Row>
                                      <Col>
                                        <div className="form-group">
                                          <div className="formify-forms__spaceb">
                                            <div className="formify-forms__checkbox">
                                              <label
                                                className="m-0"
                                                htmlFor="checkbox"
                                              >
                                                By continuing the next level,
                                                you agree to{" "}
                                                <a href="#">Privacy Policy</a>{" "}
                                                and <a href="#">Terms of use</a>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row> */}
                                    {/* <div className="form-group formify-mg-top-40">
                                      <div className="formify-forms__button">
                                        <Button
                                          variant="primary"
                                          className="formify-btn prev-step"
                                          onClick={handlePrev}
                                        >
                                          Previous
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="formify-btn next-step"
                                          onClick={handleNext}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                step === 5 ? "show active" : ""
                              }`}
                              id="step4"
                            >
                              {/* <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Add payment method
                                </h3>
                                <p className="formify-forms__quiz-text--v5 m-0">
                                  Add Credit/Debit Card
                                </p>
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <h4 className="formify-forms__group-title formify-mg-top-30">
                                      Card Type
                                    </h4>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group formify-mg-top-15">
                                          <div className="formify-forms__input formify-forms__input--cards">
                                            <input
                                              className="formify-forms__input d-none"
                                              type="radio"
                                              value=""
                                              id="card-1"
                                              name="q-card"
                                              checked=""
                                            />
                                            <label
                                              className="formify-forms__input--quiz-label m-0"
                                              htmlFor="card-1"
                                            >
                                              <div className="formify-forms__quiz-value">
                                                <img
                                                  src="https://picmaticweb.com/html/form-demo/formify-v2/img/formify-card-icon.svg"
                                                  alt="Credit Card"
                                                />{" "}
                                                Credit Card
                                              </div>
                                              <div className="formify-forms__quiz-check"></div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group formify-mg-top-15">
                                          <div className="formify-forms__input formify-forms__input--cards">
                                            <input
                                              className="formify-forms__input d-none"
                                              type="radio"
                                              value=""
                                              id="card-2"
                                              name="q-card"
                                              checked=""
                                            />
                                            <label
                                              className="formify-forms__input--quiz-label m-0"
                                              htmlFor="card-2"
                                            >
                                              <div className="formify-forms__quiz-value">
                                                <img
                                                  src="https://picmaticweb.com/html/form-demo/formify-v2/img/formify-card-icon.svg"
                                                  alt="Debit Card"
                                                />{" "}
                                                Debit Card
                                              </div>
                                              <div className="formify-forms__quiz-check"></div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <h4 className="formify-forms__group-title formify-mg-top-20">
                                      Card Details
                                    </h4>
                                    <div className="col-12">
                                      <div className="form-group formify-mg-top-10">
                                        <div className="formify-forms__input">
                                          <label>Name on Card</label>
                                          <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter name"
                                            required="required"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="form-group">
                                        <div className="formify-forms__input">
                                          <label>Card Number</label>
                                          <input
                                            type="text"
                                            name="card-number"
                                            placeholder="Enter card number"
                                            required="required"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>Valid on</label>
                                            <input
                                              type="text"
                                              name="card-expire"
                                              placeholder="MM / YY"
                                              required="required"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>CVC Code</label>
                                            <input
                                              type="text"
                                              name="card-cvc"
                                              placeholder="Enter CVC code"
                                              required="required"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group formify-mg-top-40">
                                      <div className="formify-forms__button">
                                        <button className="formify-btn prev-step">
                                          Previous
                                        </button>
                                        <button className="formify-btn next-step">
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  PAN Information
                                </h3>
                                {/* <p className="formify-forms__quiz-text--v5 m-0">
                                  Let’s start your dream journey
                                </p> */}
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <Row>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              PAN Number <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter PAN Number here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              GST Number <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter GST Number here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Invoice Information
                                </h3>
                                {/* <p className="formify-forms__quiz-text--v5 m-0">
                                  Let’s start your dream journey
                                </p> */}
                                <div className="formify-forms__quiz-form formify-mg-top-10">
                                  <div className="formify-forms__quiz-form formify-forms__quiz-form--v5">
                                    <Row>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Firm Name <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="first-name"
                                              placeholder="Enter firm name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Invoice Address <span>*</span>
                                            </label>
                                            <input
                                              type="text"
                                              name="last-name"
                                              placeholder="Enter Invoice Address here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Currency <span>*</span>
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              placeholder="Select Currency"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      {/* <Col md={12}>
                                        <div className="form-group">
                                          <div className="formify-forms__input formify-forms__textarea">
                                            <label>
                                              Tell us about what you do
                                            </label>
                                            <textarea
                                              name="description"
                                              placeholder="Enter your tagline..."
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col> */}
                                      {/* <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Password <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="first-name"
                                              placeholder="Enter name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="form-group">
                                          <div className="formify-forms__input">
                                            <label>
                                              Confirm Password <span>*</span>
                                            </label>
                                            <input
                                              type="password"
                                              name="last-name"
                                              placeholder="Enter last name here"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </Col> */}
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="form-group">
                                          <div className="formify-forms__spaceb">
                                            <div className="formify-forms__checkbox">
                                              <label
                                                className="m-0"
                                                htmlFor="checkbox"
                                              >
                                                By continuing the next level,
                                                you agree to{" "}
                                                <a href="#">Privacy Policy</a>{" "}
                                                and <a href="#">Terms of use</a>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* <div className="form-group formify-mg-top-40">
                                      <div className="formify-forms__button">
                                        <Button
                                          variant="primary"
                                          className="formify-btn prev-step"
                                          onClick={handlePrev}
                                        >
                                          Previous
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="formify-btn next-step"
                                          onClick={handleNext}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                step === 6 ? "show active" : ""
                              }`}
                              id="step6"
                            >
                              <div className="formify-forms__quiz-single">
                                <h3 className="formify-forms__quiz-title--v5 m-0">
                                  Congratulations! You did Great Job.
                                </h3>
                                <div className="formify-forms__quiz-form formify-mg-top-40">
                                  <div className="formify-forms__quiz-form">
                                    {/* Form Group */}
                                    <div className="form-group formify-mg-top-40">
                                      {/* <div className="formify-forms__button">
                                        <button className="formify-btn prev-step">
                                          Go Back
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group formify-mg-top-40">
                              <div className="formify-forms__button">
                                {step !== 1 && (
                                  <Button
                                    variant="primary"
                                    className="formify-btn prev-step"
                                    onClick={handlePrev}
                                  >
                                    Previous
                                  </Button>
                                )}
                                {step !== 6 ? (
                                  <Button
                                    variant="primary"
                                    className="formify-btn next-step"
                                    onClick={handleNext}
                                  >
                                    Next
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    className="formify-btn prev-step"
                                    onClick={handlePrev}
                                  >
                                    Go Back
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default AgentRegistration;
