 const transferBookingsData = [
    {
      id: 20,
      bookingDate: '05:07 PM, 26 Dec 2023',
      journeyDate: '27 Dec 2023',
      pax: 2, // Example value, replace with actual number of passengers
      status: 'Success',
      price: '3110',
      detailLink: 'https://tripoholidays.com/agent/bookings/hotel/detail/IixDYGAKYAo='
    },
    {
      id: 20,
      bookingDate: '05:07 PM, 26 Dec 2023',
      journeyDate: '27 Dec 2023',
      pax: 2, // Example value, replace with actual number of passengers
      status: 'Success',
      price: '3110',
      detailLink: 'https://tripoholidays.com/agent/bookings/hotel/detail/IixDYGAKYAo='
    },
    {
      id: 20,
      bookingDate: '05:07 PM, 26 Dec 2023',
      journeyDate: '27 Dec 2023',
      pax: 2, // Example value, replace with actual number of passengers
      status: 'Success',
      price: '3110',
      detailLink: 'https://tripoholidays.com/agent/bookings/hotel/detail/IixDYGAKYAo='
    },
    {
      id: 20,
      bookingDate: '05:07 PM, 26 Dec 2023',
      journeyDate: '27 Dec 2023',
      pax: 2, // Example value, replace with actual number of passengers
      status: 'Success',
      price: '3110',
      detailLink: 'https://tripoholidays.com/agent/bookings/hotel/detail/IixDYGAKYAo='
    },
    
  ];

  export default transferBookingsData;