import React from 'react'
import CustomBanner from './CustomBanner'
import CustomBreadcrumb from '../Contact/CustomBreadcrumb'
import PPContent from './PP_Content'

const Privacy_Policy = () => {
  return (
    <div>
        <CustomBanner title="Privacy Policy"/>
        <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "Privacy Policy" }]}
        />
        <PPContent/>
    </div>
  )
}

export default Privacy_Policy