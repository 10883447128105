import React from "react";
import "./LastSection.css";
import { Link } from "react-router-dom";

const LastSection = () => {
  return (
    <div className="hfe-before-footer-wrap">
      <div className="footer-width-fixer">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="1047"
          className="elementor elementor-1047"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-94e2fed elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="94e2fed"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-background-overlay"></div>
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-018ca58"
                data-id="018ca58"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-a27d3a6 elementor-widget elementor-widget-heading"
                    data-id="a27d3a6"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h6 className="elementor-heading-title elementor-size-default">
                        Become a super travel agent
                      </h6>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-210cfd0 elementor-widget elementor-widget-heading"
                    data-id="210cfd0"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        You're one step away. Get access to prices and bookings.
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-0adb771 elementor-widget elementor-widget-heading"
                    data-id="0adb771"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h6 className="elementor-heading-title elementor-size-default">
                        Quick, one-step registration to get access to the world.
                        No waiting time. No fees. Fill in the form and start
                        booking.
                      </h6>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-27930c1 elementor-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="27930c1"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <Link
                            to="/agent-registration"
                            target="_blank"
                            className="BaseButton_root__2bBDd BaseButton_root_size_m__7z01Z BaseButton_root_variant_primary___xgRg SectionWithRays_button__kpWMA"
                          >
                            Join us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LastSection;
