import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PPContent = () => {
  return (
    <Container>
      <Row className="inner_page_content">
        <Col md={12} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We value the trust you place in www.tripoholidays.com.
                    That's why we insist upon the highest standards for secure
                    transactions and customer information privacy. Please read
                    the following statement to learn about our information
                    gathering and dissemination practices. Note: Our privacy
                    policy is subject to change at any time. To make sure you
                    are aware of any changes, please review this policy
                    periodically.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span
              style={{
                fontWeight: "bolder",
                fontFamily: "Calibri, sans-serif",
                fontSize: "11pt",
              }}>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ color: "rgb(51, 51, 51)" }}>
                  1. Collection of Personally Identifiable Information
                </span>
              </span>
            </span>
            <br />
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We collect personally identifiable information (email
                    address) from you when you set up a free account with&nbsp;
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    . While you can browse some sections of our site without
                    being a registered member, certain activities (such as
                    searching for properties, viewing property contact
                    information, and registering a property) do require
                    registration.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We do use your contact information to send you offers as
                    well as results of your 'Aapki Price' quote. You may always
                    opt-out of receiving such information; see the&nbsp;
                    <em>Choice/Opt-Out</em>
                    &nbsp;section below.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      2. Use of Demographic and Profile Data
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We use personal information to provide the services you
                    request. To the extent we use your personal information to
                    market to you, we will provide you the ability to opt-out of
                    such uses. We use your personal information to resolve
                    disputes; troubleshoot problems; help promote a safe
                    service; collect fees owed; measure consumer interest in our
                    products and services, inform you about online and offline
                    offers, products, services, and updates; customize your
                    experience; detect and protect us against error, fraud and
                    other criminal activity; enforce our terms and conditions;
                    and as otherwise described to you at the time of collection.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    In our efforts to continually improve our product and
                    service offerings, we collect and analyse demographic and
                    profile data about our users' activity on our website.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We identify and use your IP address to help diagnose
                    problems with our server, and to administer our website.
                    Your IP address is also used to help identify you and to
                    gather broad demographic information.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We will occasionally ask you to complete optional online
                    surveys. These surveys may ask you for contact information
                    and demographic information (like zip code, age, or income
                    level). We use this data to tailor your experience at our
                    site, providing you with content that we think you might be
                    interested in--and to display content according to your
                    preferences.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>Cookies</span>
                  </span>
                </span>
                <br />
                <br />
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    A "cookie" is a small piece of information stored by a Web
                    server on a Web browser so it can be later read back from
                    that browser. Cookies are useful for enabling the browser to
                    remember information specific to a given user.&nbsp;
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;places both permanent and temporary cookies in your
                    computer's hard drive.&nbsp;
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;cookies do not contain any of your personally
                    identifiable information.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      3. Sharing of personal information
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We may share personal information without other corporate
                    entities and affiliates to: help detect and prevent identity
                    theft, fraud and other potentially illegal acts; correlate
                    related or multiple accounts to prevent abuse of our
                    services; and to facilitate joint or co-branded services
                    that you request where such services are provided by more
                    than one corporate entity. Those entities and affiliates may
                    not market to you as a result of such sharing unless you
                    explicitly opt-in.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    We may disclose personal information if required to do so by
                    law or in the good faith belief that such disclosure is
                    reasonably necessary to respond to subpoenas, court orders,
                    or other legal process. We may disclose personal information
                    to law enforcement offices, third party rights owners, or
                    others in the good faith belief that such disclosure is
                    reasonably necessary to: enforce our Terms or Privacy
                    Policy; respond to claims that an advertisement, posting or
                    other content violates the rights of a third party; or
                    protect the rights, property or personal safety of our users
                    or the general public.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;and its affiliates will share some or all of your
                    personal information with another business entity should we
                    (or our assets) plan to merge with, or be acquired by that
                    business entity. Should such a transaction occur, that other
                    business entity (or the new combined entity) will be
                    required to follow this privacy policy with respect to your
                    personal information?
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      4. Links to Other Sites
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    Our site links to other websites that may collect personally
                    identifiable information about you.&nbsp;
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;is not responsible for the privacy practices or the
                    content of those linked websites.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      5. Security Precautions
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    Our site has stringent security measures in place to protect
                    the loss, misuse, and alteration of the information under
                    our control. Whenever you change or access your account
                    information, we offer the use of a secure server. Once your
                    information is in our possession we adhere to strict
                    security guidelines, protecting it against unauthorized
                    access.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      6. Choice/Opt-Out
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;provides all users with the opportunity to opt-out of
                    receiving non-essential (promotional, marketing-related)
                    communications from us on behalf of our partners, and from
                    us in general, after setting up an account.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    To remove your contact information from all&nbsp;
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    &nbsp;lists and newsletters, Please contact us at the
                    address below.
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ color: "rgb(51, 51, 51)" }}>
                      7. Questions?
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ color: "rgb(51, 51, 51)" }}>
                    Questions regarding this statement should be directed to the
                    following address:
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            style={{
              marginRight: "0in",
              marginLeft: "0in",
              textAlign: "justify",
            }}>
            <span
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Calibri, sans-serif",
                fontSize: 14,
              }}>
              www.tripoholidays.com
            </span>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <br />
              </span>
            </span>
            <a href="mailto:support@tripoholidays.com">
              support@tripoholidays.com
            </a>
          </p>{" "}
        </Col>
      </Row>
    </Container>
  );
};

export default PPContent;
