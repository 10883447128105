import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import './DashServices.css';
import { Link } from "react-router-dom";

const DashServices = () => {
  return (
    <Container fluid className="service-container">
      <Row>
        <Col md={3} sm={6} xs={6}>
          <Card className="service-card">
            <Link to="/agent/hotels">
              <Card.Img
                variant="top"
                src="https://tripoholidays.com/public/img/hotel-icon.png"
                alt="Hotel"
                className="service-img"
              />
              <Card.Body>
                <Card.Title>Hotel</Card.Title>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col md={3} sm={6} xs={6}>
          <Card className="service-card">
            <Link to="/agent/sightseeing">
              <Card.Img
                variant="top"
                src="https://tripoholidays.com/public/img/binocular-icon.png"
                alt="Sightseeing"
                className="service-img"
              />
              <Card.Body>
                <Card.Title>Sightseeing</Card.Title>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col md={3} sm={6} xs={6}>
          <Card className="service-card">
            <Link to="/agent/tour">
              <Card.Img
                variant="top"
                src="https://tripoholidays.com/public/img/bag-icon.png"
                alt="Tour Packages"
                className="service-img"
              />
              <Card.Body>
                <Card.Title>Tour Packages</Card.Title>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col md={3} sm={6} xs={6}>
          <Card className="service-card">
            <Link to="/agent/transfers">
              <Card.Img
                variant="top"
                src="https://tripoholidays.com/public/img/taxi-icon.png"
                alt="Transfers"
                className="service-img"
              />
              <Card.Body>
                <Card.Title>Transfers</Card.Title>
              </Card.Body>
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashServices;
