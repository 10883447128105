import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHotelMarkupData } from "./HotelMarkupData";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Button,
  Form,
} from "react-bootstrap";
import { FaArrowLeft, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

const EditHotelMarkup = () => {
  const [serviceType, setServiceType] = useState("");
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const { hotelMarkupData, updateHotelMarkup } = useHotelMarkupData();
  const { id } = useParams();

  useEffect(() => {
    const markup = hotelMarkupData.find((markup) => markup.id === parseInt(id));
    if (markup) {
      setServiceType(markup.amountType);
      setAmount(markup.amount);
    }
  }, [id, hotelMarkupData]);

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create updated data object
    const updatedData = {
      id: id,
      amountType: serviceType,
      amount: amount,
      date: new Date().toLocaleString(),
      editLink: `/agent/hotelmarkup/edit/${id}`,
    };
  
    // Call update function
    updateHotelMarkup(updatedData);
    console.log("Updated data:", updatedData);
  
    // Redirect to the markup page or do any other necessary actions
    navigate("/agent/hotelmarkup");
  };

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Manage Markup</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Manage Markup</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <h3 className="card-title">Edit Markup</h3>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={12}>
                          <Form.Group
                            className="form-group"
                            style={{ textAlign: "right" }}>
                            <Link
                              to="/agent/hotelmarkup"
                              className="btn btn-theme-sec"
                              style={{ marginRight: "5px" }}>
                              <FaArrowLeft /> Back
                            </Link>
                            <Button className="btn btn-theme" type="submit">
                              <FaEdit /> Update Markup
                            </Button>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount Type{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="service_type"
                              value={serviceType}
                              onChange={handleServiceTypeChange}
                              required>
                              <option value="">Select Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="percentage">Percentage</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="amount"
                              value={amount}
                              onChange={handleAmountChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Form.Group className="form-group float-right">
                            <Button className="btn btn-theme" type="submit">
                              <FaEdit /> Update
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default EditHotelMarkup;
