import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './Brands.css';
import BrandImages from "./BrandImages";

const Brands = () => {

  // Options for the Owl Carousel
  const options = {
    items: 5, // Number of items to display at once
    autoplay: true, // Autoplay enabled
    autoplayHoverPause: false, // Pause autoplay when hovering over the carousel
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    dots: false, // Hide navigation dots
    nav: false, // Show navigation arrows
    loop: true,
    // center:true,
    // freeDrag:true,
    autoWidth:true,
    navText: [
      '<i class="fas fa-angle-left"></i>',
      '<i class="fas fa-angle-right"></i>',
    ], // Custom navigation icons
    // responsive: {
    //   0: { items: 1.5 },
    //   480: { items: 2 },
    //   768: { items: 3 },
    //   992: { items: 4 },
    //   1200: { items: 5 },
    // },
  };

  return (
    <div className="container brands-container">
      <div className="headingal mt40 text-center">
        <h2>Brands We Work With</h2>
      </div>
      <OwlCarousel className="owl-theme brand_slider" {...options}>
        {BrandImages.map((imageUrl, index) => (
          <div key={index} className="item">
            <div className="blogo">
              <span>
                <img
                  src={imageUrl}
                  className="rps"
                  alt={`Brand ${index + 1}`}
                />
              </span>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Brands;
