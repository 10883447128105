import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import HotelsListData from "./HotelsListData";
import FilterComp from "./FilterComp";
import "./Hotels.css";
import { FaAngleRight, FaSpinner, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

const HotelList = () => {
  return (
    <Container className="listingbx">
      <Row className="rightlist flexdrop">
        <Col>
          <h2 className="hotelcountinfo">
            New Delhi and NCR&nbsp;&nbsp;:&nbsp;&nbsp;
            <span style={{}} className="properti_f">
              {HotelsListData.length} Hotels available
            </span>
            <div className="searchtoolbar">
              <span className="searchtext">
                Our best prices have now loaded
              </span>
              <div
                className="searchloader"
                // style={{ display: "none" }}
              >
                <FaSpinner />
              </div>
            </div>
          </h2>
        </Col>
        <Col>
          <div className="ratingdrop" style={{float:"right"}}>
            <select className="lidr_pc" fdprocessedid="xayn33">
              <option>Rating</option>
              <option>Price&nbsp;&nbsp;:&nbsp;&nbsp;low to high</option>
              <option>Price&nbsp;&nbsp;:&nbsp;&nbsp;high to low</option>
              <option>Distance</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row id="myProgress">
        <div className="myBar" style={{ width: "100%" }} />
      </Row>
      <Row className="largebox_listing" id="ajaxContent">
        {HotelsListData.map((hotel) => (
          <div
            className="refendable11onword"
            id={`code_${hotel.id}`}
            key={hotel.id}>
            <div className="hotelcode" id={hotel.id}>
              <div className="hotelnamesearch" hotelname={hotel.name}>
                <div className="roomtypes" roomtype={hotel.roomType}>
                  <div className="price1" price="null" data-price="null">
                    <div className="mealplans" mealplan="room-only">
                      <div
                        className="facilities"
                        facilities={hotel.facilities.join(";")}
                        style={{ display: "block" }}>
                        <div className="lglist">
                          <div className="list_hotel_img">
                            <div className="lgzoomimg">
                              <Link
                                // to="javascript:;"
                                hid={hotel.id}
                                sid="vlybtof6uqploztqrvh3hafviq"
                                className="openhotelinfomodal">
                                <img
                                  style={{ width: "100%", height: 197 }}
                                  src={hotel.imageUrl}
                                  className="img-res"
                                  alt={hotel.name}
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="list_hotel_txt">
                            <div className="listing_hd_hotel">
                              <h2>
                                <Link
                                  to="#"
                                  hid={hotel.id}
                                  sid="vlybtof6uqploztqrvh3hafviq"
                                  className="openhotelinfomodal">
                                  <span>{hotel.name}</span>
                                </Link>
                                <div className="startbx smallstar">
                                  {/* <span>&nbsp;-&nbsp;</span> */}
                                  {Array.from({ length: 4 }, (_, index) => (
                                    <FaStar key={index} className="star_icon" />
                                  ))}
                                </div>
                              </h2>
                              <ul className="listbt_sml">
                                <li>
                                  <Link
                                    to="#"
                                    lat="28.65427704"
                                    long="77.36183794">
                                    {hotel.address}
                                  </Link>
                                </li>
                              </ul>
                              <ul className="iconsml">
                                {hotel.facilities.map((facility, index) => (
                                  <li key={index}>
                                    <span style={{ display: "none" }}>
                                      <img
                                        src="https://tripoholidays.com/public/images/Pool.png"
                                        className="img-res"
                                        alt={facility}
                                      />
                                    </span>
                                    <span>{facility}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="pribtns">
                            <div className="priceshow">
                              <h3 className="price_value">
                                ₹{hotel.price} <span>Total Cost</span>
                              </h3>
                            </div>
                            <div className="hotslc">
                              <Button
                                href="#"
                                hid={hotel.id}
                                sid="vlybtof6uqploztqrvh3hafviq"
                                className="btn-grad ftbtn_src openrooms">
                                Show Rooms
                                <FaAngleRight
                                  className="ml5"
                                  aria-hidden="true"
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="hotwlroomdata"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Row>
    </Container>
  );
};

const Hotels = () => {
  return (
    <div className="container_pd list_hotel">
      <section className="list_fliter">
        <Row>
          <Col lg={3} md={3} sm={12}>
            <FilterComp />
          </Col>
          <Col lg={9} md={9} sm={12}>
            <HotelList />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Hotels;
