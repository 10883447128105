import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import './Profile.css';

const Profile = () => {
  const loadFile = (event) => {
    // Define your logic for loading a file here
  };

  const customValidate = (formId) => {
    // Define your custom validation logic here
  };

  // Store user data in an object
  const userData = {
    firmType: "PROPRIETOR",
    companyName: "eWeblink",
    email: "info@eweblink.net",
    phone: "9015858565",
    address: "Delhi",
    state: "",
    city: "Delhi",
    zipCode: "110045",
  };

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">My Profile</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <h3 className="card-title">Company Information</h3>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <div className="table-responsive">
                          <Table bordered>
                            <tbody>
                              <tr>
                                <th>Firm Type</th>
                                <td>{userData.firmType}</td>
                              </tr>
                              <tr>
                                <th>Company Name</th>
                                <td>{userData.companyName}</td>
                              </tr>
                              <tr>
                                <th>Email</th>
                                <td>{userData.email}</td>
                              </tr>
                              <tr>
                                <th>Phone</th>
                                <td>{userData.phone}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="table-responsive">
                          <Table bordered>
                            <tbody>
                              <tr>
                                <th>Address</th>
                                <td>{userData.address}</td>
                              </tr>
                              <tr>
                                <th>State</th>
                                <td>{userData.state}</td>
                              </tr>
                              <tr>
                                <th>City</th>
                                <td>{userData.city}</td>
                              </tr>
                              <tr>
                                <th>Zip Code</th>
                                <td>{userData.zipCode}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Card className="card-primary">
                  <Card.Header>
                    <h3 className="card-title">Company Logo & Info</h3>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="form-group" >
                          <Form.Label htmlFor="company_email">
                            Company Email{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            data-valid="required"
                            placeholder="Company Email"
                            name="email"
                            type="text"
                            value={userData.email}
                            fdprocessedid="i4idtp"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="company_phone">
                            Company Phone{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className="form-control mask"
                            data-valid="required"
                            placeholder="Company Phone"
                            name="phone"
                            type="text"
                            value={userData.phone}
                            fdprocessedid="i5d57s"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="company_logos_sec">
                      <Row>
                        <Col lg={4} md={6} sm={6}>
                          <Form.Group className="profile_img_field">
                            <Form.Label>Company Logo</Form.Label>
                            <input
                              type="hidden"
                              id="old_profile_logo"
                              name="old_profile_logo"
                              value=""
                            />
                            <div className="logo_update">
                              <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(event) => loadFile(event)}
                                name="profile_logo"
                                className="form-control"
                              />
                              <Link to="javascript:;" style={{marginTop:"10px"}}>
                                Update <BiRefresh className="refresh_icon"/>
                              </Link>
                              {/* <p>Recomended 232X66</p> */}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div
                        className="package_full_form"
                        style={{ textAlign: "right" }}>
                        <Form.Group className="form-group">
                          <Button
                            className="btn btn-theme px-4"
                            onClick={() => customValidate("edit-profile")}
                            type="button"
                            fdprocessedid="z16e9j">
                            <FaEdit/> Update
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default Profile;
