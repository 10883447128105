import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";


const TopBanner = ({ packageName }) => {
  if (!packageName) {
    return <div className="server-error">Destination or package not found</div>;
  }

  return (
    <section className="pack_details start_bg_zoom">
      <div className="single_pack_banner">
        <Container>
          <Row>
            <Col md={12}>
              <div className="server-error"></div>
            </Col>
          </Row>
          <Row>
            <Col className="details_image">
              <Image src={packageName.package_gallery} fluid alt="" />
              <div className="opacity_banner"></div>
            </Col>
          </Row>
          <div className="pack_banner_title">
            <div className="inner_title">
              <h1 className="fadeInUp animated mytitle">{packageName.name}</h1>
              <span className="count_days">{packageName.nights} Nights / {packageName.days} Days</span>
              <p><FaMapMarkerAlt/> {packageName.details_day_night}</p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default TopBanner;
