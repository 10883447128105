import React from "react";
import TravelAgentLogin from "./TravelAgentLogin";
import Advantages from "./Advantages";
import Statistics from "./Statistics";
import Brands from "./Brands";
import StatisticsData from "./StatisticsData";

const Login = () => {
  
  return (
    <>
      <TravelAgentLogin />
      <Advantages />
      <Statistics StatisticsData={StatisticsData} />
      {/* <BrandsWeWorkWith/> */}
      <Brands />
    </>
  );
};

export default Login;
