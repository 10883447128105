import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Pagination,
  Table,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const RechargeHistory = () => {
  const RechargeHistoryData = [
    {
        id: 10,
        entryDate: "02:48 PM, 07 Mar 2024",
        depositAmount: "2.00",
        accountNumber: "",
        bankName: "",
        status: "Pending",
        adminRemark: "",
        detailLink: "https://tripoholidays.com/agent/wallet/detail/IiwzYGAKYAo=",
    },
      {
        id: 9,
        entryDate: "02:47 PM, 07 Mar 2024",
        depositAmount: "2.00",
        accountNumber: "",
        bankName: "",
        status: "Pending",
        adminRemark: "",
        detailLink: "https://tripoholidays.com/agent/wallet/detail/IS4wYGAKYAo=",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5; // Number of entries per page

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = RechargeHistoryData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(RechargeHistoryData.length / entriesPerPage);
  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Recharge History</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Recharge History</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr role="row">
                              <th>Id</th>
                              <th>Entry Date</th>
                              <th>Deposit Amount</th>
                              <th>Account Number</th>
                              <th>Bank Name</th>
                              <th>Status</th>
                              <th>Admin Remark</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {/* Map through the transaction data */}
                            {currentEntries.length > 0 ? (
                              currentEntries.map((transaction, index) => (
                                <tr
                                  key={index}
                                  role="row"
                                  className={index % 2 === 0 ? "odd" : "even"}>
                                  <td>{transaction.id}</td>
                                  <td>{transaction.entryDate}</td>
                                  <td
                                  style={{
                                    // textAlign: "right",
                                    fontWeight: 600,
                                  }}>
                                  ₹ {transaction.depositAmount}
                                </td>
                                  <td>{transaction.accountNumber}</td>
                                  <td>{transaction.bankName}</td>
                                  <td>
                                  <div className="check_status">
                                    <Button
                                      variant="link"
                                      className={`chk_stat_btn ${
                                        transaction.status === "Pending"
                                          ? "yellow_clr"
                                          : transaction.status === "Success"
                                          ? "green_clr"
                                          : "red_clr"
                                      }`}>
                                      {transaction.status}
                                    </Button>
                                  </div>
                                </td>
                                  <td>{transaction.adminRemark}</td>
                                  <td>
                                  <div className="nav-item dropdown action_dropdown cus_action_btn">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="primary"
                                        size="sm"
                                        className="action_btn btn btn-primary btn-rounded btn-xs"
                                        id="dropdown-basic">
                                        Action <span className="caret"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to={transaction.detailLink}>
                                            <FaEye className="eye-icon" /> View
                                            Detail
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="odd">
                                <td
                                  valign="top"
                                  colSpan="4"
                                  className="dataTables_empty"
                                  style={{ textAlign: "center" }}>
                                  No data available in table
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>

                    <Col md={12}>
                      {/* Pagination */}
                      <Row>
                        <Col sm={12} md={5}>
                          <div
                            className="dataTables_info"
                            id="invoicetable_info"
                            role="status"
                            aria-live="polite">
                            {RechargeHistoryData.length > 0
                              ? `Showing ${indexOfFirstEntry + 1} to ${Math.min(
                                  indexOfLastEntry,
                                  RechargeHistoryData.length
                                )} of ${RechargeHistoryData.length} entries`
                              : "No bookings to show"}
                          </div>
                        </Col>
                        <Col sm={12} md={7}>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="invoicetable_paginate">
                            <Pagination>
                              {/* Previous page button */}
                              <Pagination.Prev
                                onClick={() => paginate(currentPage - 1)}
                                disabled={
                                  currentPage === 1 ||
                                  RechargeHistoryData.length === 0
                                }>
                                Previous
                              </Pagination.Prev>
                              {/* Page numbers */}
                              {Array.from({ length: totalPages }).map(
                                (_, index) => (
                                  <Pagination.Item
                                    key={index}
                                    active={currentPage === index + 1}
                                    onClick={() => paginate(index + 1)}>
                                    {index + 1}
                                  </Pagination.Item>
                                )
                              )}
                              {/* Next page button */}
                              <Pagination.Next
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                  currentPage === totalPages ||
                                  RechargeHistoryData.length === 0
                                }>
                                Next
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default RechargeHistory;
