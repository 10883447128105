import React from 'react'
import Statistics from '../Login/Statistics'
import StatisticsData from '../Login/StatisticsData'
import AboutSection from './AboutSection'
import CustomBreadcrumb from '../Contact/CustomBreadcrumb'

const AboutUs = () => {
  return (
    <>
    <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "About" }]}
      />
    <Statistics StatisticsData={StatisticsData}/>
    <AboutSection/>
    </>
  )
}

export default AboutUs