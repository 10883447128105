import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ComingSoon.css'; // Import your custom CSS for styling

const ComingSoon = () => {
  return (
    <div className="inner_custom_page">
      <div className="section-content">
        <Container>
          <div className="inner_box text-center">
            <Row>
              <Col md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }}>
                <h3>Coming Soon!</h3>
                <p>This site is under construction, so we will be meet soon.</p>
                <div className="custom_pg_img coming_soon_img round_img">
                  <img src="https://tripoholidays.com/public/images/coming-soon.png" className="img-responsive" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ComingSoon;
