import React, {useState} from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaLock, FaUserTie, FaKey, FaEyeSlash, FaEye } from "react-icons/fa";
import "./TravelAgentLogin.css";
import { Link } from "react-router-dom";

const TravelAgentLogin = () => {
    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="travelagent">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="bannertxt text-center">
              <h1>World’s leading online B2B Portal</h1>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12} className="col_xs_480">
            <div className="agent_form">
              <Form>
                <h1>
                  <FaLock /> Login
                </h1>
                <div className="inner_form_field">
                  <Form.Group className="form-group">
                    <div className="frmb">
                      <span className="iconbc">
                        <FaUserTie />
                      </span>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Agency ID or Email"
                        className="form-control"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <div className="frmb">
                      <span className="iconbc">
                        <FaKey className="" />
                      </span>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className="form-control"
                      />
                      <div
                        className="pass_eyeswitch lgpassicon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <div className="checkbox">
                      <Form.Check type="checkbox" label="Remember me" className="checkbox-default"/>
                    
                    
                      <Link className="forgot-link" to="/agent/forgot_password">
                        Forgot Password
                      </Link>
                      </div>
                  </Form.Group>
                  <div className="form_btn">
                    <Button variant="custom" type="submit">
                      Login
                    </Button>
                  </div>
                  <div className="cus_txt">
                    <p>
                      Don't have an Account!{" "}
                      <Link to="/agent-registration">
                        Sign Up Here
                      </Link>
                    </p>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <div className="imgbnr">
              <img
                src="https://tripoholidays.com/public/images/bannerimg1.svg"
                className="rps"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TravelAgentLogin;
