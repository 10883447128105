const BrandImages=[
    "https://tripoholidays.com/public/images/pl1.png",
    "https://tripoholidays.com/public/images/pl2.png",
    "https://tripoholidays.com/public/images/pl3.png",
    "https://tripoholidays.com/public/images/pl4.png",
    "https://tripoholidays.com/public/images/pl5.png",
    "https://tripoholidays.com/public/images/pl6.png",
    "https://tripoholidays.com/public/images/pl7.png",
    "https://tripoholidays.com/public/images/pl8.png",
    "https://tripoholidays.com/public/images/pl9.png",
    "https://tripoholidays.com/public/images/pl10.png",
    "https://tripoholidays.com/public/images/pl11.png",
    "https://tripoholidays.com/public/images/pl12.png",
    "https://tripoholidays.com/public/images/pl13.png",
    "https://tripoholidays.com/public/images/pl14.png",
    "https://tripoholidays.com/public/images/pl15.png",
    
    
    // Add more image URLs as needed
  ];

  export default BrandImages;