import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ContactBoxes.css"; // Import your custom CSS for styling

const ContactBoxes = () => {
  return (
    <div className="contact_head" id="row_scroll">
      <Container>
        <Row className="text-center contactBoxes">
          <Col lg={3} md={3} sm={6} xs={12} className="column">
            <div className="contact_boxcol">
              <div className="contact_icon">
                <img
                  src="https://tripoholidays.com/public/images/contact1.png"
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="contact_info">
                <strong>24/7 Customer support</strong> <br />
                <span className="contactNumber">+919821446008</span>
                <a href="mailto:support@tripoholidays.com">
                  support@tripoholidays.com
                </a>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12}>
            <div className="contact_boxcol">
              <div className="contact_icon">
                <img
                  src="https://tripoholidays.com/public/images/contact2.png"
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="contact_info">
                <strong>sales support</strong> <br />
                <span className="contactNumber">+919821446008</span>
                <a href="mailto:sales@tripoholidays.com">
                  sales@tripoholidays.com
                </a>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12}>
            <div className="contact_boxcol">
              <div className="contact_icon">
                <img
                  src="https://tripoholidays.com/public/images/contact3.png"
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="contact_info">
                <strong>Technical Support</strong> <br />
                <span className="contactNumber">+919821446008</span>
                <a href="mailto:support@tripoholidays.com">
                  support@tripoholidays.com
                </a>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12}>
            <div className="contact_boxcol">
              <div className="contact_icon">
                <img
                  src="https://tripoholidays.com/public/images/hotels1.png"
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="contact_info">
                <strong>Hotel Bookings</strong> <br />
                <span className="contactNumber">+919821446008</span>
                <a href="mailto:hotel@tripoholidays.com">
                  hotel@tripoholidays.com
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactBoxes;
