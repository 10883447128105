import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "./TourPackageCustomBanner.css";
import { MdDirectionsCar } from "react-icons/md";
import AsyncSelect from "react-select/async";
import {
  FaBed,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaStar,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { nationalityOptions, cityOptions } from "./Options";

const TourPackageCustomBanners = () => {
  // const [nationality, setNationality] = useState("");
  // const handleNationalityChange = (e) => {
  //   setNationality(e.target.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    // const form = e.target;
    // const formData = new FormData(form);
    // const searchParams = new URLSearchParams(formData).toString();
    // console.log(searchParams); // You can send this data to your API endpoint
  };

  const [dateRange, setDateRange] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(false);

  const fetchCities = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = cityOptions.filter((city) =>
        city.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions.map((city) => ({ value: city, label: city })));
    });
  };

  useEffect(() => {
    fetchCities("").then((data) => setOptions(data));
  }, []);

  const [rooms, setRooms] = useState([{ adults: 2, children: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);

  const addRoom = () => {
    if (rooms.length < 6) {
      setRooms([...rooms, { adults: 2, children: 0 }]);
    }
  };

  const removeRoom = (index) => {
    if (rooms.length > 1) {
      const updatedRooms = [...rooms];
      updatedRooms.splice(index, 1);
      setRooms(updatedRooms);
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  return (
    <section className="custom_banner" id="banner">
      <div className="banner-parallax">
        <div
          className="bg-element"
          data-stellar-background-ratio="0.2"
          style={{
            backgroundImage:
              "url(https://tripoholidays.com/public/images/turkey.jpg)",
          }}></div>
        <div className="banner_search tour_package_search">
          <Container className="conn">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="banner_search_box">
                  <div className="section-tab">
                    <ul>
                      <li className="list_item">
                        <Link to="/agent/hotels">
                          <FaBed className="tabicon" />{" "}
                          <span className="d-none d-lg-block">Hotel</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/sightseeing">
                          <FaStar className="tabicon" />
                          <span className="d-none d-lg-block">Sightseeing</span>
                        </Link>
                      </li>
                      <li className="list_item active">
                        <Link to="/agent/tour">
                          <FaSuitcaseRolling className="tabicon" />
                          <span className="d-none d-lg-block">
                            Tour Package
                          </span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/transfers">
                          <MdDirectionsCar className="tabicon" />
                          <span className="d-none d-lg-block">Transfer</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="tab_content">
                    <div className="custom_tabs_sec">
                      <div className="custom_form_action">
                        {" "}
                    <Form
                      id="searchform"
                      className="booking_search_form search_form"
                      onSubmit={handleSubmit}
                      action="https://tripoholidays.com/tour/list"
                      method="get">
                      <div id="custom-search-input" className="d-flex">
                        <div
                          id="mysearchcity"
                          className="input_field searchcity">
                          <div className="input-box">
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={fetchCities}
                              loadingMessage={() => "Loading..."}
                              noOptionsMessage={() => "No cities found"}
                              placeholder="Search your Dream Destination!"
                              options={options}
                              value={selectedOption}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(option) => {
                                setSelectedOption(option);
                              }}
                            />
                            <FaMapMarkerAlt className="map_icon" />
                          </div>
                        </div>
                        <div className="search_btn">
                          <button
                            className="boxed-btn4"
                            type="submit"
                            fdprocessedid="txq5od">
                            Search
                          </button>
                        </div>
                      </div>
                    </Form>
                    </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default TourPackageCustomBanners;
