import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const ChangePassword = () => {

    const customValidate = (formId) => {
        // Define your custom validation logic here
      };

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Change Password</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={6}>
                <Card className="card-primary">
                  <Card.Header>
                    <h3 className="card-title" style={{ display: "block" }}>
                      Change Password
                    </h3>
                  </Card.Header>
                  <Form
                    method="POST"
                    action="https://tripoholidays.com/agent/change_password"
                    acceptCharset="UTF-8"
                    name="change-password">
                    <input
                      name="_token"
                      type="hidden"
                      value="AhZzpj2pHUo5wu25KKE8gBWcsAejPOCP0tMxwfat"
                    />
                    <input name="admin_id" type="hidden" value="107" />
                    <Card.Body>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="old_password">
                          Old Password{" "}
                          <span style={{ color: "#ff0000" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          data-valid="required"
                          name="old_password"
                          type="password"
                          value=""
                          fdprocessedid="7vnsh"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                          New Password{" "}
                          <span style={{ color: "#ff0000" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          data-valid="required"
                          name="password"
                          type="password"
                          value=""
                          fdprocessedid="wukin6"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password_confirmation">
                          Confirm Password{" "}
                          <span style={{ color: "#ff0000" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          data-valid="required"
                          name="password_confirmation"
                          type="password"
                          value=""
                          fdprocessedid="owbxzr"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Button
                          className="btn btn-theme px-4"
                          onClick={() => customValidate("change-password")}
                          type="button"
                          fdprocessedid="l87cj">
                          <i className="fa fa-refresh"></i> Change
                        </Button>
                      </Form.Group>
                    </Card.Body>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default ChangePassword;
