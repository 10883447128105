import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { TfiFacebook, TfiTwitterAlt, TfiLinkedin } from "react-icons/tfi";
import { FiInstagram } from "react-icons/fi";
import "./Footer.css";

const Footer = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // For example, you can fetch the input value and perform an action
    console.log("Form submitted!");
  };
  return (
    <footer className="footer">
      <div className="footer_overlap">
        <Container>
          <div className="overlap_box">
            <div>
              <h4>Subscribe to our Newsletter</h4>
            </div>
            <div>
              <Form onSubmit={handleSubmit}>
                <InputGroup>
                  <Form.Control type="email" placeholder="Your Email" />
                  <Button variant="primary" type="submit">
                    Subscribe
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <Row>
          <Col xs={6} sm={6} md={3}>
            <div className="footer-section">
              <h3>Company</h3>
              <ul className="footer-links">
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/page/terms-conditions">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/page/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/page/disclaimer">Disclaimer</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/coming_soon">Blog</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/career">Career</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <div className="footer-section">
              <h3>Products & Services</h3>
              <ul className="footer-links">
                <li>
                  <Link to="/agent/hotels">Hotels</Link>
                </li>
                <li>
                  <Link to="/agent/sightseeing">Sightseeing</Link>
                </li>
                <li>
                  <Link to="/agent/transfers">Transfers</Link>
                </li>
                <li>
                  <Link to="/agent/tour">Activities</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className="footer-section">
              <h3>Contact Us</h3>
              <div className="contact-info">
                <span>
                  <FaPhone className="icon" />
                  <Link to="tel:+91 98214 46008"> +91 98214 46008</Link>
                </span>
                <span>
                  <FaEnvelope className="icon" />
                  <Link to="mailto:b2b@tripoholidays.com">
                    {" "}
                    b2b@tripoholidays.com
                  </Link>
                </span>
              </div>
              <ul className="social-icons">
                <li>
                  <Link to="#">
                    <TfiFacebook />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <TfiTwitterAlt />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <TfiLinkedin />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FiInstagram />
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className="footer-section">
              <h3>Payment & Security</h3>
              <div className="payment-cards">
                <span className="visa" title="Visa"></span>
                <span className="master" title="Master Card"></span>
                <span className="american" title="American Express"></span>
                <span className="paypal" title="PayPal"></span>
                <span className="rupay" title="RuPay"></span>
                <span className="pci" title="PCI Security"></span>
                <span className="iata" title="IATA"></span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="footer-bottom">
              <p>
                Copyright &copy; 2024 Tripoholidays.com | All Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
