import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./ContactDetail.css"; // Import your custom CSS for styling

const ContactDetail = () => {
  // Define your custom validation function
  const customValidate = (formName) => {
    // Your validation logic here
    console.log("Form submitted!");
  };

  return (
    <div className="contact_detail">
      <Container>
        <Row>
          <Col sm={6} className="column">
            <div className="contact_form">
              <h4>Get in touch with us</h4>
              <Form
                method="POST"
                action="https://tripoholidays.com/contact/send"
                acceptCharset="UTF-8"
                name="add-contact"
                autoComplete="off"
                encType="multipart/form-data"
                className="form_sec">
                <input
                  name="_token"
                  type="hidden"
                  value="MLX6EqfQnAVLpaRCoWtVRiEwRcBc2scyGghYUD0w"
                />
                <Form.Group className="form_group">
                  <Form.Control type="text" placeholder="Name" name="name" />
                </Form.Group>
                <Form.Group className="form_group">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="form_group">
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                  />
                </Form.Group>
                <Form.Group className="form_group">
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    name="subject"
                  />
                </Form.Group>
                <Form.Group className="form_group">
                  <Form.Control
                    as="textarea"
                    placeholder="Your Message"
                    name="message"
                  />
                </Form.Group>
                <div
                  className="g-recaptcha"
                  data-sitekey="6LdXVoAcAAAAABvtjJHkNflYKGeXp2CIaTMMA05x"></div>
                <div className="contact_submit_btn text-center">
                  <Button
                    variant="primary"
                    onClick={() => customValidate("add-contact")}
                    className="form_submit_btn btn_effect">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col sm={6}>
            <div className="contact_address">
              <div className="contact_addr_col">
                <h4>Office Address</h4>
                <p>
                  253C Ground Floor, Kapoor Wali Gali, Munirka, New Delhi,Delhi
                  110067 India
                </p>
                <p>
                  <a href="tel:1800 212 8892">+919555446008</a>
                  <br />
                  <a href="mailto:techsupport@tripoholidays.com">
                    info@tripoholidays.com{" "}
                  </a>
                </p>
              </div>
              <div className="mapbx">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14017.15986252116!2d77.15766918891707!3d28.56105425784897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d78914f0a9b%3A0x7c12e9cac01a88cb!2sTripoholidays%20(%20DMC%20for%20Russia%20%7C%20Turkey%20%7C%20Vietnam%20%7CAzerbaijan)!5e0!3m2!1sen!2sin!4v1683800449628!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps Embed"></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactDetail;
