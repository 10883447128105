// sightMarkupData.js
import { useState } from 'react';

const initialData = [
  {
    id: 175,
    amountType: "percentage",
    amount: "100",
    date: "13 02 2023 20:54 PM",
    editLink: "/agent/sightseeingmarkup/edit/175",
  },
  {
    id: 176,
    amountType: "percentage",
    amount: "200",
    date: "14 02 2023 20:54 PM",
    editLink: "/agent/sightseeingmarkup/edit/176",
  },
];

export const useSightMarkupData = () => {
  const [sightMarkupData, setSightMarkupData] = useState(initialData);

  const addSightMarkup = (newMarkupData) => {
    const updatedData = sightMarkupData.push(newMarkupData);
    setSightMarkupData(updatedData);
  };

  const deleteSightMarkup = (id) => {
    const updatedData = sightMarkupData.filter(item => item.id !== id);
    setSightMarkupData(updatedData);
  };

  const updateSightMarkup = (updatedMarkupData) => {
    setSightMarkupData(updatedMarkupData);
  };

  return { sightMarkupData, addSightMarkup, deleteSightMarkup, updateSightMarkup };
};
