import React from 'react';
import { FaCheck } from 'react-icons/fa';

const BookingSteps = () => {
  return (
    <ul className="sbs sbs--border">
      <li className="finished">
        <div className="step">
          <span className="indicator">01</span>
          {/* <FaCheck/> */}
          <span className="description">Choose Package</span>
        </div>
      </li>
      <li className="active">
        <div className="step">
          <span className="indicator">02</span>
          <span className="description">Proceed Booking</span>
        </div>
      </li>
      <li className=''>
        <div className="step">
          <span className="indicator">03</span>
          <span className="description">Confirmation</span>
        </div>
      </li>
    </ul>
  );
}

export default BookingSteps;
