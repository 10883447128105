const HotelsListData = [
  {
    id: 2469041,
    name: "OYO 79846 Townhouse 292 Atal chowk Indirapuram Ghaziabad",
    roomType: "Classic Double Room",
    price: "1084",
    address: "C34 Sector 15, Ghaziabad, Uttar Pradesh",
    facilities: [
      "Guestroom wireless internet",
      "24-hour front desk",
      "Laundry/Valet service",
      "Multilingual staff",
    ],
    imageUrl:
      "https://images.grnconnect.com/2469041/dff9c1441ae80e7a0014cf42cc545413.jpg",
  },
  {
    id: 1399561,
    name: "FabHotel NStay 32",
    roomType: "Premium Queen Room",
    price: "1530",
    address: "1332-P Jharsa Road, Sector 31-32 A, Gurugram 12200, Haryana",
    facilities: [
      "Air-conditioning",
      "Elevators",
      "Complimentary-newspaper-delivered-to-room",
      "Housekeeping---daily",
      "Phone-services",
      "Smoke-free-property",
      "Complimentary-wireless-internet",
      "Free-parking",
      "Cloakroom-service",
    ],
    imageUrl:
      "https://images.grnconnect.com/1399561/7df2f565f55260fe858021245280a071.jpg",
  },
  {
    id: 246941,
    name: "OYO 79846 Townhouse 292 Atal chowk Indirapuram Ghaziabad",
    roomType: "Classic Double Room",
    price: "1084",
    address: "C34 Sector 15, Ghaziabad, Uttar Pradesh",
    facilities: [
      "Guestroom wireless internet",
      "24-hour front desk",
      "Laundry/Valet service",
      "Multilingual staff",
    ],
    imageUrl:
      "https://images.grnconnect.com/2469041/dff9c1441ae80e7a0014cf42cc545413.jpg",
  },
  {
    id: 399561,
    name: "FabHotel NStay 32",
    roomType: "Premium Queen Room",
    price: "1530",
    address: "1332-P Jharsa Road, Sector 31-32 A, Gurugram 12200, Haryana",
    facilities: [
      "Air-conditioning",
      "Elevators",
      "Complimentary-newspaper-delivered-to-room",
      "Housekeeping---daily",
      "Phone-services",
      "Smoke-free-property",
      "Complimentary-wireless-internet",
      "Free-parking",
      "Cloakroom-service",
    ],
    imageUrl:
      "https://images.grnconnect.com/1399561/7df2f565f55260fe858021245280a071.jpg",
  },
  {
    id: 269041,
    name: "OYO 79846 Townhouse 292 Atal chowk Indirapuram Ghaziabad",
    roomType: "Classic Double Room",
    price: "1084",
    address: "C34 Sector 15, Ghaziabad, Uttar Pradesh",
    facilities: [
      "Guestroom wireless internet",
      "24-hour front desk",
      "Laundry/Valet service",
      "Multilingual staff",
    ],
    imageUrl:
      "https://images.grnconnect.com/2469041/dff9c1441ae80e7a0014cf42cc545413.jpg",
  },
  {
    id: 139951,
    name: "FabHotel NStay 32",
    roomType: "Premium Queen Room",
    price: "1530",
    address: "1332-P Jharsa Road, Sector 31-32 A, Gurugram 12200, Haryana",
    facilities: [
      "Air-conditioning",
      "Elevators",
      "Complimentary-newspaper-delivered-to-room",
      "Housekeeping---daily",
      "Phone-services",
      "Smoke-free-property",
      "Complimentary-wireless-internet",
      "Free-parking",
      "Cloakroom-service",
    ],
    imageUrl:
      "https://images.grnconnect.com/1399561/7df2f565f55260fe858021245280a071.jpg",
  },
  {
    id: 246041,
    name: "OYO 79846 Townhouse 292 Atal chowk Indirapuram Ghaziabad",
    roomType: "Classic Double Room",
    price: "1084",
    address: "C34 Sector 15, Ghaziabad, Uttar Pradesh",
    facilities: [
      "Guestroom wireless internet",
      "24-hour front desk",
      "Laundry/Valet service",
      "Multilingual staff",
    ],
    imageUrl:
      "https://images.grnconnect.com/2469041/dff9c1441ae80e7a0014cf42cc545413.jpg",
  },
  {
    id: 139561,
    name: "FabHotel NStay 32",
    roomType: "Premium Queen Room",
    price: "1530",
    address: "1332-P Jharsa Road, Sector 31-32 A, Gurugram 12200, Haryana",
    facilities: [
      "Air-conditioning",
      "Elevators",
      "Complimentary-newspaper-delivered-to-room",
      "Housekeeping---daily",
      "Phone-services",
      "Smoke-free-property",
      "Complimentary-wireless-internet",
      "Free-parking",
      "Cloakroom-service",
    ],
    imageUrl:
      "https://images.grnconnect.com/1399561/7df2f565f55260fe858021245280a071.jpg",
  },
  
];

export default HotelsListData;
