import React from 'react';
import { Container, Nav, Dropdown } from 'react-bootstrap';
import { FaDownload, FaShareAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';


const SecondaryNav = () => {
  return (
    <Nav className="secondary_nav sticky_horizontal stickybar">
      <Container>
      <div className="nav-wrapper">
        <Nav.Item>
          <Nav.Link href="#description" >Overview</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#itinerary">Itinerary</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#inclu_exclu">Inclusion</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#exc_exclu">Exclusion</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#hotels">Hotels</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#terms">Terms & Condition</Nav.Link>
        </Nav.Item>
        </div>
        <div className="download_btn">
          <Link to="https://tripoholidays.com/agent/tour/download/Ii0zLGAKYAo=" className="download_icon"><FaDownload/> <span className='dnonelg'>Download Itinerary</span></Link>
          <Dropdown>
            <Dropdown.Toggle className="sharebtn" variant="none" id="dropdownbtn">
              <FaShareAlt/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link id="WhatsApp" to="https://api.whatsapp.com/send?text=AMSTERDAM%20%2B%20PARIS%20%2B%20SWISS https%3A%2F%2Ftripoholidays.com%2Fagent%2Ftour%2Feurope%2Famsterdam-paris-swiss" target="_blank">
                  <img src="https://tripoholidays.com/public/img/whatsapp.png" className="SocialLinkImage" alt="WhatsApp" title="WhatsApp" style={{ verticalAlign: 'middle' }} /> WhatsApp
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link id="Facebook" to="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftripoholidays.com%2Fagent%2Ftour%2Feurope%2Famsterdam-paris-swiss&amp;title=AMSTERDAM%20%2B%20PARIS%20%2B%20SWISS" target="_blank">
                  <img src="https://tripoholidays.com/public/img/facebook.png" className="SocialLinkImage" alt="Facebook" title="Facebook" style={{ verticalAlign: 'middle' }} /> Facebook
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link id="Twitter" to="https://twitter.com/intent/tweet?text=AMSTERDAM%20%2B%20PARIS%20%2B%20SWISS- https%3A%2F%2Ftripoholidays.com%2Fagent%2Ftour%2Feurope%2Famsterdam-paris-swiss" target="_blank">
                  <img src="https://tripoholidays.com/public/img/twitter_opt.png" className="SocialLinkImage" alt="Twitter" title="Twitter" style={{ verticalAlign: 'middle' }} /> Twitter
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link id="LinkedIn" to="http://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Ftripoholidays.com%2Fagent%2Ftour%2Feurope%2Famsterdam-paris-swiss" target="_blank">
                  <img width="30" height="30" src="https://tripoholidays.com/public/img/linkedIn_PNG36.png" className="SocialLinkImage" alt="LinkedIn" title="LinkedIn" style={{ verticalAlign: 'middle' }} /> LinkedIn
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Nav>
  );
};

export default SecondaryNav;
