import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import StatisticsItem from "./StatisticsItem";
import "./Statistics.css";

// Import the array of objects containing details for each item
// import StatisticsData from "./StatisticsData";

const Statistics = ({ StatisticsData }) => {
  return (
    <div className="bg_cldt stat-container">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="countbox">
              <ul className="d-flex">
                {/* Map over the data and render StatisticsItem for each item */}
                {StatisticsData.map((data, index) => (
                  <StatisticsItem
                    key={index}
                    iconSrc={data.iconSrc}
                    count={data.count && parseInt(data.count, 10)}
                    label={data.label}
                  />
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const StatisticsItem = ({ iconSrc, count, label }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = count;
    const duration = 5000; // 5 seconds
    const range = end - start;
    // let current = start;
    let incrementval;

    if (range >= 1000000) {
      incrementval = 5000;
    } else if (range >= 100000) {
      incrementval = 1000;
    } else if (range >= 10000) {
      incrementval = 100;
    } else if (range >= 1000) {
      incrementval = 10;
    } else {
      incrementval = 1;
    }

    const increment = end > start ? incrementval : -1;
    const steps = Math.abs(range);
    const interval = duration / steps;

    const timer = setInterval(() => {
      start += increment;
      setCounter(start);

      if (start === end) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [count]);

  return (
    <li>
      <div className="count">
        <span>
          <img src={iconSrc} className="rps" alt="" />
        </span>
        {count && <h2 className="counter">{counter}+</h2>}
        <p>{label}</p>
      </div>
    </li>
  );
};

export default Statistics;
