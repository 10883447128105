import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./HotelSection.css";
import { useNavigate } from "react-router-dom";

const HotelSection = () => {
  const navigate=useNavigate();
  
  return (
    <body
      className="home page-template-default page page-id-20 wp-custom-logo ehf-template-astra ehf-stylesheet-astra ast-page-builder-template ast-no-sidebar astra-4.6.12 ast-single-post ast-replace-site-logo-transparent ast-inherit-site-logo-transparent ast-theme-transparent-header ast-hfb-header elementor-default elementor-kit-5 elementor-page elementor-page-20 e--ua-blink e--ua-chrome e--ua-webkit ast-desktop"
    >
      <div className="hfeed site" id="page">
        <div id="content" className="site-content">
          <div className="ast-container">
            <div id="primary" className="content-area primary">
              <main id="main" className="site-main">
                <article
                  className="post-20 page type-page status-publish ast-article-single"
                  id="post-20"
                  itemtype="https://schema.org/CreativeWork"
                  itemscope="itemscope"
                >
                  <header className="entry-header ast-no-title ast-header-without-markup"></header>
                  <div className="entry-content clear" itemprop="text">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id="20"
                      className="elementor elementor-20"
                    >
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-352b6c3 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                        data-id="352b6c3"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-background-overlay"></div>
                        <Container className="elementor-container elementor-column-gap-no">
                          {/* <Row> */}
                          <Col
                            md={12}
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-19d0ec4"
                            data-id="19d0ec4"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-5e011cd elementor-widget elementor-widget-heading"
                                data-id="5e011cd"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h6 className="elementor-heading-title elementor-size-default">
                                    Welcome to
                                  </h6>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-4cd96e8 elementor-widget elementor-widget-heading"
                                data-id="4cd96e8"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h1 className="elementor-heading-title elementor-size-default">
                                  World’s leading online B2B Portal
                                  </h1>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-3fbecc2 elementor-widget elementor-widget-text-editor"
                                data-id="3fbecc2"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                  Find the best deals for your travel needs. With our platform, you can book and manage your arrangements seamlessly. Experience hassle-free booking and exceptional service.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-3640939 elementor-widget elementor-widget-button"
                                data-id="3640939"
                                data-element_type="widget"
                                data-widget_type="button.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-button-wrapper">
                                    <a
                                      className="elementor-button elementor-button-link elementor-size-sm"
                                      href="#"
                                    >
                                      <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-text"
                                        onClick={()=> navigate("/agent-registration")}
                                        >
                                          Join Us
                                        </span>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {/* </Row> */}
                        </Container>
                      </section>
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default HotelSection;
