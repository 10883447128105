const destinationsData = [
  {
    destination: "Turkey",
    tour_packages: [
      {
        name: "Istanbul Holiday Package 4 Night 5 Days",
        duration: "4",
        type: "Fixed Departure",
        price: " 1200",
        address:
          "Istanbul 3N, Cappadocia 2N, Pamukkale 1N, Kusadasi 2N, Istanbul 1N",
        tags: ["Hotel Stay", "Hotels"],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "https://tripoholidays.com/public/img/media_gallery/Holiday4Turkey Site Foto (65)_eX4F1eiFOX.png",
        link: "/agent/tour/turkey/istanbul-holiday-package-4-night-5-days",
      },
      {
        name: "Delight Turkey Package",
        duration: "6",
        type: "Customized Departure",
        price: " 3000",
        address: "3 Nights Istanbul + 3 Nights Cappadocia",
        tags: [],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "https://tripoholidays.com/public/img/media_gallery/Holiday4Turkey Site Foto - 2023-05-24T133256_gLjFDkvUxj.png",
        link: "/agent/tour/turkey/delight-turkey-package",
      },
      {
        name: "Turkey Test Package",
        duration: "5",
        type: "Customized Departure",
        price: " 180",
        address: "Turkey Test Package",
        tags: ["Hotel Stay", "Sightseeing"],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
        link: "/agent/tour/turkey/turkey-test-package",
      },
    ],
  },
  {
    destination: "Europe",
    tour_packages: [
      {
        name: "Paris",
        duration: "6",
        type: "Customized Departure",
        price: "900",
        address: "2 nights Paris + 2 nights Spain + Brussels",
        tags: ["Hotel Stay", "Sightseeing", "Transport"],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
        link: "/agent/tour/europe/paris",
      },
      {
        name: "AMSTERDAM + PARIS + SWISS",
        duration: "8",
        type: "Fixed Departure",
        price: "600",
        address: "2 nights Amsterdam + 3 nights Paris + 3 nights Zurich",
        tags: ["Sightseeing", "Hotels", "Transport"],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
        link: "/agent/tour/europe/amsterdam-paris-swiss",
        // Updated information for the "AMSTERDAM + PARIS + SWISS" package
        carousel_images: [
          "https://tripoholidays.com/public/img/media_gallery/53_ziNRjBoZAl.jpg",
          "https://tripoholidays.com/public/img/media_gallery/turkey_8abKNZOwKF.jpg",
        ],
        carousel_tags: [
          {
            title: "Sightseeing",
            image:
              "https://tripoholidays.com/public/img/topinclusion_img/sightseeing_swvwrkqiB3_DMWzWTe4Ge.png",
          },
          {
            title: "Hotels",
            image:
              "https://tripoholidays.com/public/img/topinclusion_img/hotelsNew_NnWt9MpwPr_gADqFJcxmV_D1Y5UgAgBO.png",
          },
          {
            title: "Transport",
            image:
              "https://tripoholidays.com/public/img/topinclusion_img/transport_f5kxfTgkeo_UWNFBFVQz2.png",
          },
        ],
        
        inclusion: [
          "Sightseeing as per the itinerary.",
          "Train tickets",
          "All escorted transfers.",
          "Hotel accommodation at the above mentioned hotels (or similar category) regarding availability by the time of desired tour dates.",
          "English speaking government licensed professional tour guide.",
          "All the entry fees to sites and museums.",
          "Meals as per indicated in the itinerary",
          "Transportation by A/C nonsmoking and sanitized vehicle.",
          "Local taxes and VAT.",
        ],
        exclusion: [
          "International flights.",
          "All meals and beverages which are not indicated in the itinerary.",
          "PCR test before departure from Turkey (either last overnight day at a hospital or at the airport on departure day).",
          "All personal expenditures.",
          "Tips to the tour guide and driver.",
          "Schengen Visa Fee",
          "Travel insurance",
          "Everything which is not particularly mentioned in Including section.",
        ],
        hotels: [
          {
            name: "Hotel Amsterdam",
            star: 5,
            city: "Amsterdam",
            image:
              "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
          },
          {
            name: "Hotel Paris",
            star: 4,
            city: "Paris",
            image:
              "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
          },
          {
            name: "Hotel Zurich",
            star: 4,
            city: "Zurich",
            image:
              "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
          },
        ],
        terms: [
          "Please note that cancellation fees are calculated based on the date we receive your signed Cancellation Request Form and travel service start date.",
          "The notification day and departure day are NOT counted. Eg. If the tour departure day is April 4th, we receive the official cancellation request on April 1st, it will be counted as 2 days prior to the departure date.",
          "If a tour is scheduled to depart within the next 6 days, we will not change a travelers departure date and will not offer a refund.",
          "All cancellations must be made directly with, not with tour providers.",
          "In most cases, reservations paid by wire transfer, cash deposit, or money order/travelers check/cashiers check will be refunded via bank transfer. For refunds processed by wire transfer, any associated wire transfer fee will be deducted from the overall refund amount.",
        ],
        descriptions:[
          { label: 'No. of Guest', value: '02 Adults' },
          { label: 'Rooms', value: '01' },
          { label: 'Date of Travel', value: '9th Aug 2023' },
          { label: 'Destination', value: '02 Night stay in Amsterdam' },
          { label: '', value: '03 Night stay in Paris' },
          { label: '', value: '04 Night stay in Zurich' }
        ],
        itenary:[
          {
            id: 1,
            day: '1',
            title: 'AMSTERDAM ARRIVAL',
            description: 'Arrive Amsterdam Airport (AMS). Check-in at the Hotel and rest of day is yours.',
            meals: ['Breakfast'],
            activity: 'effile tower',
            activityDescription: 'ftffghfhfhfh',
            activityPrice: '€50'
          },
          // Add the second list item here
          {
            id: 2,
            day: '2',
            title: 'ROTTERDAM, DELFT & THE HAGUE FULL-DAY TOUR',
            description: 'Explore southern Holland on a full-day tour to Delft, Rotterdam, and The Hague. See an old Dutch city, view one of the world\'s largest harbors, tour the buildings of the Dutch Parliament and visit the miniature town, Madurodam.',
            meals: ['Breakfast'],
            activity: 'effile tower',
            activityDescription: 'ftffghfhfhfh',
            activityPrice: '€50'
          },
          {
              id: 3,
              day: '3',
              title: 'AMSTERDAM TO PARIS BY TRAIN',
              description: 'Breakfast at hotel (depends on your train timing). Check out from hotel (international check out time is 12:00 o\'clock). We’ll pick you up from your hotel at around 8.00 – 8.30 AM for your train.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 4,
              day: '4',
              title: 'EIFFEL TOWER TOUR',
              description: 'Discover the Eiffel Tower with an access ticket to the 2nd level or to the summit by elevator. Enjoy a presentation from your guide and admire the incredible views of Paris from above. Lessen your waiting time in the long queues to one of the capital\'s most unforgettable monuments with an access ticket to the second level and, if the option is selected, to the summit. Learn more about the tower from your guide. Enjoy the viewing platforms and gaze out at Notre Dame Cathedral, Les Invalides, all at your own pace. Following a short introduction that will reveal to you the history of the emblematic Tower, ascend to the 2nd Level by elevator. Head up to the Summit by elevator if you\'ve selected this option. Take in the spectacular views of the French capital and spot emblematic monuments from above.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            // Add the fourth list item here
            {
              id: 5,
              day: '5',
              title: 'PARIS – HOP ON-HOP OFF BUS TOUR',
              description: 'Enjoy fantastic views and experience the charming atmosphere of Paris aboard an open-top double-decker sightseeing bus. Choose from a 24 or 48-hour hop-on hop-off ticket or upgrade to the 48-hour Hop-on Hop-off with Seine River cruise option. Discover iconic attractions like Notre Dame, the Arc de Triomphe, and the Sacre Coeur. Take in the sights at the lavish Champs-Elysées, the historical Grand Palais, Opera Garnier, and Louvre Museum. Explore Paris with a narrated tour with daily departures. You may join the tour from any of the stops on the route: Tour Eiffel, Champ de Mars, Opéra Garnier, Louvre-Pyramide / Big Bus Information Centre, Louvre-Pont des Arts, Notre Dame.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 6,
              day: '6',
              title: 'PARIS TO ZURICH BY TRAIN',
              description: 'Breakfast at hotel (depends on your train timing). Check out from hotel (international check out time is 12:00 o\'clock). We’ll pick you up from your hotel at around 8.00 – 8.30 AM for your train.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 7,
              day: '7',
              title: 'STEIN AM RHEIN AND RHINE FALLS',
              description: 'Spend the afternoon in northern Switzerland on a half-day trip from Zurich. Travel through charming villages, visit Europe\'s largest waterfall, and the medieval village of Stein am Rhein. Travel out of Zürich for a scenic tour through dramatic natural landscapes and historic landmarks. Come across quaint villages and vast vineyards, and take the lift to the Rhine Falls. Depart Zürich and journey towards the Swiss/German border of northern Switzerland. Travel through a beautiful wine-growing region of charming farming villages and picturesque landscapes en route to Laufen. See the splendid medieval castle of Schloss Laufen and the mighty Rhine Falls and experience Europe\'s biggest waterfall. Get unique views from the panoramic lift and discover what it looks like when an average of 700,000 liters of water per second plunges into the basin below. If you are feeling adventurous, take the optional boat ride and experience the falls from below. Continue via Schaffhausen with its famous Munot Fortress. Soak up the views of the gently rolling vineyards and farming villages en route to Stein am Rhein. Upon arrival, learn about the well-preserved old town during a stroll. See distinctive medieval buildings such as the church and former monastery of St. George\'s Abbey. Look at the half-timber frame townhouses, along with the city gates and towers, the Town Hall, and the Armory. Admire the delicate wooden window frames of many of the buildings and marvel at their exceptional frescoes.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 8,
              day: '8',
              title: 'MOUNT TITLIS DAY TOUR',
              description: 'Explore the charming Swiss city of Lucerne before enjoying a ride up Mount Titlis. Take in 360-degree views of the Swiss Alps from the Rotair revolving cable car and stop by an ice grotto. Enjoy a day trip from Zurich to the winter wonderland of Mount Titlis with a guide. Discover the beauty of Lucerne, enjoy a scenic drive to Engelberg, experience an unforgettable adventure on the Ice Flyer chairlift, and cross the Titlis Cliff Walk bridge suspended over the Swiss Alps. Departing from Zurich, take a bus ride over the Albis Pass and along the shores of Lake Lucerne on the way to the city of Lucerne. Along the way, your tour guide will point out the city\'s most important sights and include a photo stop at the Lion Monument or the lakeside, depending on traffic. Afterward, enjoy some free time to explore Lucerne on your own. From there, continue the journey to Engelberg. The breathtaking ride up into the high alpine glaciers of Mount Titlis begins here. From the Rotair revolving cable car on your way up to the mountain, get spectacular views of the deep crevasses in the glaciers, and the immense chunks of ice that break off in spectacular fashion. Enjoy incredible views over the Swiss Alps from the Panorama Restaurant on top of Mount Titlis. Visit the Ice Grotto, or take the Ice Flyer chairlift to get up close to the glaciers at an altitude of over 9,800 feet (3,000 meters). Adventure seekers can slide through the snow and down the mountainside on a snow tube – the choice is yours.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 9,
              day: '9',
              title: 'FREE DAY IN ZURICH',
              description: 'breakfast at hotel, after than rest of day is yours. Overnight stay at hotel.',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            },
            {
              id: 10,
              day: '10',
              title: 'ZURICH DEPARTURE',
              description: 'Breakfast at hotel (depends on your flight timing). Check out from hotel (international check out time is 12:00 o\'clock). We will inform you for certain pick up timing, 1 day before the transfer (Generally 3 hrs before your flight time). Meet with your driver at the hotel lobby, Private transfer to Zurich Airport. (distance is approx1 hrs / depends on road conditions & traffic).',
              meals: ['Breakfast'],
              activity: 'effile tower',
              activityDescription: 'ftffghfhfhfh',
              activityPrice: '€50'
            }
          // Add more itinerary items here if needed
        ]
      },
    ],
  },
  {
    destination: "Vietnam",
    tour_packages: [
      {
        name: "Tour 1",
        duration: "2",
        type: "Fixed Departure",
        price: "1000",
        address: "2 nights",
        tags: ["Hotel Stay", "Sightseeing"],
        theme: ["Honeymoon", "Test Theme 1"],
        image:
          "	https://tripoholidays.com/public/img/media_gallery/ooty_rRQjsxT2kL.jpg",
        link: "/agent/tour/vietnam/tour-1",
      },
    ],
  },
  {
    destination: "Russia",
    tour_packages: [],
  },
];

export default destinationsData;
