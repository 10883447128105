import React, { useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { Link } from "react-router-dom";

// make modal diifferent for each iternary


const Itinerary = ({ packageName }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = (activity) => {
    setSelectedActivity(activity);
    setShowModal(true);
  };

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <section id="itinerary" className="common_section">
      <h2>Itinerary</h2>
      <ul className="cbp_tmtimeline">
        {packageName.package_itinerary.map((item,index) => (
          <li key={item.id} id={`addon_${item.package_id}`}>
            <div className="cbp_tmtime"><span>Day</span></div>
            <div className="cbp_tmicon">{index+1}</div>
            <div className="cbp_tmlabel">
              <h4
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}>
                {item.title}
              </h4>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "justify",
                  marginBottom: "10px",
                }}
                dangerouslySetInnerHTML={renderHTML(item.details)}>
                {/* {item.details} */}
              </p>
              <div className="itinery_meals">
                <h6>Meals:</h6>
                <ul className="bullets">
                  {/* {item.meals.map((meal, index) => ( */}
                    {/* <> */}
                    <li><GiForkKnifeSpoon className="meal_icon"/></li>
                    <li 
                    // key={index}
                    >{item.foodtype}</li>
                    {/* </> */}
                  {/* ))} */}
                </ul>
              </div>
              <div className="viewd_pop">
                <div className="add-activity-card">
                  <p>
                    <i>
                      <b>End Of Day -</b> Spend time at Leisure or add an
                      activity to your day
                    </i>
                  </p>
                  <Button variant="primary" className="btnactv" onClick={() => handleShow(item)}>
                    ADD ACTIVITY TO DAY
                  </Button>
                </div>
              </div>
            </div>
            {/* Modal */}
            <Modal
              show={
                showModal && selectedActivity && selectedActivity.id === item.id
              }
              onHide={handleClose}
              // animation={false}
              centered
              className="right fade"
              >
              <Modal.Header closeButton>

                <Modal.Title>Add Activity</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="rpall_scrl">
                  <div className="box_pop_rpt shaddon_22" addonid="22">
                    <div className="box_rpteq d-flex">
                      <div className="pck_eq_img">
                        <img
                          src={item.itinerary_image}
                          alt=""
                        />
                      </div>
                      <div className="pck_eq_txt">
                        <div className="badges_eq">
                          {/* Add activity details here */}
                        </div>
                        <div className="vl_txt d-flex">
                          <div className="dssl_s">
                            <h3 className="activityHeading">
                              {selectedActivity?.activity}
                            </h3>
                            <div className="read_hd">
                              <p>{selectedActivity?.activityDescription}</p>
                            </div>
                          </div>
                          <div className="charges_xs">
                            <span className="pr_xs">
                              + {selectedActivity?.activityPrice}
                            </span>
                            <span className="perPerson">Price/Person</span>
                            <Link
                              to="javascript:;"
                              className="selectpackage"
                              data-id="22">
                              Select
                            </Link>
                            <Link
                              style={{ display: "none" }}
                              to="javascript:;"
                              className="removepackage"
                              data-id="">
                              Remove
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="makeFlex column detailsWrapper">
                  <p className="font14 latoBlack appendBottom5 greyText2 detailsContainer">
                    <span className="selected_day">Day {item.day}</span>
                    <span className="font12 whiteText appendLeft5 latoRegular selectedDate selectedNameList selected_itenery">
                      <span>{item.title}</span>
                    </span>
                  </p>
                  <div className="font18 latoBold whiteText makeFlex">
                    <span className="amount">
                      + {selectedActivity?.activityPrice}
                    </span>
                  </div>
                </div>
                <div className="makeFlex column packageUpdate">
                  <p
                    style={{ display: "none" }}
                    className="latoBlack font12 appendBottom6 lineHeight16 packageSubTotal">
                    Package Subtotal
                  </p>
                  <p
                    style={{ display: "none" }}
                    className="font22 latoBold whiteText lineHeight25 price">
                    <span className="sub_total">+ €650</span>
                    <span className="font14 regular person"> / Person</span>
                  </p>
                  <div className="updatePackageBtnWrapper btn">
                    <p
                      data-addond={item.id}
                      className="updatePackageBtnText font10 btn btn-primary btn-sm">
                      Update Package
                    </p>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Itinerary;
