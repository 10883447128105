import React from "react";
import HotelCustomBanners from "./HotelCustomBanners";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./TransferPage.css";
import TransferCustomBanners from "./TransferCustomBanners";

const ImageCard = ({ imageUrl, title, description }) => {
  return (
    <Col md={4}>
      <Card className="image_card">
        <Card.Img variant="top" src={imageUrl} alt={title} />
        <Card.Body className="card_content text-center">
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

const TransfersPage = () => {
  return (
    <div className="transfer_page">
      <TransferCustomBanners/>

      <section className="py-50">
        <Container>
          <Row>
            <ImageCard
              imageUrl="https://tripoholidays.com/public/images/low-cost-transfers.jpg"
              title="Free Cancellation"
              description="If your plans change, you can cancel your reservation free of charge up to 24 hours before the scheduled pick up time"
            />
            <ImageCard
              imageUrl="https://tripoholidays.com/public/images/professional-service.jpg"
              title="Professional Service"
              description="We use reputable airport transfer service providers employing professional and reliable drivers"
            />
            <ImageCard
              imageUrl="https://tripoholidays.com/public/images/low-cost-transfers.jpg"
              title="Low Cost Transfers"
              description="Our team compares airport taxi prices daily to offer you the lowest rate for each vehicle category"
            />
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default TransfersPage;
