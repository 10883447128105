import React, { useEffect, useState, useCallback } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { FaChevronRight, FaMapMarkerAlt } from "react-icons/fa";
import destinationsData from "./DestinationsData";
import "./Destinations.css";

const PackageList = ({ packageList }) => {
  const [filteredPackages, setFilteredPackages] = useState(packageList);
  const [filters, setFilters] = useState({
    name: "",
    priceRange: [180, 3000],
    duration: "",
    packageType: "",
    topInclusions: [],
    packageThemes: [],
  });

  // Filtering logic based on selected filter criteria
  const applyFilters = useCallback(() => {
    setFilteredPackages((prevPackages) => {
      let filtered = packageList.filter((packageItem) => {
        // Filter by package name
        if (
          filters.name &&
          !packageItem.name.toLowerCase().includes(filters.name.toLowerCase())
        ) {
          return false;
        }
        // Filter by price range
        if (
          packageItem.price < filters.priceRange[0] ||
          packageItem.price > filters.priceRange[1]
        ) {
          return false;
        }
        // Filter by duration (no. of nights)
        if (
          filters.duration &&
          packageItem.duration != parseInt(filters.duration)
        ) {
          return false;
        }
        // Filter by package type
        if (
          filters.packageType.length > 0 &&
          !filters.packageType.includes(packageItem.type)
        ) {
          return false;
        }
        // Filter by top inclusions
        if (filters.topInclusions.length > 0) {
          const intersection = packageItem.tags.filter((tag) =>
            filters.topInclusions.includes(tag)
          );
          if (intersection.length === 0) {
            return false;
          }
        }
        // Filter by package themes
        if (filters.packageThemes.length > 0) {
          const intersection = packageItem.theme.filter((theme) =>
            filters.packageThemes.includes(theme)
          );
          if (intersection.length === 0) {
            return false;
          }
        }
        return true;
      });
      return filtered;
    });
  }, [filters, packageList]);

  // Apply filters automatically for larger screens (> 992px) and on button click for smaller screens (<= 992px)
  useEffect(() => {
    const applyFiltersAutomatically = () => {
      if (window.innerWidth > 768) {
        applyFilters();
      }
    };
    applyFiltersAutomatically();
    window.addEventListener("resize", applyFiltersAutomatically);
    return () => {
      window.removeEventListener("resize", applyFiltersAutomatically);
    };
  }, [filters, applyFilters]);

  return (
    <Container>
      <Row>
        {/* <button
          id="cleflt"
          className="clearfilter"
          // onClick={handleClearAll}
        >
          Filter
        </button> */}
        <Col lg={3} md={3} sm={12} className="cus_col_3 sidebar_filter">
          <FilterComponent
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
          />
        </Col>
        <Col lg={9} md={9} sm={12} className="pad_xs_10 cus_col_9">
          <Container className="package-container">
            {filteredPackages.map((packageItem, index) => (
              <Card className="package_item" key={index}>
                <Row className="trip_package_content">
                  <Col lg={4} md={4} sm={4} xs={12} className="trip_pic_col">
                    <div className="pack_img">
                      <Card.Img src={packageItem.image} alt="" />
                      <div className="pack_nts">
                        <span>{packageItem.duration} nights</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={12} className="trip_pic_detail">
                    <Card.Body>
                      <Card.Title className="trip_type">
                        {packageItem.type}
                      </Card.Title>
                      <Card.Title className="trip_name">
                        <Link to={packageItem.link} className="pkg_name">
                          {packageItem.name}
                        </Link>
                        <div className="p_price">
                          <span className="price">€ {packageItem.price}</span>
                          <p>Per Person</p>
                        </div>
                      </Card.Title>
                      <Card.Text className="trip_address">
                        <span>
                          <FaMapMarkerAlt className="mapicon" />{" "}
                          {packageItem.address}
                        </span>
                        <Link to={packageItem.link} className="btn btn-book-now">
                          Book Now <FaChevronRight />
                        </Link>
                      </Card.Text>
                      <div className="trip_tags">
                        <ul>
                          {packageItem.tags.map((tag, index) => (
                            <li key={index}>{tag}</li>
                          ))}
                        </ul>
                      </div>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

const FilterComponent = ({ filters, setFilters, applyFilters }) => {
  const [sliderValue, setSliderValue] = useState([180, 3000]);

  const handleClearAll = () => {
    setFilters({
      name: "",
      priceRange: [180, 3000],
      duration: "",
      packageType: "",
      topInclusions: [],
      packageThemes: [],
    });
    setSliderValue([180, 3000]); // Reset slider value
    // Uncheck all checkboxes
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    applyFilters(); // Apply filters after clearing
  };

  // Function to handle slider change
  const handleSliderChange = (value) => {
    setSliderValue(value);
    setFilters({ ...filters, priceRange: value });
  };

  // Function to handle other filter changes
  const handleFilterChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: checked
          ? [...prevFilters[name], value]
          : prevFilters[name].filter((item) => item !== value),
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  return (
    // <div className="col-lg-3 col-md-3 col-lg-pull-9 col-md-pull-9 col-sm-12 cus_col_3 sidebar_filter">
    <div className="custom_sidebar">
      <div className="filter_head">
        {/* <FaWindowClose className="filter_close icon" /> */}
        <div className="filter_title">
          <h3>
            Filter{" "}
            <button
              id="cleflt"
              className="clearfilter"
              onClick={handleClearAll}>
              Clear All
            </button>
          </h3>
        </div>
      </div>
      <div className="inner_filter">
        <div className="box-widget">
          <h5 className="box-title">Package Name</h5>
          <div className="box-content">
            <Form.Control
              type="text"
              className="form-control hotelname_search"
              placeholder="Enter Name"
              onChange={handleFilterChange}
              name="name"
            />
          </div>
        </div>

        <div className="box-widget">
          <h5 className="box-title">Price Per Person</h5>
          <div className="box-content">
            <input type="hidden" className="pricenew" value="180-3000" />
            <div className="slider-dragable-range slider-range-price">
              <Slider
                range
                min={180}
                max={3000}
                value={sliderValue}
                onChange={handleSliderChange}
                className="custom-slider"
              />
              <div className="slider-value">
                <span>€{sliderValue[0]}</span> <span>-</span> <span>€{sliderValue[1]}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="box-widget nonight_widget">
          <h5 className="box-title">No. of Nights</h5>
          <div className="box-content">
            <Form.Control
              as="select"
              name="duration"
              id="no_of_night"
              onChange={handleFilterChange}
              className="form-control coofliter">
              <option value="">-- Select No. of Nights --</option>
              {[...Array(30).keys()].map((night) => (
                <option key={night} value={night + 1}>
                  {night + 1} night{night !== 0 && "s"}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>

        <div className="box-widget">
          <h5 className="box-title">Package Type</h5>
          <div className="box-content">
            <ul id="myULairtype" className="check-boxes-custom list-checkboxes">
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Fixed Departure"
                  id="optionfixed"
                  name="packageType"
                  onChange={handleFilterChange}
                  value="Fixed Departure"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Group Departure"
                  id="optiongroup"
                  name="packageType"
                  onChange={handleFilterChange}
                  value="Group Departure"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Customized Departure"
                  id="optioncustomized"
                  name="packageType"
                  onChange={handleFilterChange}
                  value="Customized Departure"
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="box-widget">
          <h5 className="box-title">Top Inclusions</h5>
          <div className="box-content">
            <ul id="myULairfac" className="check-boxes-custom list-checkboxes">
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Hotel Stay"
                  id="option9"
                  name="topInclusions"
                  onChange={handleFilterChange}
                  value="Hotel Stay"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Hotels"
                  id="option11"
                  name="topInclusions"
                  onChange={handleFilterChange}
                  value="Hotels"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Flight"
                  id="option12"
                  name="topInclusions"
                  onChange={handleFilterChange}
                  value="Flight"
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="box-widget">
          <h5 className="box-title">Package Theme</h5>
          <div className="box-content">
            <ul id="myULairfac" className="check-boxes-custom list-checkboxes">
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Honeymoon"
                  id="option1"
                  name="packageThemes"
                  onChange={handleFilterChange}
                  value="Honeymoon"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Test Theme 1"
                  id="option4"
                  name="packageThemes"
                  onChange={handleFilterChange}
                  value="Test Theme 1"
                />
              </li>
              <li>
                <Form.Check
                  className="label-container checkbox-default"
                  type="checkbox"
                  label="Test Theme 2"
                  id="option5"
                  name="packageThemes"
                  onChange={handleFilterChange}
                  value="Test Theme 2"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="applyfilter_btn">
        <Button
          type="button"
          className="apply_btn btn_gradient"
          onClick={handleApplyFilters}>
          Apply Filter
        </Button>
      </div>
    </div>
    // </div>
  );
};

const InnerBanner = ({ destination }) => {
  return (
    <section className="custom_banner listPackagesBanner" id="banner">
      <div className="banner-parallax">
        <div
          className="bg-element"
          data-stellar-background-ratio="0.2"
          style={{
            backgroundImage: `url(	https://tripoholidays.com/public/images/turkey.jpg)`,
          }}></div>
        <img src="https://tripoholidays.com/public/images/turkey.jpg" alt="" />
        <div className="banner_search tour_package_search">
          <Container className="conn">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="banner_search_box">
                  <h1>{destination?.destination}</h1>
                  <Form
                    id="searchform"
                    className="booking_search_form search_form"
                    action="https://tripoholidays.com/tour/list"
                    method="get">
                    <div id="custom-search-input" className="d-flex">
                      <div id="mysearchcity" className="input_field searchcity">
                        <div className="input-box">
                          <input
                            type="hidden"
                            data-valid="required"
                            name="name"
                            id="roundfromsearch"
                            value={destination?.destination}
                          />
                          <input
                            type="hidden"
                            data-valid="required"
                            name="c"
                            id="hotelcountrycode"
                            value=""
                          />
                          <input
                            type="hidden"
                            data-valid="required"
                            name="d"
                            id="fromcityid"
                            value={destination?.cityId}
                          />
                          <Form.Control
                            name="city"
                            readOnly
                            value={destination?.destination}
                            type="text"
                            className="search-query ui-autocomplete-input"
                            placeholder="Search your Dream Destination!"
                            autoComplete="off"
                          />
                          <FaMapMarkerAlt className="iconnn" />
                        </div>
                      </div>
                      <div className="search_btn">
                        <Button className="boxed-btn4" type="submit">
                          Search
                        </Button>
                      </div>
                    </div>
                    {/* <div className="clearfix"></div> */}
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

const Destinations = () => {
  const { destination } = useParams(); // Get destination from URL parameter
  const [destinationData, setDestinationData] = useState(null);

  // Find the data for the specified destination
  useEffect(() => {
    const foundDestination = destinationsData.find(
      (data) => data.destination.toLowerCase() === destination.toLowerCase()
    );
    if (foundDestination) {
      setDestinationData(foundDestination);
    }
  }, [destination]);

  // If destinationData is not set yet, return null or a loading indicator
  if (!destinationData) {
    return <div>Loading...</div>;
  }

  const { tour_packages } = destinationData;

  return (
    <section className="flight_list_sec single_sec_flat tour_search">
      <InnerBanner destination={destinationData} />
      <PackageList packageList={tour_packages} />
    </section>
  );
};

export default Destinations;
