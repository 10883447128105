import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import Careers from "./components/Careers/Careers";
import NotFound from "./components/NotFound/NotFound";
import Protected from "./components/Protected/Protected";
import TermsNConditions from "./components/Pages/TermsNConditions";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import Disclaimer from "./components/Pages/Disclaimer";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import FAQ from "./components/FAQ/FAQ";
import AgentRegistration from "./components/AgentRegistration/AgentRegistration";
import DashboardNavbar from "./dashboard/home/DashboardNavbar";
import DashboardHome from "./dashboard/home/DashboardHome";
import HotelBookingPage from "./dashboard/booking/hotelbooking/HotelBookingPage";
import PackageBookingPage from "./dashboard/booking/packagebooking/PackageBookingPage";
import SightBookingPage from "./dashboard/booking/sightbooking/SightBookingPage";
import TransferBookingPage from "./dashboard/booking/transferbooking/TransferBookingPage";
import NewHotelMarkup from "./dashboard/markup/hotelmarkup/NewHotelMarkup";
import HotelMarkupTable from "./dashboard/markup/hotelmarkup/HotelMarkupTable";
import EditHotelMarkup from "./dashboard/markup/hotelmarkup/EditHotelMarkup";
import EditPackageMarkup from "./dashboard/markup/packagemarkup/EditPackageMarkup";
import NewPackageMarkup from "./dashboard/markup/packagemarkup/NewPackageMarkup";
import PackageMarkupTable from "./dashboard/markup/packagemarkup/PackageMarkupTable";
import SightMarkupTable from "./dashboard/markup/sightmarkup/SightMarkupTable";
import NewSightMarkup from "./dashboard/markup/sightmarkup/NewSightMarkup";
import EditSightMarkup from "./dashboard/markup/sightmarkup/EditSightMarkup";
import TransferMarkupTable from "./dashboard/markup/transfermarkup/TransferMarkupTable";
import NewTransferMarkup from "./dashboard/markup/transfermarkup/NewTransferMarkup";
import EditTransferMarkup from "./dashboard/markup/transfermarkup/EditTransferMarkup";
import HotelCancelPage from "./dashboard/cancel/hotelcancel/HotelCancelPage";
import SightAmendmentPage from "./dashboard/amendments/sightamendment/SightAmendmentPage";
import HotelPage from "./dashboard/products/HotelPage";
import SightseeingPage from "./dashboard/products/SightseeingPage";
import TourPackagePage from "./dashboard/products/TourPackagePage";
import TransfersPage from "./dashboard/products/TransfersPage";
import Destinations from "./dashboard/tourpackage/Destinations";
import Package from "./dashboard/package/Package";
import ScrollToTopButton from "./ScrollToTop";
import Profile from "./dashboard/profile/Profile";
import ChangePassword from "./dashboard/profile/ChangePassword";
import TransactionLog from "./dashboard/walletdeposit/TransactionLog";
import CreditLimitLog from "./dashboard/walletdeposit/CreditLimitLog";
import RechargeHistory from "./dashboard/walletdeposit/RechargeHistory";
import Wallet from "./dashboard/walletdeposit/Wallet";
import ProfitLoss from "./dashboard/bookingreports/ProfitLoss";
import DailySalesReport from "./dashboard/bookingreports/DailySalesReport";
import LedgerReport from "./dashboard/bookingreports/LedgerReport";
import Hotels from "./dashboard/hotellist/Hotels";
import Login from "./components/Login/Login";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/agent/login" element={<Login />} />
          <Route path="/agent-registration" element={<AgentRegistration />} />

          <Route path="/agent/hotels" element={<HotelPage />} />
          <Route path="/agent/sightseeing" element={<SightseeingPage />} />
          <Route path="/agent/tour" element={<TourPackagePage />} />
          <Route path="/agent/transfers" element={<TransfersPage />} />

          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/coming_soon" element={<ComingSoon />} />
          <Route path="/page/terms-conditions" element={<TermsNConditions />} />
          <Route path="/page/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/page/disclaimer" element={<Disclaimer />} />
          <Route path="/faq" element={<FAQ />} />

          <Route path="/agent/dashboard" element={<DashboardHome />} />
          <Route path="/agent/bookings/hotel" element={<HotelBookingPage />} />
          <Route path="/agent/bookings/tour" element={<PackageBookingPage />} />
          <Route
            path="/agent/bookings/sightseeing"
            element={<SightBookingPage />}
          />
          <Route
            path="/agent/bookings/transfer"
            element={<TransferBookingPage />}
          />

          <Route path="/agent/hotelmarkup" element={<HotelMarkupTable />} />
          <Route
            path="/agent/hotelmarkup/create"
            element={<NewHotelMarkup />}
          />
          <Route
            path="/agent/hotelmarkup/edit/:id"
            element={<EditHotelMarkup />}
          />
          <Route path="/agent/packagemarkup" element={<PackageMarkupTable />} />
          <Route
            path="/agent/packagemarkup/create"
            element={<NewPackageMarkup />}
          />
          <Route
            path="/agent/packagemarkup/edit/:id"
            element={<EditPackageMarkup />}
          />
          <Route
            path="/agent/sightseeingmarkup"
            element={<SightMarkupTable />}
          />
          <Route
            path="/agent/sightseeingmarkup/create"
            element={<NewSightMarkup />}
          />
          <Route
            path="/agent/sightseeingmarkup/edit/:id"
            element={<EditSightMarkup />}
          />
          <Route
            path="/agent/transfermarkup"
            element={<TransferMarkupTable />}
          />
          <Route
            path="/agent/transfermarkup/create"
            element={<NewTransferMarkup />}
          />
          <Route
            path="/agent/transfermarkup/edit/:id"
            element={<EditTransferMarkup />}
          />

          <Route
            path="/agent/bookings/hotel/cancel/request/list"
            element={<HotelCancelPage />}
          />
          <Route
            path="/agent/bookings/amendments/lists/sightseeing"
            element={<SightAmendmentPage />}
          />
          <Route
            exact
            path="/agent/tour/:destination"
            element={<Destinations />}
          />
          <Route
            exact
            path="/agent/tour/:destination/:packageName"
            element={<Package />}
          />
          <Route exact path="/agent/profile" element={<Profile />} />
          <Route
            exact
            path="/agent/change_password"
            element={<ChangePassword />}
          />
          <Route
            exact
            path="/agent/transaction_log"
            element={<TransactionLog />}
          />
          <Route
            exact
            path="/agent/credit_limit_log"
            element={<CreditLimitLog />}
          />
          <Route
            exact
            path="/agent/recharge/history"
            element={<RechargeHistory />}
          />
          <Route exact path="/agent/wallet" element={<Wallet />} />
          <Route exact path="/agent/profitloss" element={<ProfitLoss />} />
          <Route
            exact
            path="/agent/report/dailysale"
            element={<DailySalesReport />}
          />
          <Route exact path="/agent/report/ledger" element={<LedgerReport />} />

          <Route exact path="/agent/hotellist" element={<Hotels />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footerr />
        <ScrollToTopButton />
      </div>
    </Router>
  );
}

function Navbar() {
  const location = useLocation();
  // const isAgentRegistration = location.pathname === "/agent-registration";
  const isAgentRoute =
    location.pathname.includes("/agent/") &&
    !location.pathname.startsWith("/agent/login");

  // if (isAgentRegistration) {
  //   return null; // Return null to hide the footer
  // }
  return isAgentRoute ? <DashboardNavbar /> : <Header />;
}

function Footerr() {
  const location = useLocation();
  const isDashboard = location.pathname === "/agent/dashboard";
  const isAgentRegistration = location.pathname === "/agent-registration";

  if (isDashboard || isAgentRegistration) {
    return null; // Return null to hide the footer
  }

  return <Footer />;
}

export default App;
