// Details for each item as an array of objects
const StatisticsData = [
  {
    iconSrc: "https://tripoholidays.com/public/images/COUNTRIES.svg",
    count: "60",
    label: "COUNTRIES",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/HOTELWORLDWIDE.svg",
    count: "2000000",
    label: "HOTEL WORLDWIDE",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/DESTINATION.svg",
    count: "25000",
    label: "DESTINATION",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/OFFICE.svg",
    count: "25",
    label: "OFFICE",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/tours-activites.png",
    count: "50000",
    label: "SIGHTSEEING & TOURS",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/transfer-options.png",
    count: "5000",
    label: "TRANSFERS",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/apartments.png",
    count: "180",
    label: "Apartments & More",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/pay-hotel.png",
    label: "Pay@hotel",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/tripadvisor.png",
    label: "Trip Advisor Ratings",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/prepayment.png",
    label: "Prepayment",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/incentive.png",
    label: "Deposit Incentive",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/zero-card.png",
    label: "Zero Credit Card Charges",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/support-all.png",
    label: "24 x 7 x 365 Support",
  },
  {
    iconSrc: "https://tripoholidays.com/public/images/save-money.png",
    label: "Rate Advantage",
  },
];

export default StatisticsData;
