import React, { useEffect, useRef } from "react";
import DashServices from "./DashServices";
import AmazingDealsCarousel from "./AmazingDealsCarousel";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import {
  FaBell,
  FaBinoculars,
  FaBuilding,
  FaCalendar,
  FaCar,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { IoLogoUsd } from "react-icons/io5";
import Chart from "chart.js/auto";
import "./DashboardHome.css";
import hotelBookingsData from "../booking/hotelbooking/HotelBookingData";
import packageBookingsData from "../booking/packagebooking/PackageBookingData";
import sightBookingsData from "../booking/sightbooking/SightBookingData";
import transferBookingsData from "../booking/transferbooking/TransferBookingData";
import BookingGraph from "./BookingGraph";
import { Link } from "react-router-dom";

const notifications = [
  {
    heading: "Best Travel Deal",
    content:
      "Tripoholidays is providing the best travel deal for Turkey. Please avail it before.",
  },
  {
    heading: "Cheapest Fare Ever - Book Now",
    content:
      "Get The Cheapest deal On Europe Tour before Holi. Special scheme launched.",
  },
  {
    heading: "Let's Get Packing",
    content: "Let's Plan a Party at Paris.",
  },
  {
    heading: "Cheapest Fare Ever - Book Now",
    content:
      "Get The Cheapest deal On Europe Tour before Holi. Special scheme launched.",
  },
  {
    heading: "Cheapest Fare Ever - Book Now",
    content:
      "Get The Cheapest deal On Europe Tour before Holi. Special scheme launched.",
  },
];

const countBookingsWithStatus = (status) => {
  return (
    hotelBookingsData.filter((booking) => booking.status === status).length +
    sightBookingsData.filter((booking) => booking.status === status).length +
    packageBookingsData.filter((booking) => booking.status === status).length +
    transferBookingsData.filter((booking) => booking.status === status).length
  );
};

const DashboardHome = () => {
  const latestHotelBookings = hotelBookingsData
    .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
    .slice(0, 4);

  const latestTourBookings = packageBookingsData
    .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
    .slice(0, 4);

  const latestSightseeingBookings = sightBookingsData
    .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
    .slice(0, 4);

  const latestTransferBookings = transferBookingsData
    .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
    .slice(0, 4);

  const hotelData = [10, 15, 8, 20, 12, 18, 9, 22, 14, 17, 11, 16];
  const tourData = [8, 12, 6, 18, 10, 14, 7, 20, 11, 16, 9, 15];
  const sightseeingData = [5, 9, 4, 12, 7, 10, 6, 14, 8, 11, 5, 10];
  const transferData = [6, 10, 7, 15, 8, 12, 6, 18, 9, 14, 7, 13];

  const chartRef = useRef(null);

  useEffect(() => {
    const data = {
      labels: [
        "Hotel Bookings",
        "Sightseeing Bookings",
        "Tour Bookings",
        "Transfer Bookings",
      ],
      datasets: [
        {
          label: "Booking Comparison",
          data: [
            hotelBookingsData.length,
            sightBookingsData.length,
            packageBookingsData.length,
            transferBookingsData.length,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(224, 147, 72,0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(224, 147, 72,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };

    const myChart = new Chart(chartRef.current, {
      type: "pie",
      data: data,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <div className="dashboard_home">
      <DashServices />
      <Container>
        <Row className="box-icons" style={{ marginBottom: "15px" }}>
          <Col md={6} lg={3} sm={6} xs={12}>
            <Card className="box-bg">
                <div className="info-box">
                <span className="info-box-icon bg-red">
                  <FaBuilding />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Hotel Booking</span>
                  <span className="info-box-number">
                    {hotelBookingsData.length} Booked
                  </span>
                  <span className="info-box-more">
                    <Link to="/agent/bookings/hotel">More Info</Link>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={6} lg={3} sm={6} xs={12}>
            <Card className="box-bg">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <FaBinoculars />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Sightseeing Booking</span>
                  <span className="info-box-number">
                    {sightBookingsData.length} Booked
                  </span>
                  <span className="info-box-more">
                    <Link to="/agent/bookings/sightseeing">More Info</Link>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={6} lg={3} sm={6} xs={12}>
            <Card className="box-bg">
              <div className="info-box">
                <span className="info-box-icon bg-yellow">
                  <FaSuitcaseRolling />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Tour Booking</span>
                  <span className="info-box-number">
                    {packageBookingsData.length} Booked
                  </span>
                  <span className="info-box-more">
                    <Link to="/agent/bookings/tour">More Info</Link>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={6} lg={3} sm={6} xs={12}>
            <Card className="box-bg">
              <div className="info-box">
                <span className="info-box-icon bg-green">
                  <FaCar />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Transfer Booking</span>
                  <span className="info-box-number">
                    {transferBookingsData.length} Booked
                  </span>
                  <span className="info-box-more">
                    <Link to="/agent/bookings/transfer">More Info</Link>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <Row>
              <Card className="booking-graph-container">
                <Card.Header>Booking Trends</Card.Header>
                <Card.Body className="line_graph_wrapper">
                  <BookingGraph
                    hotelData={hotelData}
                    tourData={tourData}
                    sightseeingData={sightseeingData}
                    transferData={transferData}
                  />
                </Card.Body>
              </Card>
            </Row>
          </Col>

          <Col md={3}>
            <Row className="row mb30">
              <Col md={12} className="resp-mt-20">
                <Card
                  className="dashboardbox dashboxcolor2"
                  style={{ marginBottom: "5px" }}>
                  <Card.Body style={{ padding: "0px" }}>
                    <h2 className="boxheading boxheadcolor2">
                      My Wallet
                      <IoLogoUsd style={{ float: "right", fontSize: "18px" }} />
                    </h2>
                    <div className="dashinnerbox">
                      <ul className="creditlist">
                        <li>
                          <label>Profile Credit Limit:</label> USD 0.00
                        </li>
                        <li>
                          <label>Wallet Balance:</label> USD 0.00
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <Card.Text style={{ color: "white" }}>
                      Available Credit Limit:{" "}
                      <span className="float-right">USD 0.00</span>
                    </Card.Text>
                  </Card.Footer>
                </Card>
              </Col>
              <Col
                md={12}
                className="resp-mt-20"
                style={{ marginBottom: "5px" }}>
                <Card className="dashboardbox dashboxcolor3">
                  <Card.Body style={{ padding: "0px" }}>
                    <h2 className="boxheading boxheadcolor3">
                      Bookings
                      <FaCalendar
                        style={{ float: "right", fontSize: "18px" }}
                      />
                    </h2>
                    <div className="dashinnerbox">
                      <ul className="creditlist">
                        <li>
                          <label>Confirmed :</label>{" "}
                          {countBookingsWithStatus("Success")}
                        </li>
                        <li>
                          <label>Pending :</label>{" "}
                          {countBookingsWithStatus("Pending")}
                        </li>
                        <li>
                          <label>Cancelled :</label>{" "}
                          {countBookingsWithStatus("Failed")}
                        </li>
                        <li>
                          <label>All Booking :</label>{" "}
                          {hotelBookingsData.length +
                            sightBookingsData.length +
                            packageBookingsData.length +
                            transferBookingsData.length}
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={12} className="resp-mt-20">
                <Card
                  className="dashboardbox dashboxcolor4"
                  style={{ marginBottom: "5px" }}>
                  <Card.Body style={{ padding: "0px" }}>
                    <h2 className="boxheading boxheadcolor4">
                      Booking Activity
                      {/* <IoLogoUsd style={{ float: "right", fontSize: "18px" }} /> */}
                    </h2>
                    <div className="dashinnerbox">
                      <div className="dashinnerbox">
                        <canvas ref={chartRef}></canvas>{" "}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Col>
              <AmazingDealsCarousel />
            </Col>
            <Col>
              <Card className="notifications-container">
                <Card.Header className="notifications-heading">
                  <FaBell className="notification-icon" /> Notifications
                </Card.Header>
                {notifications.map((notification, index) => (
                  <Card key={index} className="notification-card">
                    <Card.Body>
                      <Card.Title>{notification.heading}</Card.Title>
                      <Card.Text>{notification.content}</Card.Text>
                    </Card.Body>
                    {index !== notifications.length - 1 && (
                      <hr className="notification-divider" />
                    )}
                  </Card>
                ))}
              </Card>
            </Col>
          </Col>

          <Col md={6}>
            <Col>
              <Card className="latest-bookings">
                <Card.Header>Latest Hotel Bookings</Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th>Hotel Name</th>
                        <th>Fare</th>
                        <th>Module</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestHotelBookings.map((booking, index) => (
                        <tr key={index}>
                          <td>{booking.id}</td>
                          <td>{booking.bookingDate}</td>
                          <td>{booking.status}</td>
                          <td>{booking.hotelName}</td>
                          <td>{booking.fare}</td>
                          <td>Hotel</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="latest-bookings">
                <Card.Header>Latest Tour Bookings</Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th>Package</th>
                        <th>Price</th>
                        <th>Module</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestTourBookings.map((booking, index) => (
                        <tr key={index}>
                          <td>{booking.id}</td>
                          <td>{booking.bookingDate}</td>
                          <td>{booking.status}</td>
                          <td>{booking.packageName}</td>
                          <td>{booking.price}</td>
                          <td>Tour Package</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="latest-bookings">
                <Card.Header>Latest Sightseeing Bookings</Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th>Info</th>
                        <th>Price</th>
                        <th>Module</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestSightseeingBookings.map((booking, index) => (
                        <tr key={index}>
                          <td>{booking.id}</td>
                          <td>{booking.bookingDate}</td>
                          <td>{booking.status}</td>
                          <td>{booking.packageName}</td>
                          <td>{booking.price}</td>
                          <td>Sightseeing</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="latest-bookings">
                <Card.Header>Latest Transfer Bookings</Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th>Pax</th>
                        <th>Price</th>
                        <th>Module</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestTransferBookings.map((booking, index) => (
                        <tr key={index}>
                          <td>{booking.id}</td>
                          <td>{booking.bookingDate}</td>
                          <td>{booking.status}</td>
                          <td>{booking.pax}</td>
                          <td>{booking.price}</td>
                          <td>Transfer</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>
      <Container
        className="copyright_container"
        style={{ padding: "0px", margin: "0px", width: "100%" }}>
        <Row style={{ padding: "0px", margin: "0px" }}>
          <Col sm={12} style={{ padding: "0px" }}>
            <div
              className="footer-bottom"
              style={{
                textAlign: "center",
                borderTop: "1px solid #e4f1c7",
                marginTop: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: "#f2ffd4",
              }}>
              <p
                style={{
                  color: "#333",
                  marginBottom: "0",
                  fontWeight: "500",
                  fontSize: "14px",
                }}>
                Copyright &copy; 2024 Tripoholidays.com | All Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardHome;
