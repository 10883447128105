import React from "react";
import CustomBanner from "../Pages/CustomBanner";
import { Breadcrumb } from "react-bootstrap";
import CustomBreadcrumb from "../Contact/CustomBreadcrumb";
import CareerPage from "./CareerPage";

const Careers = () => {
  return (
    <>
      <CustomBanner
        title="Career"
        imageUrl={"https://tripoholidays.com/public/images/carrer-bg.jpg"}
      />
      <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "Career" }]}
      />
      <CareerPage/>
    </>
  );
};

export default Careers;
