import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Wallet = () => {
  const [depositType, setDepositType] = useState("Cash");
  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Handle any additional logic here
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const customValidate = (formId) => {
    // Define your custom validation logic here
  };

  const validate = (event) => {
    // Your validation logic here
    console.log("Validating key press...");
  };
  
  const handleAmountKeyUp = (event) => {
    validate(event);
  };
  
  const validate_amount = (value) => {
    // Your amount validation logic here
    console.log("Validating amount:", value);
  };

  const handleDepositTypeChange = (e) => {
    setDepositType(e.target.value);
    if (e.target.value === "Online") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Recharge Request</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Recharge Request</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card className="card-primary">
                  <Card.Header>
                    <h3 className="card-title">Recharge Request</h3>
                  </Card.Header>
                  <Form
                    method="POST"
                    action="https://tripoholidays.com/agent/wallet/store"
                    acceptCharset="UTF-8"
                    name="add-wallet"
                    autoComplete="off"
                    encType="multipart/form-data">
                    <Card.Body>
                      {/* Unconditional fields */}
                      <Form.Group
                        as={Row}
                        controlId="pay_mode"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Deposit Type
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            as="select"
                            data-valid="required"
                            name="pay_mode"
                            value={depositType}
                            onChange={handleDepositTypeChange}>
                            <option value="Cash">Cash</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cheque Deposit">
                              Cheque Deposit
                            </option>
                            <option value="Online">Online</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="amount"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Deposit Amount
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="number"
                            name="amount"
                            autoComplete="off"
                            data-valid="required"
                            onKeyUp={handleAmountKeyUp}
                          />
                        </Col>
                      </Form.Group>

                      {depositType === "Bank Transfer" && (
                        <>
                          <Form.Group
                            as={Row}
                            controlId="bank_transaction_id"
                            className="form-group">
                            <Form.Label column sm={3}>
                              UTR No.
                            </Form.Label>
                            <Col sm={9}>
                              <Form.Control
                                type="text"
                                name="bank_transaction_id"
                                autoComplete="off"
                                data-valid="required"
                                className="form-control"
                                onKeyUp={handleAmountKeyUp}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            controlId="pay_type"
                            className="form-group">
                            <Form.Label column sm={3}>
                              Select payment mode
                            </Form.Label>
                            <Col sm={9}>
                              <Form.Control
                                as="select"
                                data-valid="required"
                                className="form-control"
                                name="pay_type">
                                <option value="NEFT">NEFT</option>
                                <option value="RTGS">RTGS</option>
                                <option value="IMPS">IMPS</option>
                              </Form.Control>
                            </Col>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group
                        as={Row}
                        controlId="pay_date"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Deposit Date
                        </Form.Label>
                        <Col sm={9}>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="From Date"
                            maxDate={new Date()}
                            popperPlacement="bottom-start"
                          />
                        </Col>
                      </Form.Group>

                      {depositType === "Cheque Deposit" && (
                        <>
                          <Form.Group
                            as={Row}
                            controlId="cheque_bank_name"
                            className="form-group">
                            <Form.Label column sm={3}>
                              Cheque Issue Bank
                            </Form.Label>
                            <Col sm={9}>
                              <Form.Control
                                type="text"
                                name="cheque_bank_name"
                                autoComplete="off"
                                data-valid="required"
                                className="form-control"
                                onKeyUp={handleAmountKeyUp}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            controlId="cheque_no"
                            className="form-group">
                            <Form.Label column sm={3}>
                              Cheque/DD Number
                            </Form.Label>
                            <Col sm={9}>
                              <Form.Control
                                type="text"
                                name="cheque_no"
                                autoComplete="off"
                                data-valid="required"
                                className="form-control"
                                onKeyUp={handleAmountKeyUp}
                              />
                            </Col>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group
                        as={Row}
                        controlId="bank_name"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Bank
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            as="select"
                            data-valid="required"
                            className="fill_account_num"
                            name="bank_name"
                            // onChange="get_accountnum($(this).val());"
                            >
                            <option value="">---Select Bank---</option>
                            <option value="SBI">SBI</option>
                            <option value="AXIS">AXIS</option>
                            <option value="HDFC">HDFC</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="account_number"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Account Number
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            id="accountnumber"
                            name="account_number"
                            autoComplete="off"
                            data-valid="required"
                            readOnly=""
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="remarks"
                        className="form-group">
                        <Form.Label column sm={3}>
                          Remarks
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            as="textarea"
                            className="form-control"
                            data-valid="required"
                            name="remarks"
                          />
                        </Col>
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col sm={12}>
                          <div className="form-group float-right">
                            <Button
                              className="btn btn-theme"
                              onClick={() => customValidate("add-wallet")}
                              type="button">
                              <i className="fa fa-save"></i> Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Modal for Online Deposit */}
        <Modal
          show={showModal}
          onHide={handleModalClose}
          backdrop="static"
          size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Online TopUp</Modal.Title>
          </Modal.Header>
          <form
            name="frmTransaction"
            method="POST"
            action="https://tripoholidays.com/agent/sabpaisa"
            id="frmTransaction"
            className="has-validation-callback payment_gateway_form">
            <Modal.Body>
              <div className="pay_gate_note">
                <p>
                  <b>Note:</b> Recommended Payment Gateway Sabpaisa.
                </p>
              </div>
              <div className="row payment_gateways_modal">
                <div className="col-md-3" style={{ display: "none" }}>
                  <div className="form-group">
                    <h5>Payment Gateways</h5>
                    <ul style={{ listStyle: "none", paddingLeft: "5px" }}>
                      <li>
                        <label htmlFor="">
                          <input
                            data-id="0"
                            checked=""
                            name="payment_method"
                            className="selectpaymentgateway"
                            type="radio"
                            value="Sabpaisa"
                          />{" "}
                          sabpaisa
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <table className="table table-bordered table-hover text-nowrap payment_method">
                      <thead>
                        <tr>
                          <th>Payment Method</th>
                          <th style={{ width: "80px" }}>Surcharge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="creditcard">
                              <input
                                data-atype=""
                                data-charges="0"
                                checked=""
                                id="creditcard"
                                name="payment_type"
                                type="radio"
                                className="payment_type"
                                value="Credit Card"
                              />{" "}
                              <i className="fa-solid fa-credit-card"></i>{" "}
                              Sabpaisa
                            </label>
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className="creditcardamount">
                            0
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Deposit Amount</label>
                    <input
                      id="price"
                      type="text"
                      className="form-control onlynumber"
                      placeholder=""
                      name="totalAmount"
                      onKeyPress={(event) => handleAmountKeyUp(event)}
                      onKeyUp={(event) => validate_amount(event.target.value)}
                      onChange={(event) => validate_amount(event.target.value)}
                    />
                  </div>
                  <div className="form-group" style={{ marginBottom: "29px" }}>
                    <p>
                      <b>Total:</b>{" "}
                      <span
                        className="totl_amount"
                        style={{
                          fontSize: "18px",
                          lineHeight: "21px",
                          fontWeight: "bold",
                        }}></span>
                    </p>
                  </div>
                  <input type="hidden" name="amount" id="net_pay" />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                id="payButton"
                name="submitted"
                className="btn btn-success continue">
                <span
                  className="fa fa-hand-point-right"
                  aria-hidden="true"></span>{" "}
                Continue
              </button>{" "}
              &nbsp;
              <Link
                // to="#"
                className="btn btn-danger"
                onClick={handleModalClose}>
                <span className="fa fa-times-circle" aria-hidden="true"></span>{" "}
                Cancel
              </Link>
            </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </div>
  );
};

export default Wallet;
