import React from "react";

import FAQContent from "./FAQContent";
import CustomBanner from "../Pages/CustomBanner";
import CustomBreadcrumb from "../Contact/CustomBreadcrumb";

const FAQ = () => {
  return (
    <div>
      <CustomBanner title="FAQ" />
      <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "FAQ" }]}
      />
      <FAQContent />
    </div>
  );
};

export default FAQ;
