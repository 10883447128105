import React from "react";
import { Container, Row, Col } from "react-bootstrap";


const TNCContent = () => {
  return (
    <Container>
      <Row className="inner_page_content">
        <Col md={12} style={{ paddingLeft: "15px", paddingRight: "15px", overflowWrap: "break-word" }}>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                We,&nbsp;
                <span style={{ fontWeight: "bolder" }}>Tripoholidays.com</span>
                &nbsp;would ask that you take the time to read these Terms and
                Conditions carefully. They explain a number of things including,
                for example, the rules covering your use of this
                portal/site/mobile application as well as other information
                regarding your rights. By using&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                , you'll be confirming that you have read, understood and agree
                to these Terms and Conditions. These Terms and Conditions may
                change from time to time and, if they do,the up-to-date version
                will always be available on&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>.</span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>LEGAL CONTRACT</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                When you click to make a booking with us legal obligations arise
                and your right to refund of monies charged to your Top-up,
                Credit Module or Credit card or paid in any other way agreed by
                us, are limited by our terms and policies and by the conditions
                of each particular supplier,&nbsp;
                <span style={{ backgroundColor: "#a1c849" }}>
                  bookings made by Company credits are subject to cancellations
                  if agent fails to pay the company dues as committed,
                  tripoholidays.com holds all the legal rights to cancel the
                  bookings of agent who fails to pay Company credits on time
                </span>
                . You must not make any booking through the
                Portal/Website/Application unless you understand and agree to
                all our terms and policies. If you do not accept the terms
                stated here, do not use this Portal/Website/Application and our
                services. Once payment is made for the booking, it is deemed
                that you have read and understood the terms and conditions for
                the booking. If you have any queries please contact us before
                making any booking. Please revisit this area of the
                Portal/Website/Application from time to time as we may change
                these terms and conditions occasionally and your continued use
                of the Portal/Website/Application will constitute your
                acceptance of the amended terms and conditions.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>AGENCY</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Products and services are sold by&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;as an agent for airline and wholesale companies and other
                suppliers providing services, including accommodation and
                transportation services.&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;is not a provider of such services and has no
                responsibility for services provided or not provided by any
                party. We make no warranty or representation regarding the
                standard or availability of any service or product to be
                supplied nor for the availability of any promotions, airfares or
                specific holiday products. All travel documents are issued
                subject to the tariffs and terms and conditions of sale of
                suppliers and these terms. They are issued by us as agent only.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                You agree to defend and indemnify&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;Affiliates, and their respective suppliers and any of
                their officers, directors, employees and agents from and against
                any claims, causes of action, demands, recoveries, losses,
                damages, fines, penalties or other costs or expenses of any kind
                or nature including but not limited to reasonable legal and
                accounting fees, brought by third parties as a result of:
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                (a) Your breach of this Agreement or the documents referenced
                herein; or
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                (b) Your violation of any law or the rights of a third party
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;will not be held responsible and I/we release&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;partners, officers and employees from any claims and
                damages arising out in known and unknown circumstances.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>FLIGHTS</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span
            style={{
              fontFamily: "Calibri, sans-serif",
              fontWeight: "bolder",
              fontSize: "1rem",
            }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  &nbsp;Flight Booking policy:
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The total price displayed includes all applicable government
                  taxes.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Bookings need to complete with proper name and other details
                  as per photo ID/ Passport accepted by airline.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  You are required to pay the entire amount prior to the
                  confirmation of your booking.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  To avail of infant fares, an infant must be under 24 months
                  throughout the entire itinerary you are booking. This includes
                  both onward and return journeys. If the infant is 24 months or
                  above on the return journey, you'll need to make a separate
                  booking using a child fare.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Infants must be accompanied by an adult at least 18 years of
                  age.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Fares &amp; Seats are dynamic and are subject to availability
                  till the tickets are confirmed.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  All Tickets/Bookings issued shall additionally be governed
                  under the terms and conditions as laid out by the respective
                  Airlines / Supplier.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  All the travel documents such as VISA and PASSPORT should be
                  checked before ticket issuance. In case, if traveller fails to
                  fulfil airline terms &amp; condition.&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;shall not be liable for any discrepancy or
                  refund/ADM/Penalty/If PNR is cancelled by airline. This
                  charges will apply to the travel agent.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Check in:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  As per the airline rules, the standard check-in time begins 2
                  hours before departure for domestic flights.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  For International flights, the check-in time is 3 hours before
                  departure.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The passenger needs to check-in at least 3 hrs prior departure
                  for Air India and Air India Express domestic flights, else
                  will be considered as&nbsp;
                  <span style={{ fontWeight: "bolder" }}>no show</span>.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Infants must have valid proof-of-age documents showing that
                  the infant is under two years old.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Amendments:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;can assist you with amendments to most bookings. In some
                  cases, though, you'll need to contact the airline directly.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Every booking made on&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;app is subject to amendment charges levied by the
                  airline, which may vary by flight and booking class.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The charges levied by Airlines are Non-Refundable in case of
                  cancellation post amendment.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  If you amend your booking, you will be charged the difference
                  in fare, if any, applicable when the amendment is made and the
                  rebooking charges as applicable will be collected. The charges
                  levied by Airlines are Non-Refundable in case of cancellation
                  post amendment.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  ONLINE REISSUANCE&nbsp;can be made by signing in to your&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  :
                  <br />
                  Domestic reissuance : 06 Hours prior to departures time (for
                  some Airlines regulations may vary).
                  <br />
                  International reissuance : 24 Hours prior to departures time
                  (for some Airlines regulations may vary)
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  OFFLINE REISSUANCE:-&nbsp;We also accept reissuance requests
                  through phone and Email(atleast6 hrs prior to airline
                  reissuance rule).
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  We'll collect these charges from you when we make the changes
                  to your travel plans. We'll also collect the difference in
                  fare, if any, applicable when the amendment is made.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Depending on the airline's policy, some booked fares may not
                  be amended.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  All bookings made through us, can also be amended by calling
                  up the Airline Call Centre directly.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Cancellation Policy:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Every booking made on&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;app is subject to cancellation charges levied by the
                  airline, which may vary by flight and booking class.Some
                  booked fares may be non-refundable per the specific airline's
                  policy.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Convenience fee is non-refundable in case of ticket
                  cancellation by the user, or flight cancellation by the
                  airline.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  ONLINECANCELLATION&nbsp;can be made online by signing in
                  toyour&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;app :-
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Domestic cancellation : 06 Hours prior to departures time (for
                some Airlines regulations may vary).
                <br />
                International cancellation : 24 Hours prior to departures time
                (for some Airlines regulations may vary).
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  OFFLINE CANCELLATION :-&nbsp;We also accept cancellation
                  requests through phone and Email (atleast 6hrs prior to
                  airline cancellation rule).
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  For e-tickets, if your cancellation request is less than 3
                  hours before departure, you'll need to contact airline
                  directly to get your reservation cancelled
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  If you have done a 'Web/Tele Check-in' with the airline, get
                  in touch with the airline for cancellation.&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;will not be able to process the refund for the same and
                  send us a mail to claim refund against cancelled ticket.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>Refunds</span>
                </span>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>:</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  It is mandatory to contact&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;for all refunds, as the airline will not be able to
                  refund your tickets booked from&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;app.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  All cancellations made directly with the Airline need to be
                  intimated to&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;by Email in order to initiate the refunds process.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Processing times for cancellation and refund requests may
                  vary.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;shall refund your cancellation only after the respective
                  Airline/Service Provider processes to us your eligible refund.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  For any tickets issued on Credit module need to contact&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;representative.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>No Show:</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  No Show refunds can be claimed only after 24hrs from arrival
                  time.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  All No Show refunds need to be claim within 15 days from the
                  arrival time of the flight.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>HOTELS</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  * is ONLY a Hotel Booking agent. It does not operate Hotel
                  services of its own. In order to provide a comprehensive
                  choice of Hotel operators, Check-in &amp; Check-out times and
                  prices to customers.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The primary guest must be at least 18 years old to be able to
                  check into this hotel.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Standard check-in time is 12:00 PM and check-out time is 12:00
                  PM (Checkin&amp; Checkout time will vary for individual Hotel
                  please cross check before booking any such Hotels especially
                  international). Early check-in or late check-out is subject to
                  availability and the hotel might charge you extra for it.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Rooms are subject to availability for hotel bookings made
                  within 72 hours.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The total price is inclusive of all applicable taxes. However,
                  it does not include additional personal expenses like
                  telephone charges, meals that aren't a part of your meal plan,
                  any hotel services you use (like laundry and room service) or
                  tips. The hotel will charge you directly for these when you're
                  checking out.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Hotels may require you to furnish a valid photo-id and address
                  proof at the time of check-in. Documents that may be accepted
                  as valid proofs include driving license, passport, voter's id
                  card, national identity card. Please note that that the
                  accepted document may vary based on the country where the
                  hotel is. In case of any doubt please call the hotel directly
                  before your check-in to get the exact details. PAN cards are
                  not accepted as valid ID cards.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The hotel reserves the right of admission. Accommodation can
                  be denied to guests posing as a couple if suitable proof of
                  identification is not presented at check-in. Agent will not be
                  responsible for any check-in denied by the hotel due to the
                  aforesaid reason.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Hotels may charge a mandatory meal surcharge on festive
                  periods e.g. Christmas, New Year's Eve etc... All additional
                  charges (including mandatory meal surcharges) need to be
                  cleared directly at the hotel.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Taxes are paid by Agent to its vendors. Service fees are
                  retained by Agent for servicing the travel reservation.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Cancellation Policy:-</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Only those cancellation requests which are made either online
                  or on the phone or emailed to our customer support team shall
                  be entertained. Agent shall not be liable to entertain any
                  cancellation requests made through any other medium.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Cancellation charges may vary by time and date of cancellation
                  as per hotel without prior notice.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;charges a separate service fee of Rs 250 for all online
                  &amp; offline cancellations.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  If you don't show up at the hotel, you'll still be charged the
                  entire amount.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  Modifications &amp; Refunds:-
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Don't call the hotel directly for reservation changes or
                  cancellations. The hotel's agents can't make changes to or
                  process refunds for these specially-negotiated rates.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  We take at least 14 working days to process refunds for
                  bookings cancelled online.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  Specific Rules for Non A/c Room - Bed &amp; Breakfast
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  These booking are non-refundable.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>BUS</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span
            style={{
              fontFamily: "Calibri, sans-serif",
              fontWeight: "bolder",
              fontSize: "1rem",
            }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  &nbsp;bus booking policy:
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The total price displayed includes all applicable government
                  taxes. It is the amount you will have to pay to complete the
                  transaction.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  On successful payment your ticket will be issued by the
                  partner site.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Fares &amp; Seats are dynamic and are subject to availability
                  till the tickets are confirmed.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Our partner site may have additional terms and condition for
                  using their service, and you will have to accept them for
                  completing your purchase.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  The booking details you enter as part of the booking process
                  on&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;(Portal/Site/Application), such as names and details of
                  passengers, email addresses, phone numbers, etc. will be sent
                  to our partner site and you will see them pre-populated on the
                  landing page.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Amendments:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  You can find contact information for amendments on your ticket
                  and the booking confirmation email you would have received on
                  purchase.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;cannot assist you with amendments to your bus bookings.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Cancellation:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  For all cancellations you should contact&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;or operator.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  You can find contact information of operators printed on your
                  ticket and the booking confirmation email you will receive
                  after purchase.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;does not charge any fee for cancellations done with our
                  partner site.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  For charges applicable on cancellation on partner, you are
                  requested to refer to the cancellation policy printed on your
                  ticket, or contact them at the numbers / email addresses
                  provided on your ticket.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>Refunds:</span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  Any refunds due to you because of amendments or cancellation
                  will be given to you, irrespective of partner processes to us
                  your eligible refund against purchase.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  If you have any questions about your refunds, please contact
                  concern bus operator for your purchase.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  &nbsp;<span style={{ fontWeight: "bolder" }}>INSURANCE</span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;is only collecting the premium on behalf of insurance
                  company and issuance of policy is at sole discretion of the
                  insurance company and payment of premium on this site does not
                  means that your policy has been issued.
                </span>
              </span>
            </span>
          </li>
          <li style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  In case of your proposal being rejected by the insurance
                  company for any reason whatsoever may be, then&nbsp;
                  <span style={{ fontWeight: "bolder" }}>
                    Tripoholidays.com
                  </span>
                  &nbsp;will not be liable for any consequential loss or damage.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  IF ANY AIRLINE/INSURANCE/HOTEL/BUS/SUPPLIERS GIVEN
                  OPERATION/SERVICE/REFUND ARE BEEN
                  DELAYED/CANCELLED/WITHDRAWN,&nbsp;
                </span>
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ fontSize: "12pt" }}>
                  &nbsp;SHALL NOT BE LIABLE.
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp;General Provisions
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                You may travel to certain destinations which involve greater
                risks than others, entirely at your risk as to cost and
                consequences.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                You agree to abide by the terms and conditions of purchase
                imposed by any supplier with whom you elect to deal, including,
                but not limited to, payment of all amounts when due and with the
                supplier’s rules and restrictions regarding availability and use
                of fares, products, or services. You acknowledge that some
                third–party providers offering certain services and/or
                activities may require you to sign their liability waiver prior
                to participating in the service and/or activity they offer. You
                understand that any violation of any such supplier’s rules and
                restrictions may result in cancellation of your reservation(s),
                in your being denied access to the applicable travel product or
                services, in your forfeiting any monies paid for such
                reservation(s), and/or in&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;debiting your account for any costs we incur as a result
                of such violation.
                <br />
                <br />
                No waiver by&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;of any provision of these Terms and Conditions shall be
                binding except as set forth in writing and signed by its duly
                authorized representative.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          &nbsp;
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ backgroundColor: "#a1c849" }}>
              <span style={{ fontFamily: "Calibri, sans-serif" }}>
                <span style={{ fontWeight: "bolder" }}>
                  <span style={{ fontSize: "12pt" }}>
                    &nbsp;Terms and Conditions of Use
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <span
            style={{
              fontFamily: "Calibri, sans-serif",
              fontWeight: "bolder",
              fontSize: "1rem",
            }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;may add to, change or remove any part of these Terms and
                Conditions of Use at any time, without notice. Any changes to
                these Terms and Conditions of Use or any terms posted on this
                Portal/Site/Application apply as soon as they are posted. By
                continuing to use this Portal/Site/Application after any changes
                are posted, you are indicating your acceptance of those changes.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;may add, change, discontinue, remove or suspend any other
                Content posted on this Portal/Site/Application, including
                features and specifications of products described or depicted on
                the Portal/Site/Application, temporarily or permanently, at any
                time, without notice and without liability.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;reserves the right to undertake all necessary steps to
                ensure that the security, safety and integrity of&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;as well as its client’s interests are and remain,
                well–protected.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>Towards this end,&nbsp;</span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;may take various steps to verify and confirm the
                authenticity, enforceability and validity of orders placed by
                you.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>If&nbsp;</span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                , in its sole and exclusive discretion, concludes that the said
                orders are not or do not reasonably appear to be, authentic,
                enforceable or valid, then&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;may cancel the said orders at any time up to 1 hours
                before the scheduled time of departure of the relevant flight or
                1 hours before the expected date of visit to any property booked
                through&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;using Portal/Website/Application.
                <br />
                <br />
                These terms and conditions are governed by and shall be
                construed in accordance with the laws of the Republic of India
                and any dispute shall exclusively be subject to the jurisdiction
                of the appropriate Courts situated at Delhi, India.
              </span>
            </span>
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            textAlign: "justify",
          }}>
          <br />
          <br />
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Your agent ID &amp; password for&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;do not share it with anyone.&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{ fontWeight: "bolder", fontFamily: "Calibri, sans-serif" }}>
            Tripoholidays.com
          </span>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Calibri, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                &nbsp;will not be responsible for any misuse of your log in
                credentials.
              </span>
            </span>
          </span>
        </p>{" "}
        </Col>
      </Row>
    </Container>
  );
};

export default TNCContent;
