import React from "react";
import "./BecomeAgentSection.css";
import { Link } from "react-router-dom";

function BecomeAgentSection() {
  return (
    <section
      id="becomeAgent"
      data-section="true"
      data-theme="black"
      className="Section_root___gfoL Section_root_black__EKtgb SectionWithRays_root__hU_KI"
    >
      <div className="Container_root__n_Nmn">
        <div className="SectionWithRays_content__kReGf">
          <header className="SectionWithRays_header__ZI3zY">
            <div className="SectionWithRays_headerOverflow__bT_85">
              <p className="Typography_root__WDpW6 Typography_root_variant_h2__r93x3 SectionWithRays_subtitle__EEq_P">
                Become a super travel agent
              </p>
              <h2 className="Typography_root__WDpW6 Typography_root_variant_h1__bpLmV SectionWithRays_title__5Jyzj">
                You're one step away. Get access to prices and bookings.
              </h2>
              <p className="Typography_root__WDpW6 Typography_root_variant_p1__rxFrh SectionWithRays_description__l2k3W SectionWithRays_description_isLast__heWzK">
                Quick, one-step registration to get access to the world. No
                waiting time. No fees. Fill in the form and start booking.
              </p>
            </div>
            <Link
              to="/agent-registration"
              target="_blank"
              className="BaseButton_root__2bBDd BaseButton_root_size_m__7z01Z BaseButton_root_variant_primary___xgRg SectionWithRays_button__kpWMA"
            >
              Join us
            </Link>
          </header>
        </div>
      </div>
      <div className="SectionWithRays_photo__h0Xh5">
        <span
          style={{
            boxSizing: "border-box",
            display: "inline-block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: 0,
            margin: 0,
            padding: 0,
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <span
            style={{
              boxSizing: "border-box",
              display: "block",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: 0,
              margin: 0,
              padding: 0,
              maxWidth: "100%",
            }}
          >
            <img
              style={{
                display: "block",
                maxWidth: "100%",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
              }}
              alt=""
              aria-hidden="true"
              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27710%27%20height=%27720%27/%3e"
            />
          </span>
          <img
            alt=""
            src="/Images/person.png"
            decoding="async"
            data-nimg="intrinsic"
            style={{
              position: "absolute",
              top: 0,
              left: -45,
              bottom: 0,
              right: 0,
              boxSizing: "border-box",
              padding: 0,
              border: "none",
              margin: "auto",
              display: "block",
              width: 0,
              height: 0,
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
            }}
          />
          <noscript>
            <img
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="intrinsic"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
              }}
              src="https://f.worldota.net/partner/frontend/master/b543755/_next/static/media/photo.ca3163a1.webp"
            />
          </noscript>
        </span>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="500"
        height="394"
        fill="none"
        viewBox="0 0 500 394"
        className="SectionWithRays_rays__4ZZVh SectionWithRays_rays_isMobile__QdmHK"
      >
        <path
          stroke="#a1c849"
          strokeWidth="2.498"
          d="M232.833 548.812 559-83.624M232.833 548.812l290.97-676.176m-290.97 676.176 214.218-747.924M232.833 548.812 363.79-248.134M232.833 548.812l43.717-821.754m-43.717 821.754-44.851-821.593m44.851 821.593L100.776-247.656m132.057 796.468L17.583-198.331m215.25 747.143 253.559-714.77m-253.559 714.77L406.08-226.574M232.833 548.812l87.671-812.442m-87.671 812.442L232.264-276m.569 824.812-88.792-812.121m88.792 812.121L58.517-225.942m174.316 774.754L-21.712-165.034M232.903 549-59-126.117"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 896 676"
        className="SectionWithRays_rays__4ZZVh SectionWithRays_rays_isDesktop__Pf01x"
      >
        <path
          stroke="#a1c849"
          strokeWidth="2.498"
          d="M532.777 675.367 1126-34.495M532.777 675.367 1061.99-83.59M532.777 675.367l389.614-839.488M532.777 675.367l238.181-894.512M532.777 675.367l79.512-922.357m-79.512 922.357-81.574-922.176m81.574 922.176L292.596-218.608m240.181 893.975-391.49-838.612m391.49 838.612 461.166-802.275M532.777 675.367l315.097-870.312M532.777 675.367 692.23-236.537M532.777 675.367l-1.035-925.789m1.035 925.789L371.285-236.177m161.492 911.544L215.735-194.235m317.042 869.602L69.819-125.871m463.085 801.449L2-82.189"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1498"
        height="864"
        fill="none"
        viewBox="0 0 1498 864"
        className="SectionWithRays_rays__4ZZVh SectionWithRays_rays_isWideDesktop__ftx5m"
      >
        <path
          stroke="#a1c849"
          strokeWidth="2"
          d="M707.443 863.719 1497-80.719M707.443 863.719 1411.8-146.037M707.443 863.719 1226-253.18M707.443 863.719 1024.45-326.387M707.443 863.719 813.271-363.433M707.443 863.719 598.872-363.193M707.443 863.719 387.772-325.673M707.443 863.719 186.386-252.015M707.443 863.719l613.797-1067.39M707.443 863.719 1126.82-294.19M707.443 863.719 919.67-349.527M707.443 863.719 706.066-368m1.377 1231.719L492.504-349.048M707.443 863.719 285.473-293.247m421.97 1156.966L91.263-202.291M707.613 864 1-144.174"
        ></path>
      </svg>
    </section>
  );
}

export default BecomeAgentSection;
