import React from 'react';
import HotelCancelTable from './HotelCancelTable';


const HotelCancelPage = () => {
  // Sample data for bookings
  const hotelcancelData = [
    {
      cancelId: 10,
      bookId: 165,
      requestDate: "05:07 PM, 26 Dec 2023",
      hotelName: "Mahaveer BnB",
      checkIn: "27 Dec 2023",
      checkOut: "28 Dec 2023",
      status: "Pending",
      amount: 3110,
      detailLink: "https://tripoholidays.com/agent/bookings/hotel/detail/IixDYGAKYAo="
    },
    {
      cancelId: 18,
      bookId: 166,
      requestDate: "07:35 PM, 10 Oct 2023",
      hotelName: "Hotel Gems By OYO Rooms",
      checkIn: "11 Oct 2023",
      checkOut: "12 Oct 2023",
      status: "Pending",
      amount: 1478,
      detailLink: "https://tripoholidays.com/agent/bookings/hotel/detail/IiwzQGAKYAo="
    }
  ];

  return (
    <div>
      <HotelCancelTable heading="Hotel Cancellation Lists" data={hotelcancelData} />
    </div>
  );
};

export default HotelCancelPage;
