import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLocationDot, FaAngleRight } from 'react-icons/fa6'; // Import required icons
import './AboutSection.css'; // Import your custom CSS for styling

const AboutSection = () => {
  return (
    <div className="inner_box">
      {/* Company Profile */}
      <section className="py-50">
        <Container>
          <Row className="align-items-center-lg d-flex-lg">
            <Col md={6} className="about_content">
              <h4>Tripoholidays: Company Profile</h4>
              <p>
                At Tripoholidays, We work passionately towards offering memorable and immersive travel experiences for international travelers to Russia, Turkey, Azerbaijan, Europe, and Egypt. By following exceptional standards, we offer the best team of professionals to assist you throughout every step of your trip. Our goal is to deliver the most reliable and spectacular travel experience to each of our clients.
              </p>
            </Col>
            <Col md={6}>
              <div className="about_img">
                <img src="https://tripoholidays.com/public/images/company-profile.jpg" className="img-responsive" alt="Company Profile" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Us */}
      <section className="py-50 bg-light">
        <Container>
          <Row className="align-items-center-lg d-flex-lg">
            <Col md={6}>
              <div className="about_img">
                <img src="https://tripoholidays.com/public/images/about-company.jpg" className="img-responsive" alt="About Us" />
              </div>
            </Col>
            <Col md={6} className="about_content">
              <h4>About Us</h4>
              <p>
                Tripoholidays is a leading destination management company that offers world-class travel management services throughout India, Russia, Turkey, and Egypt. Since 2017, we have been planning and delivering innovative itineraries based on each of our clients according to their choices and likings.
              </p>
              <p>
                We have highly trained professionals from India and overseas to assist our traveling clients with their trips. We deal in some of the most visited countries such as Russia, Egypt, Turkey, and Europe. Tripoholidays offer customized Tour Packages, Airport Transfers, to unique Tour Activities, and we even help throughout VISA, Hotels, Flights, and more.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Network & Services */}
      <section className="py-50">
        <Container>
          <Row className="align-items-center-lg d-flex-lg">
            <Col md={6}>
              <div className="about_content">
                <h4>Our Network/ Representation Offices:</h4>
                <p><FaLocationDot className="location-icon"/>&nbsp;Offices in - India, Russia, Turkey, and Egypt</p>
              </div>
              <div className="about_content mt25">
                <h4>Services We Offer:</h4>
                <ul className="listsr">
                  <li><FaAngleRight className="mr-5" />Tour packages</li>
                  <li><FaAngleRight className="mr-5" />Airport Transfers</li>
                  <li><FaAngleRight className="mr-5" />Tour Activities</li>
                  <li><FaAngleRight className="mr-5" />Hotels</li>
                  <li><FaAngleRight className="mr-5" />VISA</li>
                  <li><FaAngleRight className="mr-5" />Flights and more.</li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="about_img">
                <img src="https://tripoholidays.com/public/images/our-services.jpg" className="img-responsive" alt="Services" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default AboutSection;
