import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const BookingGraph = ({
  hotelData,
  tourData,
  sightseeingData,
  transferData,
}) => {
  const canvasRef = useRef(null);
  let chartInstance = null;

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              label: "Hotel Bookings",
              data: hotelData,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
            {
              label: "Tour Bookings",
              data: tourData,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
            {
              label: "Sightseeing Bookings",
              data: sightseeingData,
              backgroundColor: "rgba(255, 206, 86, 0.2)",
              borderColor: "rgba(255, 206, 86, 1)",
              borderWidth: 1,
            },
            {
              label: "Transfer Bookings",
              data: transferData,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },

        options: {
          maintainAspectRatio: false,
          responsive: true,
          // legend: {
          //   display: true,
          //   position: 'bottom',
          //   labels: {
          //     fontColor: 'rgba(0, 0, 0, 0.7)',
          //     fontSize: 12,
          //     boxWidth: 12,
          //   },
          // },
          // scales: {
          //   xAxes: [{
          //     gridLines: {
          //       display: false,
          //     },
          //     ticks: {
          //       fontColor: 'rgba(0, 0, 0, 0.7)',
          //       fontSize: 12,
          //     },
          //   }],
          //   yAxes: [{
          //     gridLines: {
          //       color: 'rgba(0, 0, 0, 0.1)',
          //       borderDash: [3, 3],
          //     },
          //     ticks: {
          //       beginAtZero: true,
          //       fontColor: 'rgba(0, 0, 0, 0.7)',
          //       fontSize: 12,
          //     },
          //   }],
          // },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [hotelData, tourData, sightseeingData, transferData]);

  return <canvas ref={canvasRef} id="bookingGraph" />;
};

export default BookingGraph;
