// packageMarkupData.js
import { useState } from 'react';

const initialData = [
  {
    id: 175,
    amountType: "percentage",
    amount: "100",
    date: "13 02 2023 20:54 PM",
    editLink: "/agent/packagemarkup/edit/175",
  },
  {
    id: 176,
    amountType: "percentage",
    amount: "200",
    date: "14 02 2023 20:54 PM",
    editLink: "/agent/packagemarkup/edit/176",
  },
];

export const usePackageMarkupData = () => {
  const [packageMarkupData, setPackageMarkupData] = useState(initialData);

  const addPackageMarkup = (newMarkupData) => {
    const updatedData = packageMarkupData.push(newMarkupData);
    setPackageMarkupData(updatedData);
  };

  const deletePackageMarkup = (id) => {
    const updatedData = packageMarkupData.filter(item => item.id !== id);
    setPackageMarkupData(updatedData);
  };

  const updatePackageMarkup = (updatedMarkupData) => {
    setPackageMarkupData(updatedMarkupData);
  };

  return { packageMarkupData, addPackageMarkup, deletePackageMarkup, updatePackageMarkup };
};
