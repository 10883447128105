import React from 'react';
import SightAmendmentTable from './SightAmendmentTable';


const SightAmendmentPage = () => {
  // Sample data for bookings
  const sightamendmentData = [
    {
      bookId: 165,
      amdId: 10,
      amdType: "Type A", // Replace with the actual amendment type
      bookingType: "Hotel", // Replace with the actual booking type
      entryDate: "05:07 PM, 26 Dec 2023", // Change to match the table format
      status: "Pending",
    },
    {
      bookId: 166,
      amdId: 18,
      amdType: "Type B", // Replace with the actual amendment type
      bookingType: "Hotel", // Replace with the actual booking type
      entryDate: "07:35 PM, 10 Oct 2023", // Change to match the table format
      status: "Pending",
    },
  ];
  return (
    <div>
      <SightAmendmentTable heading="Amendments Lists" data={sightamendmentData} />
    </div>
  );
};

export default SightAmendmentPage;
