// import React from "react";
// import { Container, Card } from "react-bootstrap";

// const PackageDescription = ({ packageName }) => {
//   return (
//     <section id="description" className="common_section">
//       <Container>
//         <h2>Description</h2>
//         <Card style={{ border: "none" }}>
//           <Card.Body>
//             {packageName.descriptions.map((item, index) => (
//               <p
//                 key={index}
//                 className="MsoNormal"
//                 style={{
//                   margin: "0cm 0cm 8pt",
//                   padding: "0px",
//                   textAlign: "justify",
//                   fontSize: "11pt",
//                   lineHeight: "15.6933px",
//                   color: "rgb(34, 34, 34)",
//                   fontFamily: "Calibri, sans-serif",
//                 }}>
//                 <span
//                   style={{
//                     fontWeight: 700,
//                     display: "inline-block",
//                     minWidth: "100px", // Adjust the width as needed
//                   }}>
//                   {item.label}
//                 </span>
//                 : {item.value}
//                 {item.additional && (
//                   <span style={{ fontSize: "16pt", lineHeight: "22.8267px" }}>
//                     {item.additional}
//                   </span>
//                 )}
//               </p>
//             ))}
//           </Card.Body>
//         </Card>
//       </Container>
//     </section>
//   );
// };

// export default PackageDescription;

import React from "react";
import { Container, Card } from "react-bootstrap";

const PackageDescription = ({ packageName }) => {
  // Extracting descriptions from package_overview
  // const descriptions = packageName.package_overview
  //   .split(":")
  //   .map((desc) => desc.trim());

  // Convert HTML entities to actual HTML elements
  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <section id="description" className="common_section">
      <Container>
        <h2>Description</h2>
        <Card style={{ border: "none" }}>
          <Card.Body>
            {/* {descriptions.map((item, index) => ( */}
            <p
              // key={index}
              className="MsoNormal"
              dangerouslySetInnerHTML={renderHTML(packageName.package_overview)}>
              {/* {packageName.package_overview} */}
            </p>
            {/* ))} */}
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export default PackageDescription;
