import React from "react";
import CustomBreadcrumb from "./CustomBreadcrumb";
import ContactBoxes from "./ContactBoxes";
import ContactDetail from "./ContactDetails";

const Contact = () => {
  return (
    <>
      <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "Contact Us" }]}
      />

      <ContactBoxes />
      <ContactDetail />
    </>
  );
};

export default Contact;
