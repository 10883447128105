import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import {
  FaBars,
  FaUserCircle,
  FaSuitcaseRolling,
  FaStar,
  FaBed,
  FaPowerOff,
  FaUser,
  FaEnvelope,
  FaAngleDown,
  FaBinoculars,
  FaRegCircle,
  FaUmbrellaBeach,
  FaCreditCard,
  FaFileAlt,
  FaCog,
  FaSignOutAlt,
  FaWallet,
  FaAngleUp,
} from "react-icons/fa";
import { FaChartBar } from "react-icons/fa6";
import { ImUserPlus } from "react-icons/im";
import { MdHeadset, MdDirectionsCar, MdWhatsapp } from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";
import "./Sidebar.css";

import "./DashboardNavbar.css";
import { Link } from "react-router-dom";
// import Sidebar from "./Sidebar";

function DashboardNavbar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [mainHeaderHeight, setMainHeaderHeight] = useState(0);
  const mainHeaderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (mainHeaderRef.current) {
        setMainHeaderHeight(mainHeaderRef.current.offsetHeight);
      }
    };

    // Initial calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const closeSidebar = (e) => {
      if (
        showSidebar &&
        !e.target.closest(".main-sidebar") &&
        !e.target.closest(".nav_toggle")
      ) {
        setShowSidebar(false);
      }
    };

    document.body.addEventListener("click", closeSidebar);

    return () => {
      document.body.removeEventListener("click", closeSidebar);
    };
  }, [showSidebar]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [showHotelSubMenu, setShowHotelSubMenu] = useState(false);
  const [showSightseeingSubMenu, setShowSightseeingSubMenu] = useState(false);
  const [showTourPackagesSubMenu, setShowTourPackagesSubMenu] = useState(false);
  const [showTransfersSubMenu, setShowTransfersSubMenu] = useState(false);
  const [showWalletSubMenu, setShowWalletSubMenu] = useState(false);
  const [showBookingReportsSubMenu, setShowBookingReportsSubMenu] =
    useState(false);
  const [showSettingsSubMenu, setShowSettingsSubMenu] = useState(false);
  const [showBalanceSubMenu, setShowBalanceSubMenu] = useState(false);
  const [showProfileSubMenu, setShowProfileSubMenu] = useState(false);
  const [showSupportSubMenu, setShowSupportSubMenu] = useState(false);

  const toggleHotelSubMenu = () => {
    setShowHotelSubMenu(!showHotelSubMenu);
  };
  const toggleSightseeingSubMenu = () => {
    setShowSightseeingSubMenu(!showSightseeingSubMenu);
  };
  const toggleTourPackagesSubMenu = () => {
    setShowTourPackagesSubMenu(!showTourPackagesSubMenu);
  };
  const toggleTransfersSubMenu = () => {
    setShowTransfersSubMenu(!showTransfersSubMenu);
  };
  const toggleWalletSubMenu = () => {
    setShowWalletSubMenu(!showWalletSubMenu);
  };
  const toggleBookingReportsSubMenu = () => {
    setShowBookingReportsSubMenu(!showBookingReportsSubMenu);
  };
  const toggleSettingsSubMenu = () => {
    setShowSettingsSubMenu(!showSettingsSubMenu);
  };
  const toggleBalanceSubMenu = () => {
    setShowBalanceSubMenu(!showBalanceSubMenu);
  };
  const toggleProfileSubMenu = () => {
    setShowProfileSubMenu(!showProfileSubMenu);
  }
  const toggleShowSupportSubMenu = () => {
    setShowSupportSubMenu(!showProfileSubMenu);
  };

  const handleMouseEnter = (toggleFunction) => {
    return () => {
      toggleFunction(true);
    };
  };

  // Function to handle mouse leave event
  const handleMouseLeave = (toggleFunction) => {
    return () => {
      toggleFunction(false);
    };
  };

  return (
    // <body className="sidebar-mini layout-fixed loderover sidebar-collapse">
    //   <div className="wrapper">
    <>
      <Navbar expand="md" className="main-header" ref={mainHeaderRef}>
        <Nav className="navbar-nav nav_toggle">
          <Nav.Link onClick={toggleSidebar}>
            <FaBars className="icon menu-icon" />
          </Nav.Link>
        </Nav>
        <div className="agent_mob_logo ">
          <Link to="/agent/dashboard">
            <img
              src=" https://tripoholidays.com//public/img/profile_imgs/20221010_051756_0000_4pPvJXhCle.png"
              alt="Logo"
              className="brand-image"
            />
          </Link>
        </div>
        {/* <Nav className="ml-auto nav_support_menu ">
          <NavDropdown
            title={
              <>
                <MdHeadset/>
                &nbsp;Support <span className="caret" />
              </>
            }
            id="support-dropdown"
            className="quick_link support_link d-none d-lg-flex">
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaUserCircle className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">Account Manager</span>
                <span className="sub_txt">
                  +919821446008 <br /> info@tripoholidays.com
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <IoMdGlobe className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">Customer Support</span>
                <span className="sub_txt">
                  techsupport@tripoholidays.com <br /> 98214 46008
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon whatsapp_icon">
                <MdWhatsapp className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">98214 46008</span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaEnvelope className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">For Booking</span>
                <span className="sub_txt">
                  <a href="mailto:support@tripoholidays.com">
                    support@tripoholidays.com
                  </a>
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaEnvelope className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">For Refund</span>
                <span className="sub_txt">
                  <a href="mailto:support@tripoholidays.com">
                    support@tripoholidays.com
                  </a>
                </span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="₹ 0.00"
            id="balance-dropdown"
            className="nav-item quick_link">
            <NavDropdown.Item href=" /agent/transaction_log">
              Balance <span>₹ 0.00</span>
            </NavDropdown.Item>
            <NavDropdown.Item href=" /agent/credit_limit_log">
              Credit <span>₹ 0.00</span>
            </NavDropdown.Item>
            <NavDropdown.Item href=" /agent/wallet?type=instant_recharge">
              Top-up Online
            </NavDropdown.Item>
            <NavDropdown.Item href=" /agent/wallet">
              Top-up request
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <>
                <FaUser/> TRIPO107{" "}
                <span className="caret" />
              </>
            }
            id="profile-dropdown"
            className="nav-item quick_link prf_link">
            <NavDropdown.Item href=" /agent/dashboard">
              <FaUser className="icon" />
              eWeblink
            </NavDropdown.Item>
            <NavDropdown.Item href=" /agent/dashboard">
              <ImUserPlus className="icon" /> Dashboard
            </NavDropdown.Item>
            <NavDropdown.Item
              href=" /agent/logout"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("logout-form").submit();
              }}>
              <FaPowerOff className="icon" /> Logout
            </NavDropdown.Item>
            <form
              method="POST"
              action=" /agent/logout"
              acceptCharset="UTF-8"
              name="admin_login"
              id="logout-form">
              <input
                name="_token"
                type="hidden"
                defaultValue="Y4xDCP1NYMqxl0e6iPGBGfTCpkYOPV74jESoljqp"
              />
            </form>
          </NavDropdown>
        </Nav> */}
        <Nav className="navbar-nav agent_nav ">
          <NavDropdown
            title={
              <>
                <FaBed className="icon" /> Hotel <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowHotelSubMenu)}
            onMouseLeave={handleMouseLeave(setShowHotelSubMenu)}
            show={showHotelSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/hotels">
              Search Hotel
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/bookings/hotel">
              Hotel Booking List
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/hotelmarkup">
              Hotel Markup List
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/agent/bookings/hotel/cancel/request/list"
            >
              Hotel Cancellation List
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link
            target="_blank"
            href=" /agent/hotel"
            className="agentnav_item">
            <FaBed className="icon" />
            <span>Hotel</span>
          </Nav.Link>
          <Nav.Link
            target="_blank"
            href=" /agent/sightseeing"
            className="agentnav_item">
            <FaStar className="icon" />
            <span>Sightseeing</span>
          </Nav.Link>
          <Nav.Link
            target="_blank"
            href=" /agent/tour"
            className="agentnav_item">
            <FaSuitcaseRolling className="icon" />
            <span>Tour&nbsp;Packages</span>
          </Nav.Link>
          <Nav.Link
            target="_blank"
            href=" /agent/transfers"
            className="agentnav_item">
            <MdDirectionsCar className="icon" />
            <span>Transfers </span>
          </Nav.Link> */}
          <NavDropdown
            title={
              <>
                <FaStar className="icon" /> Sightseeing{" "}
                <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowSightseeingSubMenu)}
            onMouseLeave={handleMouseLeave(setShowSightseeingSubMenu)}
            show={showSightseeingSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/sightseeing">
              Search Sightseeing
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/bookings/sightseeing">
              Sightseeing Booking List
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/sightseeingmarkup">
              Sightseeing Markup List
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/agent/bookings/amendments/lists/sightseeing"
            >
              Sightseeing Amendment List
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaSuitcaseRolling className="icon" /> Tour&nbsp;Packages{" "}
                <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowTourPackagesSubMenu)}
            onMouseLeave={handleMouseLeave(setShowTourPackagesSubMenu)}
            show={showTourPackagesSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/tour">
              Search Packages
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/bookings/tour">
              Packages Booking List
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/packagemarkup">
              Packages Markup List
            </NavDropdown.Item>
            {/* <NavDropdown.Item href=" /agent/bookings/hotel/cancel/request/list">
              Cancellation List
            </NavDropdown.Item> */}
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <MdDirectionsCar className="icon" /> Transfers{" "}
                <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowTransfersSubMenu)}
            onMouseLeave={handleMouseLeave(setShowTransfersSubMenu)}
            show={showTransfersSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/transfers">
              Search Transfers
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/bookings/transfer">
              Transfers Booking List
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/transfermarkup">
              Transfers Markup List
            </NavDropdown.Item>
            {/* <NavDropdown.Item href=" /agent/bookings/hotel/cancel/request/list">
              Cancellation List
            </NavDropdown.Item> */}
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaCreditCard className="icon" /> Wallet &amp; Deposits{" "}
                <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowWalletSubMenu)}
            onMouseLeave={handleMouseLeave(setShowWalletSubMenu)}
            show={showWalletSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/transaction_log">
              Transactions Log
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/credit_limit_log">
              Credit Limit Log
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/wallet">
              Wallet
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/recharge/history">
              Recharge History
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaFileAlt className="icon" /> Booking Reports{" "}
                <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowBookingReportsSubMenu)}
            onMouseLeave={handleMouseLeave(setShowBookingReportsSubMenu)}
            show={showBookingReportsSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/bookings/hotel">
              Booking Reports
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/profitloss">
              Profit & Loss
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/report/dailysale">
              Daily Sales Report
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/report/ledger">
              Ledger Report
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <MdHeadset className="icon" />
                Support <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="quick_link agentnav_item support_link d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowSupportSubMenu)}
            onMouseLeave={handleMouseLeave(setShowSupportSubMenu)}
            show={showSupportSubMenu}
          >
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaUserCircle className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">Account Manager</span>
                <span className="sub_txt">
                  +919821446008 <br /> info@tripoholidays.com
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <IoMdGlobe className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">Customer Support</span>
                <span className="sub_txt">
                  techsupport@tripoholidays.com <br /> 98214 46008
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon whatsapp_icon">
                <MdWhatsapp className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">98214 46008</span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaEnvelope className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">For Booking</span>
                <span className="sub_txt">
                  <Link
                    className="mail_link"
                    to="mailto:support@tripoholidays.com"
                  >
                    support@tripoholidays.com
                  </Link>
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="cus_icon">
                <FaEnvelope className="icon" />
              </div>
              <div className="cus_txt">
                <span className="title">For Refund</span>
                <span className="sub_txt">
                  <Link
                    className="mail_link"
                    to="mailto:support@tripoholidays.com"
                  >
                    support@tripoholidays.com
                  </Link>
                </span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaCog className="icon" /> Settings <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="agentnav_item d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowSettingsSubMenu)}
            onMouseLeave={handleMouseLeave(setShowSettingsSubMenu)}
            show={showSettingsSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/profile">
              My Profile
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/change_password">
              Change Password
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaWallet className="icon" />
                ₹ 0.00 <span className="caret" />
              </>
            }
            id="hotel-dropdown"
            className="nav-item agentnav_item quick_link d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowBalanceSubMenu)}
            onMouseLeave={handleMouseLeave(setShowBalanceSubMenu)}
            show={showBalanceSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/transaction_log">
              Balance <span>₹ 0.00</span>
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/credit_limit_log">
              Credit <span>₹ 0.00</span>
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/agent/wallet?type=instant_recharge"
            >
              Top-up Online
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/wallet">
              Top-up request
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <>
                <FaUser className="icon" /> TRIPO107 <span className="caret" />
              </>
            }
            id="profile-dropdown"
            className="nav-item agentnav_item quick_link prf_link d-none d-lg-flex"
            onMouseEnter={handleMouseEnter(setShowProfileSubMenu)}
            onMouseLeave={handleMouseLeave(setShowProfileSubMenu)}
            show={showProfileSubMenu}
          >
            <NavDropdown.Item as={Link} to="/agent/dashboard">
              <FaUser className="iconn" />
              eWeblink
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/agent/dashboard">
              <ImUserPlus className="iconn" /> Dashboard
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/agent/logout"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("logout-form").submit();
              }}
            >
              <FaPowerOff className="iconn" /> Logout
            </NavDropdown.Item>
            <form
              method="POST"
              action=" /agent/logout"
              acceptCharset="UTF-8"
              name="admin_login"
              id="logout-form"
            >
              <input
                name="_token"
                type="hidden"
                defaultValue="Y4xDCP1NYMqxl0e6iPGBGfTCpkYOPV74jESoljqp"
              />
            </form>
          </NavDropdown>

          <Nav.Link
            to="/agent/logout"
            className="agentnav_item d-none d-lg-block"
          >
            <FaSignOutAlt className="icon" />
            <span>Logout </span>
          </Nav.Link>
        </Nav>
      </Navbar>

      {/* <Sidebar/>
       */}

      <div
        className={`main-sidebar ${showSidebar ? "active" : ""}`}
        style={{ top: `${mainHeaderHeight}px` }}
      >
        <Navbar expand="lg" className="sidebar">
          <Container fluid>
            {/* Brand Logo */}
            {/* <Navbar.Brand href="/agent/dashboard">
              <img
                src="https://tripoholidays.com/public/img/profile_imgs/20221010_051756_0000_4pPvJXhCle.png"
                alt="Logo"
                className="brand-image"
              />
            </Navbar.Brand> */}

            {/* Collapse Button */}
            {/* <Navbar.Toggle aria-controls="sidebar-nav" /> */}

            {/* Sidebar Content */}
            {/* <Navbar.Collapse id="sidebar-nav"> */}
            <Nav className="flex-column">
              {/* Dashboard */}
              <Nav.Link as={Link} to="/agent/dashboard">
                <FaChartBar className="icon sideicon" /> Dashboard
              </Nav.Link>

              {/* Hotel Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleHotelSubMenu}
              >
                <FaBed className="icon sideicon" /> Hotel{" "}
                {showHotelSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Hotel Menu */}
              {showHotelSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/hotels">
                    <FaRegCircle className="icon" /> Hotel Search
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/hotel">
                    <FaRegCircle className="icon" /> Hotel Booking List
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/hotel/cancel/request/list">
                    <FaRegCircle className="icon" /> Cancellation List
                  </Nav.Link>
                  <Nav.Link to="/agent/hotelmarkup">
                    <FaRegCircle className="icon" /> Hotel Markup
                  </Nav.Link>
                </Nav>
              )}

              {/* Sightseeing Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleSightseeingSubMenu}
              >
                <FaBinoculars className="icon sideicon" /> Sightseeing{" "}
                {showSightseeingSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {showSightseeingSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/sightseeing">
                    <FaRegCircle className="icon" /> Sightseeing Search
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/sightseeing">
                    <FaRegCircle className="icon" /> Sightseeing Booking List
                  </Nav.Link>
                  <Nav.Link to="/agent/sightseeingmarkup">
                    <FaRegCircle className="icon" /> Sightseeing Markup
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/amendments/lists/sightseeing">
                    <FaRegCircle className="icon" /> Amendment Lists
                  </Nav.Link>
                </Nav>
              )}

              {/* Tour Packages Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleTourPackagesSubMenu}
              >
                <FaUmbrellaBeach className="icon sideicon" /> Tour Packages{" "}
                {showTourPackagesSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Tour Packages Menu */}
              {showTourPackagesSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/tour">
                    <FaRegCircle className="icon" /> Packages Search
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/tour">
                    <FaRegCircle className="icon" /> Packages Booking List
                  </Nav.Link>
                  <Nav.Link to="/agent/packagemarkup">
                    <FaRegCircle className="icon" /> Packages Markup
                  </Nav.Link>
                </Nav>
              )}

              {/* Transfers Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleTransfersSubMenu}
              >
                <MdDirectionsCar className="icon sideicon" /> Transfers{" "}
                {showTransfersSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Transfers Menu */}
              {showTransfersSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/transfers">
                    <FaRegCircle className="icon" /> Transfers Search
                  </Nav.Link>
                  <Nav.Link to="/agent/bookings/transfer">
                    <FaRegCircle className="icon" /> Transfers Booking List
                  </Nav.Link>
                  <Nav.Link to="/agent/transfermarkup">
                    <FaRegCircle className="icon" /> Transfers Markup
                  </Nav.Link>
                </Nav>
              )}

              {/* Wallet & Deposits Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleWalletSubMenu}
              >
                <FaCreditCard className="icon sideicon" /> Wallet &amp; Deposits{" "}
                {showWalletSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Wallet & Deposits Menu */}
              {showWalletSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/transaction_log">
                    <FaRegCircle className="icon" /> Transactions Log
                  </Nav.Link>
                  <Nav.Link to="/agent/credit_limit_log">
                    <FaRegCircle className="icon" /> Credit Limit Log
                  </Nav.Link>
                  <Nav.Link to="/agent/wallet">
                    <FaRegCircle className="icon" /> Wallet
                  </Nav.Link>
                  <Nav.Link to="/agent/recharge/history">
                    <FaRegCircle className="icon" /> Recharge History
                  </Nav.Link>
                </Nav>
              )}

              {/* Booking Reports Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleBookingReportsSubMenu}
              >
                <FaFileAlt className="icon sideicon" /> Booking Reports{" "}
                {showBookingReportsSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Booking Reports Menu */}
              {showBookingReportsSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/bookings/hotel">
                    <FaRegCircle className="icon" /> Booking Reports
                  </Nav.Link>
                  <Nav.Link to="/agent/profitloss">
                    <FaRegCircle className="icon" /> Profit & Loss
                  </Nav.Link>
                  <Nav.Link to="/agent/report/dailysale">
                    <FaRegCircle className="icon" /> Daily Sales Report
                  </Nav.Link>
                  <Nav.Link to="/agent/report/ledger">
                    <FaRegCircle className="icon" /> Ledger Report
                  </Nav.Link>
                </Nav>
              )}

              {/* Settings Menu */}
              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleSettingsSubMenu}
              >
                <FaCog className="icon sideicon" /> Settings{" "}
                {showSettingsSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Settings Menu */}
              {showSettingsSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/profile">
                    <FaRegCircle className="icon" /> My Profile
                  </Nav.Link>
                  <Nav.Link to="/agent/change_password">
                    <FaRegCircle className="icon" /> Change Password
                  </Nav.Link>
                </Nav>
              )}

              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleBalanceSubMenu}
              >
                <FaWallet className="icon sideicon" /> ₹ 0.00{" "}
                {showBalanceSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Wallet & Deposits Menu */}
              {showBalanceSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/transaction_log">
                    <FaRegCircle className="icon" /> Balance <span>₹ 0.00</span>
                  </Nav.Link>
                  <Nav.Link to="/agent/credit_limit_log">
                    <FaRegCircle className="icon" /> Credit <span>₹ 0.00</span>
                  </Nav.Link>
                  <Nav.Link to="/agent/wallet?type=instant_recharge">
                    <FaRegCircle className="icon" /> Top-up Online
                  </Nav.Link>
                  <Nav.Link to="/agent/wallet">
                    <FaRegCircle className="icon" /> Top-up request
                  </Nav.Link>
                </Nav>
              )}

              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu"
                onClick={toggleProfileSubMenu}
              >
                <FaUser className="icon sideicon" /> TRIPO107{" "}
                {showProfileSubMenu ? (
                  <FaAngleUp className="submenu-icon" />
                ) : (
                  <FaAngleDown className="submenu-icon" />
                )}
              </Nav.Link>
              {/* Submenu for Wallet & Deposits Menu */}
              {showProfileSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link to="/agent/dashboard">
                    <FaRegCircle className="icon" /> eWeblink
                  </Nav.Link>
                  <Nav.Link to="/agent/dashboard">
                    <FaRegCircle className="icon" /> Dashboard
                  </Nav.Link>
                  <Nav.Link
                    to="/agent/logout"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("logout-form").submit();
                    }}
                  >
                    <FaRegCircle className="icon" /> Logout
                  </Nav.Link>
                </Nav>
              )}

              {/* Logout */}
              <Nav.Link
                to="/agent/logout"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("logout-form").submit();
                }}
              >
                <FaSignOutAlt className="icon sideicon" /> Logout
              </Nav.Link>
              <form
                method="POST"
                action="https://tripoholidays.com/agent/logout"
                acceptCharset="UTF-8"
                name="admin_login"
                id="logout-form"
              >
                <input
                  name="_token"
                  type="hidden"
                  value="L8ZjQJJrtCzC0BOHF2aBTocyNwJHwTW9QNNkf9Ih"
                />
              </form>
            </Nav>
            {/* </Navbar.Collapse> */}
          </Container>
        </Navbar>
      </div>
    </>
    //   </div>
    // </body>
  );
}

export default DashboardNavbar;
