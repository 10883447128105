import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './NotFound.css'; // Import your custom CSS for styling

const NotFound = () => {
  return (
    <div className="not_found_page">
      <div className="section-content">
        <Container>
          <div className="inner_box text-center">
            <Row>
              <Col md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }}>
                <h3>Sorry!</h3>
                <p>we could't find the page you are looking for&#128532;</p>
                <div className="custom_pg_img coming_soon_img round_img">
                  <img src="https://www.brn.co.il/wp-content/uploads/2023/03/8030430_3828537.webp" className="img-responsive" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default NotFound;
