import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col, Card, Breadcrumb, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

const CreditLimitLog = () => {
  const CreditData = [
    {
      id: 1,
      detail: "Credit Limit 1",
      limit: 1000,
      date: "2024-02-17",
    },
    {
      id: 2,
      detail: "Credit Limit 2",
      limit: 2000,
      date: "2024-02-18",
    },
    
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5; // Number of entries per page

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = CreditData.slice(indexOfFirstEntry, indexOfLastEntry);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(CreditData.length / entriesPerPage);
  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Credit Limit Log</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Credit Limit Log</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body className="table-responsive">
                    <Col md={12}>
                      <table
                        id=""
                        className="table table-bordered table-hover text-nowrap">
                        <thead>
                          <tr>
                            <th>S. N.</th>
                            <th>Detail</th>
                            <th>Limit</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody className="tdata">
                          {/* Map through the transaction data */}
                          {currentEntries.length > 0 ? (
                            currentEntries.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction.id}</td>
                                <td>{transaction.detail}</td>
                                <td>{transaction.limit}</td>
                                <td>{transaction.date}</td>
                              </tr>
                            ))
                          ) : (
                            <tr className="odd">
                              <td
                                valign="top"
                                colSpan="4"
                                className="dataTables_empty"
                                style={{ textAlign: "center" }}>
                                No data available in table
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Col>

                    <Col md={12}>
                      {/* Pagination */}
                      <Row>
                        <Col sm={12} md={5}>
                          <div
                            className="dataTables_info"
                            id="invoicetable_info"
                            role="status"
                            aria-live="polite">
                            {CreditData.length > 0
                              ? `Showing ${indexOfFirstEntry + 1} to ${Math.min(
                                  indexOfLastEntry,
                                  CreditData.length
                                )} of ${CreditData.length} entries`
                              : "No bookings to show"}
                          </div>
                        </Col>
                        <Col sm={12} md={7}>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="invoicetable_paginate">
                            <Pagination>
                              {/* Previous page button */}
                              <Pagination.Prev
                                onClick={() => paginate(currentPage - 1)}
                                disabled={
                                  currentPage === 1 || CreditData.length === 0
                                }>
                                Previous
                              </Pagination.Prev>
                              {/* Page numbers */}
                              {Array.from({ length: totalPages }).map(
                                (_, index) => (
                                  <Pagination.Item
                                    key={index}
                                    active={currentPage === index + 1}
                                    onClick={() => paginate(index + 1)}>
                                    {index + 1}
                                  </Pagination.Item>
                                )
                              )}
                              {/* Next page button */}
                              <Pagination.Next
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                  currentPage === totalPages ||
                                  CreditData.length === 0
                                }>
                                Next
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default CreditLimitLog;
