import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import destinationsData from "../tourpackage/DestinationsData";
import TopBanner from "./TopBanner";
import SecondaryNav from "./SecondaryNav";
import PackageSidebar from "./PackageSidebar";
import BookingSteps from "./BookingSteps";
import TripDetailsSection from "./TripDetailSection";
import InclusionExclusionSection from "./InclusionExclusionSection";
import Itinerary from "./Itenary";
import './Package.css'
import PackageDescription from "./PackageDescription";
import axios from "axios";

const Package = () => {
  const { destination, packageName } = useParams();
  const [packageData, setPackageData] = useState(null);
  const [tourpolicy, settourpolicy] = useState(null);
  const [cancelationpolicy, setcancelationpolicy] = useState(null);
  const [paymentpolicy, setpaymentpolicy] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://tripoholidays.in/api/destinations/${destination}/${packageName}`);
        setPackageData(response.data.data.packagedetail);
        settourpolicy(response.data.data.tour_policy);
        setcancelationpolicy(response.data.data.cancelation_policy);
        setpaymentpolicy(response.data.data.payment_policy);
      } catch (error) {
        console.error("Error fetching package data:", error);
      }
    };

    fetchData();
  }, [destination, packageName]);

  if (!packageData || !tourpolicy) {
    return <div>Loading...</div>;
  }

  const { destination_slug, name, type, offer_price, publish_price, nights, days, details_day_night, package_overview, package_inclusions, package_exclusions, package_topinclusions, package_gallery, hotel, package_itinerary } = packageData;


  // const formattedPackageName = packageName
  //   .toLowerCase()
  //   .replace(/[^a-zA-Z0-9]+/g, "");

  // let selectedPackage = null;

  // const selectedDestination = destinationsData.find(
  //   (dest) => dest.destination.toLowerCase() === destination.toLowerCase()
  // );

  // if (selectedDestination) {
  //   selectedPackage = selectedDestination.tour_packages.find(
  //     (pkg) =>
  //       pkg.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") ===
  //       formattedPackageName
  //   );
  // }

  return (
    <>
      <section className="main_detail_pack">
        <TopBanner
          packageName={packageData}
        />
        <div className="details_main packageDetailView">
          <div className="bg_color_1 pack_details" style={{ transform: "none" }}>
            <SecondaryNav />
            <Container className="padd_30_25" style={{ transform: "none" }}>
              <BookingSteps />
              <Row id="row_scroll" style={{ transform: "none" }}>
                <Col lg={8}>
                  <TripDetailsSection packageName={packageData} />
                  <PackageDescription packageName={packageData} />
                  <Itinerary packageName={packageData} />
                  <InclusionExclusionSection packageName={packageData} tourpolicy={tourpolicy} cancelationpolicy={cancelationpolicy} paymentpolicy={paymentpolicy} />
                </Col>
                <Col lg={4} className="pack_sidebar" id="sidebar">
                  <PackageSidebar packageName={packageData} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Package;
