import React from 'react'
import CustomBanner from './CustomBanner'
import CustomBreadcrumb from '../Contact/CustomBreadcrumb'
import DContent from './D_Content'

const Disclaimer = () => {
  return (
    <div>
        <CustomBanner title="Discliamer"/>
        <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "Disclaimer" }]}
        />
        <DContent/>
    </div>
  )
}

export default Disclaimer