import React from "react";
// import TravelAgentLogin from "./TravelAgentLogin";
// import Advantages from "./Advantages";
// import Statistics from "./Statistics";
// import Brands from "./Brands";
import HotelSection from "./HotelSection";
import "./Home.css";
import BookingSystem from "./BookingSystem";
import LastComponent from "./LastComponent";
import PluginsSection from "./PluginSection";
import Advantages from "../Login/Advantages";
import Brands from "../Login/Brands";
import Statistics from "../Login/Statistics";
import BecomeAgentSection from "./BecomeAgentSection";
import StatisticsData from "../Login/StatisticsData";
import LastSection from "./LastSection";

const Home = () => {
  
  return (
    <div className="homepg">
      <HotelSection />
      <Advantages />
      <div
        id="booking-system"
        class="nicdark_section nicdark_height_150 nicdark_display_none_all_responsive"
      ></div>
      <BookingSystem />
      <Statistics StatisticsData={StatisticsData} />
      <PluginsSection />
      <Brands />
      {/* <BecomeAgentSection /> */}
      <LastSection/>
    </div>
  );
};

export default Home;
