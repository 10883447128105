import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "./CustomBanners.css";
import { MdDirectionsCar } from "react-icons/md";
import AsyncSelect from "react-select/async";
import {
  FaBed,
  FaCalendarAlt,
  FaStar,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { nationalityOptions, cityOptions } from "./Options";

const SightseeingCustomBanners = () => {
  const [nationality, setNationality] = useState("");
  const handleNationalityChange = (e) => {
    setNationality(e.target.value);
  };

  const [tourDate, setTourDate] = useState(moment());
  const [calendarFocused, setCalendarFocused] = useState(false);

  const handleTourDateChange = (date) => {
    setTourDate(date);
    setCalendarFocused(false); // Hide the calendar when a date is selected
  };

  const handleCalendarFocusChange = ({ focused }) => {
    setCalendarFocused(focused);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(false);

  const fetchCities = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = cityOptions.filter((city) =>
        city.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions.map((city) => ({ value: city, label: city })));
    });
  };

  useEffect(() => {
    fetchCities("").then((data) => setOptions(data));
  }, []);

  const [rooms, setRooms] = useState([{ adults: 2, children: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  return (
    <section className="custom_banner" id="banner">
      <div className="banner-parallax">
        <div
          className="bg-element"
          data-stellar-background-ratio="0.2"
          style={{
            backgroundImage:
              "url(https://tripoholidays.com/public/images/sightseeing_image.jpg)",
          }}></div>
        <div className="banner_search hotel_banner">
          <Container className="conn">
            <Row>
              <Col md={12}>
                <div className="banner_search_box">
                  <div className="section-tab">
                    <ul>
                      <li className="list_item">
                        <Link to="/agent/hotels">
                          <FaBed className="tabicon" />{" "}
                          <span className="d-none d-lg-block">Hotel</span>
                        </Link>
                      </li>
                      <li className="list_item active">
                        <Link to="/agent/sightseeing">
                          <FaStar className="tabicon" />
                          <span className="d-none d-lg-block">Sightseeing</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/tour">
                          <FaSuitcaseRolling className="tabicon" />
                          <span className="d-none d-lg-block">
                            Tour Package
                          </span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/transfers">
                          <MdDirectionsCar className="tabicon" />
                          <span className="d-none d-lg-block">Transfer</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="tab_content">
                    <div className="custom_tabs_sec">
                      <div className="custom_form_action">
                        {" "}
                        <Form
                          onSubmit={handleSubmit}
                          className="booking_search_form hotel_booking_search">
                          <div className="inner_form_action">
                            <div className="cus_field location_field hotel_location_field searchcity">
                              <div className="input-box">
                                <label
                                  htmlFor="fromCity"
                                  className="label-text">
                                  <span className="span_label sel_type">
                                    Enter Destination
                                  </span>
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={fetchCities}
                                    loadingMessage={() => "Loading..."}
                                    noOptionsMessage={() => "No cities found"}
                                    placeholder="Select City or Hotel"
                                    options={options}
                                    value={selectedOption}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    onChange={(option) => {
                                      setSelectedOption(option);
                                    }}
                                  />
                                </label>
                              </div>
                            </div>

                            <div className="cus_field calendar_field checkinout_field">
                              <div className="merge_field">
                                <div className="input-box input_chkin">
                                  <SingleDatePicker
                                    date={tourDate}
                                    onDateChange={handleTourDateChange}
                                    focused={calendarFocused}
                                    onFocusChange={handleCalendarFocusChange}
                                    id="datepicker-time-start"
                                    readOnly={true}
                                    numberOfMonths={1}
                                    small={true}
                                  />
                                  <label
                                    htmlFor="checkin"
                                    className="label-text negmt"
                                    onClick={() => setCalendarFocused(true)}>
                                    <span className="span_label">
                                      <FaCalendarAlt /> Tour Date
                                    </span>
                                    <p className="sr_date checkin mydepdate">
                                      {tourDate.format("DD ")}{" "}
                                      <span>{tourDate.format("MMM'YY")}</span>
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <input
                                type="hidden"
                                value={tourDate.format("DD/MM/YYYY")}
                                id="departuredate"
                              />
                            </div>

                            <div className="cus_field traveler_field cus_passenger_field">
                              <div className="input-box">
                                <label
                                  id="travelersdetail"
                                  htmlFor="travellers"
                                  className="label-text"
                                  onClick={() =>
                                    setLabelClicked(!labelClicked)
                                  }>
                                  <span className="span_label">
                                    Adults and children
                                  </span>
                                  <p className="sr_travel">
                                    <span id="guestcount">
                                      {rooms[0].adults} Adults,{" "}
                                      {rooms[0].children} Child
                                    </span>{" "}
                                  </p>
                                </label>
                                <div
                                  className="onlytraveller normaltraveller"
                                  style={{
                                    display: labelClicked ? "block" : "none",
                                  }}>
                                  <ul className="traveller_list">
                                    <li>
                                      <div className="list-persons-count">
                                        <div id="roomshtml">
                                          <div className="box" id="divroom1">
                                            <div className="roomTxt">
                                              <span>Room 1:</span>
                                            </div>
                                            <div className="left pull-left">
                                              <span className="txt">
                                                <span id="Label7">
                                                  Adult{" "}
                                                  <em>(Above 12 years)</em>
                                                </span>
                                              </span>
                                            </div>
                                            <div className="right pull-right">
                                              <div
                                                id="field1"
                                                className="PlusMinusRow">
                                                <a
                                                  type="button"
                                                  id="Adults_room_1_1_minus"
                                                  className="sub hoteladultclass"
                                                  onClick={() =>
                                                    updateRoom(
                                                      0,
                                                      "adults",
                                                      Math.max(
                                                        rooms[0].adults - 1,
                                                        1
                                                      )
                                                    )
                                                  }>
                                                  -
                                                </a>
                                                <span
                                                  id="Adults_room_1_1"
                                                  className="PlusMinus_number">
                                                  {rooms[0].adults}
                                                </span>
                                                <a
                                                  type="button"
                                                  id="Adults_room_1_1_plus"
                                                  className="add hoteladultclass"
                                                  onClick={() =>
                                                    updateRoom(
                                                      0,
                                                      "adults",
                                                      Math.min(
                                                        rooms[0].adults + 1,
                                                        6
                                                      )
                                                    )
                                                  }>
                                                  +
                                                </a>
                                              </div>
                                            </div>
                                            <div className="spacer"></div>
                                            <div className="left pull-left">
                                              <span className="txt">
                                                <span id="Label9">
                                                  Child{" "}
                                                  <em>(Below 12 years)</em>
                                                </span>
                                              </span>
                                            </div>
                                            <div className="right pull-right">
                                              <div
                                                id="field2"
                                                className="PlusMinusRow">
                                                <a
                                                  type="button"
                                                  id="Children_room_1_1_minus"
                                                  className="sub hotelchildclass"
                                                  onClick={() =>
                                                    updateRoom(
                                                      0,
                                                      "children",
                                                      Math.max(
                                                        rooms[0].children - 1,
                                                        0
                                                      )
                                                    )
                                                  }>
                                                  -
                                                </a>
                                                <span
                                                  id="Children_room_1_1"
                                                  className="PlusMinus_number">
                                                  {rooms[0].children}
                                                </span>
                                                <a
                                                  type="button"
                                                  id="Children_room_1_1_plus"
                                                  className="add hotelchildclass"
                                                  onClick={() =>
                                                    updateRoom(
                                                      0,
                                                      "children",
                                                      Math.min(
                                                        rooms[0].children + 1,
                                                        6
                                                      )
                                                    )
                                                  }>
                                                  +
                                                </a>
                                              </div>
                                            </div>
                                            <div className="clear"></div>
                                            {rooms[0].children > 0 && (
                                              <div className="childresAgeTxt">
                                                Age(s) of Children
                                              </div>
                                            )}
                                            {rooms[0].children > 0 &&
                                              [...Array(rooms[0].children)].map(
                                                (_, childIndex) => (
                                                  <select key={childIndex}>
                                                    {[...Array(12)].map(
                                                      (age, index) => (
                                                        <option key={index + 1}>
                                                          {index + 1}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                )
                                              )}
                                            <div className="clear"></div>
                                          </div>
                                        </div>
                                        <a
                                          className="apply_btn"
                                          href="javascript:;"
                                          // style={{
                                          //   display:
                                          //     index === rooms.length - 1
                                          //       ? "inline-block"
                                          //       : "none",
                                          // }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setLabelClicked(false); // Hide the onlytraveller or normaltraveller div
                                          }}>
                                          Done
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="booking_search_btn">
                            <div className="search_btn">
                              <div className="row">
                                <div
                                  className="col-md-9"
                                  style={{ textAlign: "center" }}>
                                  <Button
                                    type="submit"
                                    className="cus_btn btn_gradient roundformsearch" style={{marginBottom:"5px"}}>
                                    <i className="fa fa-search"></i> Search
                                    Sightseeing
                                  </Button>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    as="select"
                                    id="nationalitydrop"
                                    onChange={handleNationalityChange}>
                                    {nationalityOptions.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default SightseeingCustomBanners;
