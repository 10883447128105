import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "./TransferCustomBanner.css";
import { MdDirectionsCar } from "react-icons/md";
import AsyncSelect from "react-select/async";
import {
  FaBed,
  FaCalendarAlt,
  FaExchangeAlt,
  FaStar,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { nationalityOptions, cityOptions } from "./Options";

const TransferCustomBanners = () => {
  const [nationality, setNationality] = useState("");
  const handleNationalityChange = (e) => {
    setNationality(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const [dateRange, setDateRange] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(false);

  const fetchCities = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = cityOptions.filter((city) =>
        city.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions.map((city) => ({ value: city, label: city })));
    });
  };

  useEffect(() => {
    fetchCities("").then((data) => setOptions(data));
  }, []);

  const [rooms, setRooms] = useState([{ adults: 2, children: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);

  const addRoom = () => {
    if (rooms.length < 6) {
      setRooms([...rooms, { adults: 2, children: 0 }]);
    }
  };

  const removeRoom = (index) => {
    if (rooms.length > 1) {
      const updatedRooms = [...rooms];
      updatedRooms.splice(index, 1);
      setRooms(updatedRooms);
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  return (
    <section className="custom_banner" id="banner">
      <div className="banner-parallax">
        <div
          className="bg-element"
          data-stellar-background-ratio="0.2"
          style={{
            backgroundImage:
              "url(https://tripoholidays.com/public/images/airport-transfer.png)",
          }}></div>
        <div className="banner_search hotel_banner">
          <Container className="conn">
            <Row>
              <Col md={12}>
                <div className="banner_search_box">
                  <div className="section-tab">
                    <ul>
                      <li className="list_item">
                        <Link to="/agent/hotels">
                          <FaBed className="tabicon" />{" "}
                          <span className="d-none d-lg-block">Hotel</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/sightseeing">
                          <FaStar className="tabicon" />
                          <span className="d-none d-lg-block">Sightseeing</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/tour">
                          <FaSuitcaseRolling className="tabicon" />
                          <span className="d-none d-lg-block">
                            Tour Package
                          </span>
                        </Link>
                      </li>
                      <li className="list_item active">
                        <Link to="/agent/transfers">
                          <MdDirectionsCar className="tabicon" />
                          <span className="d-none d-lg-block">Transfer</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="tab_content">
                    <div className="custom_tabs_sec">
                      <div className="custom_form_action">
                        <Form onSubmit={handleSubmit}>
                          <div
                            id="Transfer"
                            className="tab-pane active">
                            <div className="tb_trnf rtb">
                              <div className="d-flex frmdfs booking_search_form">
                                <div className="form_input_trs wd40 searchcity">
                                  <label>From</label>
                                  <div className="form_rtv input-box">
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={fetchCities}
                                      placeholder="Address, airport, hotel or other place"
                                      value={selectedOption}
                                      onChange={(option) =>
                                        setSelectedOption(option)
                                      }
                                    />
                                  </div>
                                  <span className="from-error custom-error"></span>
                                </div>
                                <span className="excng">
                                  <FaExchangeAlt/>
                                </span>
                                <div className="form_input_trs wd40 searchcity">
                                  <label>To</label>
                                  <div className="form_rtv input-box">
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={fetchCities}
                                      placeholder="Address, airport, hotel or other place"
                                      value={selectedOption}
                                      onChange={(option) =>
                                        setSelectedOption(option)
                                      }
                                    />
                                  </div>
                                  <span className="to-error custom-error"></span>
                                </div>
                                <div className="form_input_trs">
                                  <label className="nonemd">&nbsp;</label>
                                  <div className="btn_rm">
                                    <button
                                      className="findtransfer"
                                      type="submit">
                                      Find transfer
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default TransferCustomBanners;
