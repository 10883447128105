import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PackageSidebar = ({ packageName }) => {
  return (
    // <aside className="col-lg-4 pack_sidebar" id="sidebar">
    <div className="theiaStickySidebar">
      <div className="booking_sidebar" style={{ display: "none" }}>
        <div
          className="fare_rules sidebar_bgclr inner_sidebar"
          id="DivFareQuote">
          <ul>
            <li style={{ display: "none" }} className="myadults">
              Adults x <span className="no_adult">0</span>{" "}
              <span className="adultprices price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
            <li style={{ display: "none" }} className="myinfants">
              Infant x <span className="no_infants">0</span>{" "}
              <span className="price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
            <li style={{ display: "none" }} className="mycwb">
              Child with bed x <span className="no_cwb">0</span>{" "}
              <span className="price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
            <li style={{ display: "none" }} className="mycwob">
              Child without bed x <span className="no_cwob">0</span>{" "}
              <span className="price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
            <li style={{ display: "none" }} className="mycwobb">
              Child without bed (below 2-3 years) x{" "}
              <span className="no_cwobb">0</span>{" "}
              <span className="price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
            <li style={{ display: "none" }} className="rmyaddons">
              Addons x <span className="no_addon">0</span>{" "}
              <span className="price">
                <i className="fa fa-rupee-sign"></i> 0
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="pkgform-wrapper">
        <div className="package_price">
          <div className="pack_price_sec">
            <input
              type="hidden"
              className="mytotalprice"
              value={packageName.offer_price}
            />
            <div className="pack_price_txt">
              <h4>
                € {packageName.offer_price} <br />
                <sub>Price Per Adult</sub>
              </h4>
            </div>
          </div>

          <div className="booking_sidebar">
            <div className="cus_field calendar_field depart_field">
              <div className="booking-form-title">
                <label>Choose date and number of people</label>
              </div>
              <div className="input-box">
                <Form.Control
                  type="text"
                  id="datepicker-time-start_tour"
                  className="date-range form-control"
                  readOnly
                  placeholder="Choose Date"
                />
              </div>
            </div>

            <Button
              id="dropdown-2__BV_toggle_"
              aria-haspopup="menu"
              aria-expanded="false"
              type="button"
              className="btn btn-secondary">
              1 Adult, 0 Child, 0 Baby
            </Button>

            <div className="price-type-item">
              <div className="type">
                <label>Adult (9+)</label>
              </div>
              <div className="type-count">
                <span className="adult_minus">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M6 11V9H14V11H6Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
                <input
                  className="adult_no"
                  type="number"
                  value="1"
                  readOnly="readonly"
                />
                <span className="adult_plus">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="11"
                      cy="11"
                      r="10.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M10.4524 15.0088V11.5564H7V10.4524H10.4524V7H11.5564V10.4524H15V11.5564H11.5564V15.0088H10.4524Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="price-type-item">
              <div className="type">
                <label>Child (2-9)</label>
              </div>
              <div className="type-count">
                <span className="child_minus">
                  {/* SVG content for minus button */}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M6 11V9H14V11H6Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
                <input
                  className="child_no"
                  type="number"
                  value="0"
                  readOnly="readonly"
                />
                <span className="child_plus">
                  {/* SVG content for plus button */}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="11"
                      cy="11"
                      r="10.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M10.4524 15.0088V11.5564H7V10.4524H10.4524V7H11.5564V10.4524H15V11.5564H11.5564V15.0088H10.4524Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="price-type-item">
              <div className="type">
                <label>Infant (0-2)</label>
              </div>
              <div className="type-count">
                <span className="infant_minus">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M6 11V9H14V11H6Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
                <input
                  className="infant_no"
                  type="number"
                  value="0"
                  readOnly="readonly"
                />
                <span className="infant_plus">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="11"
                      cy="11"
                      r="10.5"
                      fill="white"
                      stroke="#a2c949"
                      className="type-count-svg-circle"></circle>
                    <path
                      d="M10.4524 15.0088V11.5564H7V10.4524H10.4524V7H11.5564V10.4524H15V11.5564H11.5564V15.0088H10.4524Z"
                      fill="#a2c949"
                      className="type-count-svg-path"></path>
                  </svg>
                </span>
              </div>
            </div>

            {/* Add similar blocks for Child and Infant here */}

            <div className="pack_book_btn">
              <Link to="javascript:;" className="check_availability">
                Review and Book
              </Link>
            </div>
            <input type="hidden" className="tour_total_price" value="600" />
            <input type="hidden" className="tour_adult_price" value="600" />
            <input type="hidden" className="tour_child_price" value="400" />
            <input type="hidden" className="tour_infant_price" value="0" />
            <input type="hidden" className="tour_addon_price" value="0" />
            {/* Pack total price section */}
            <div className="pack_total_price_sec" style={{ display: "none" }}>
              <h4>Tour Price</h4>
              <div className="pack_price_txt">
                <h4>
                  Rs <br />
                  <sub>Taxes Included</sub>
                </h4>
              </div>
              <div className="pack_book_btn">
                <Button href="javascript:;" className="bookin_continue">
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </aside>
  );
};

export default PackageSidebar;
