import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaRocket,
  FaThumbsUp,
  FaBed,
  FaCheckSquare,
  FaPlug,
  FaMoneyBill,
  FaCreditCard,
} from "react-icons/fa";
import "./Advantages.css";

const Advantages = () => {
  return (
    <Container className="adv-container">
      <div className="advtd" style={{ marginBottom: "20px" }}>
        <div className="headingal mrggape text-center">
          <h2>Tripoholidays Advantages</h2>
        </div>

        <Row>
          <Col sm={6}>
            <img
              style={{ borderRadius: "20px", marginBottom:"15px" }}
              src="https://images.pexels.com/photos/2407070/pexels-photo-2407070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              className="rps"
              alt=""
            />
          </Col>

          <Col sm={6}>
            <ul className="flx_d">
              <AdvantageItem
                icon={<FaRocket className="icon" />}
                title="Advanced Technology"
                description="User Friendly, Quick and Easy B2B hotel booking system supported by latest technology from reputable IT house"
              />
              <AdvantageItem
                icon={<FaThumbsUp className="icon" />}
                title="Competitive Rates On Most Destinations"
                description="We ensure you enjoy the best rates available in the market."
              />
              <AdvantageItem
                icon={<FaBed className="icon" />}
                title="Wide Range Of Hotels"
                description="From low budget hotel until luxury property including apartment, bungalows and villas."
              />
              <AdvantageItem
                icon={<FaCheckSquare className="icon" />}
                title="Live & Instant Availability"
                description="Provide you with 500,000+ hotels at over 25,000 destination worldwide."
              />
              <AdvantageItem
                icon={<FaPlug className="icon" />}
                title="Direct To Hotel Connectivity"
                description="Guarantee to have the last inventory in hotel."
              />
              <AdvantageItem
                icon={<FaMoneyBill className="icon" />}
                title="Multiple Currency"
                description="Available for multi-currency which always connected to reputable online currency exchange."
              />
              <AdvantageItem
                icon={<FaCreditCard className="icon" />}
                title="Unique Payment Terms"
                description="Easy payment solution from dedicated Virtual Cccount, real-time bank transfer at any Indonesia's Bank and accepts Mastercard/Visa/Amex credit card."
              />
            </ul>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const AdvantageItem = ({ icon, title, description }) => (
  <li>
    <span>{icon}</span>
    <h3>{title}<span>{description}</span></h3>
    
  </li>
);

export default Advantages;
