import React from "react";
import HotelCustomBanners from "./HotelCustomBanners";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "./TourPackagePage.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import TourPackageCustomBanners from "./TourPackageCustomBanners";

const TourPackagePage = () => {
  // Dummy data for destinations
  const destinations = [
    {
      name: "Europe",
      link: "/agent/tour/europe",
      image:
        "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
    },
    {
      name: "Vietnam",
      link: "/agent/tour/vietnam",
      image:
        "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
    },
    {
      name: "Russia",
      link: "/agent/tour/russia",
      image:
        "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
    },
    {
      name: "Turkey",
      link: "/agent/tour/turkey",
      image:
        "https://tripoholidays.com/public/img/media_gallery/tourism-in-turkey37020104_As91YutJrm.jpg",
    },
  ];

  // Options for Owl Carousel
  const owlOptions = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: false,
    autoplayTimeout: 3000,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      800: { items: 3 },
      1000: { items: 4 },
    },
    fluidSpeed:100,
    navClass: ['owl-prev', 'owl-next'],
    // navText: [<FaAngleLeft />, <FaAngleRight />],
  };

  return (
    <div className="tourpackage_page">
      <TourPackageCustomBanners/>
      <div className="package_tour_sec">
        <div className="explore_tour_package">
          <div className="india_tour_package py-50">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="resort_title text-center">
                    <h2>Explore India Tour Packages by Destinations</h2>
                    <p>
                      Holidays in India are nothing short of a dream come true.
                      The ultimate treasures of amazing experiences await here.
                      As you set foot in India, you will find yourselves in
                      love. Let us help you have a memorable affair, browse
                      through our sea of ideas and pick your pearl.
                    </p>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col md={12} className="text-center">
                  <div className="indiatour_package_carousel">
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="https://example.com/slide1.jpg"
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="https://example.com/slide2.jpg"
                          alt="Second slide"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center">
                  <div className="exlore_destination">

                  </div>
                </Col>
              </Row> */}
            </Container>
          </div>
          <div className="international_tour_package">
            <Container>
              <div className="resort_title text-center">
                <h2>Explore International Tour Packages from India</h2>
              </div>
              <OwlCarousel
                className="owl-theme internaionaltour_package_carousel"
                {...owlOptions}>
                {destinations.map((destination, index) => (
                  <div key={index} className="item">
                    <div className="destination_box">
                      <Link to={destination.link}>
                        <img src={destination.image} alt={destination.name} />
                        <div className="destination_name">
                          <span>{destination.name}</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
              <Row>
                <Col md={12} className="text-center">
                  <div className="exlore_destination">
                    {/* <a href="#">View All Destinations</a> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="package_intrnational_resort">
          <Container>
            <div className="resort_title">
              <h2 className="text-center">
                International Group Escorted Tours
              </h2>
            </div>
            <div className="package_card_list">
              <Row>
                <Col md={4}>
                  <Card className="package_card_column">
                    <Link to="/agent/tour/turkey/istanbul-holiday-package-4-night-5-days">
                      <Card.Img
                        variant="top"
                        src="https://tripoholidays.com/public/img/media_gallery/Holiday4Turkey Site Foto (65)_eX4F1eiFOX.png"
                      />
                    </Link>
                    <Card.Body>
                      <div className="inner_content">
                        <Link to="/agent/tour/turkey/istanbul-holiday-package-4-night-5-days">
                          <Card.Title>
                            Istanbul Holiday Package 4 Night 5 Days
                          </Card.Title>
                        </Link>
                        <Card.Text>
                          Istanbul 3N, Cappadocia 2N, Pamukkale 1N, Kusadasi 2N,
                          Istanbul 1N
                        </Card.Text>
                      </div>
                      <div className="price_view_btns">
                        <div>
                          <div className="flex items-baseline">
                            <span className="price">
                              <span className="currency_symb">€</span>1200
                            </span>
                            <span className="pr_perperson">per person</span>
                          </div>
                          <div className="incld_tax">
                            Includes Taxes &amp; Fees
                          </div>
                        </div>
                        <div>
                          <Link
                            to="/agent/tour/turkey/istanbul-holiday-package-4-night-5-days"
                            className="view_btn">
                            View Details
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="package_card_column">
                    <Link to="/agent/tour/turkey/delight-turkey-package">
                      <Card.Img
                        variant="top"
                        src="https://tripoholidays.com/public/img/media_gallery/Holiday4Turkey Site Foto - 2023-05-24T133256_gLjFDkvUxj.png"
                      />
                    </Link>
                    <Card.Body>
                      <div className="inner_content">
                        <Link to="/agent/tour/turkey/delight-turkey-package">
                          <Card.Title>Delight Turkey Package</Card.Title>
                        </Link>
                        <Card.Text>
                          3 Nights Istanbul + 3 Nights Cappadocia
                        </Card.Text>
                      </div>
                      <div className="price_view_btns">
                        <div>
                          <div className="flex items-baseline">
                            <span className="price">
                              <span className="currency_symb">€</span>3000
                            </span>
                            <span className="pr_perperson">per person</span>
                          </div>
                          <div className="incld_tax">
                            Includes Taxes &amp; Fees
                          </div>
                        </div>
                        <div>
                          <Link
                            to="/agent/tour/turkey/delight-turkey-package"
                            className="view_btn">
                            View Details
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default TourPackagePage;
