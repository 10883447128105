import React from 'react'
import HotelCustomBanners from './HotelCustomBanners'
import SightseeingCustomBanners from './SightseeingCustomBanners'

const SightSeeingPage = () => {
  return (
    <div>
        <SightseeingCustomBanners/>
    </div>
  )
}

export default SightSeeingPage