import React from 'react'
import CustomBanner from './CustomBanner'
import CustomBreadcrumb from '../Contact/CustomBreadcrumb'
import TNCContent from './TNC_Content'

const TermsNConditions = () => {
  return (
    <div>
        <CustomBanner title="Terms &amp; Conditions"/>
        <CustomBreadcrumb
        items={[{ link: "/", text: "Home" }, { text: "Terms & Conditions" }]}
        />
        <TNCContent/>
    </div>
  )
}

export default TermsNConditions