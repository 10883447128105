import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const DContent = () => {
  return (
    <Container>
      <Row className="inner_page_content">
        <Col md={12} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <p
            style={{
              marginLeft: "0in",
              marginRight: "0in",
              textAlign: "justify",
              fontSize: "11pt",
              fontFamily: "Calibri, sans-serif",
            }}>
            We have made every commercially reasonable effort to ensure this
            website contains accurate and correct information. However, we
            cannot warrant the validity, quality and pricing of the products and
            services offered at this website. The information, software,
            products, and services published on this website may include
            inaccuracies or typographical errors. In particular, we or our
            affiliates do not guarantee the accuracy of, and disclaim liability
            for inaccuracies relating to the information and description of the
            tickets (including pricing), airlines (including schedule),
            automobiles and other travel products displayed on this website
            (including, without limitation, photographs, list of hotel
            amenities, general product descriptions, etc.).
          </p>
          <p
            style={{
              marginLeft: "0in",
              marginRight: "0in",
              textAlign: "justify",
              fontSize: "11pt",
              fontFamily: "Calibri, sans-serif",
            }}>
            Neither we nor our affiliates make any representations about the
            suitability of the information, software, products, and services
            contained on this website for any purpose, and the inclusion or
            offering for sale of any products or services on this website does
            not constitute any endorsement or recommendation of such products or
            services by us or our affiliates. All such information, software,
            products, and services are provided "as is" basis without warranty
            of any kind. We and our affiliates hereby disclaim all warranties
            and conditions with regard to this information, software, products,
            and services, including all implied warranties and conditions of
            merchantability, fitness for a particular purpose, title, and
            non-infringement. The carriers, hotels and other suppliers providing
            travel or other services for us are independent contractors and not
            our agents or that of our affiliates. We are not liable for the
            acts, errors, omissions, representations, warranties, breaches or
            negligence of any such suppliers or for any personal injuries,
            death, property damage, or other damages or expenses resulting there
            from.
          </p>
          <p
            style={{
              marginLeft: "0in",
              marginRight: "0in",
              textAlign: "justify",
              fontSize: "11pt",
              fontFamily: "Calibri, sans-serif",
            }}>
            We or our affiliates have no liability and will make no refund in
            the event of any delay, cancellation, overbooking, strike, force
            majeure or other causes beyond our direct control, and have no
            responsibility for any additional expense, omissions, delays,
            re-routing or acts of any government or authority. We have not
            reviewed the sites, downloads, plug-ins, features or advertisements
            linked to this website and are not responsible for the content or
            accuracy of any off-site pages or any other sites linked to this
            website (including without limitation sites linked through
            advertisements or through any search engines). The inclusion of any
            link does not imply that we or our affiliates endorse the linked
            site, and you use the links at your own risk. We do not accept any
            responsibility or liability for any claims arising out of your use
            of these links.
          </p>
          <p
            style={{
              marginLeft: "0in",
              marginRight: "0in",
              textAlign: "justify",
              fontSize: "11pt",
              fontFamily: "Calibri, sans-serif",
            }}>
            Your correspondence or dealings with, or participation in promotions
            of, advertisers on the site are solely between you and such
            advertisers. Neither we nor our affiliates will be liable or
            responsible for any loss or damage of any sort incurred as the
            result of any such dealings or as the result of the presence of
            advertisements on this website.
          </p>
          <p
            style={{
              marginLeft: "0in",
              marginRight: "0in",
              textAlign: "justify",
            }}>
            &nbsp;
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default DContent;
