import React from "react";
import { Accordion, Card, Container, Row, Col } from 'react-bootstrap';


const FAQContent = () => {
  return (
    <div className="inner_page">
      <section>
        <Container>
          <div className="section-content">
            <Row>
              <Col>
                <div className="container">
                  <Row>
                    <Col>
                      <div className="inner_contact">
                        <div className="clearfix"></div>
                        <Col sm={12} className="cus_collpse">
                          <Accordion /*flush*/>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                1. For domestic sectors, which airlines are
                                offered as Full Service airlines?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  Jet Airways (9W), Air India (AI), and Air
                                  Vistara (UK) are offered as full-service
                                  airlines. They offer full services including
                                  free in-flight meals.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                2. For domestic sectors, which airlines are
                                offered as "Low Cost Carriers"?
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    Low Cost Carriers presently include SpiceJet
                                    (SG), IndiGo (6E), GoAir (G8), and Air
                                    Asia(I5).
                                  </li>
                                  <li>
                                    Low-cost Carriers are known for their lower
                                    fares and the exclusion of services such as
                                    free in-flight meals.
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                3. What is an airline PNR Number?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  An airline PNR is the Passenger Name Record,
                                  and is the reference for the particular
                                  booking that is logged in the Airline
                                  Reservation System. The airlines will be able
                                  to assist you with the help of this number.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                4. What is meant by direct flights?
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    Direct Flights are where two cities are
                                    connected by a single aircraft. Thus flights
                                    with none, one or more intermediate stops,
                                    but with no change of aircraft are known as
                                    direct flights.
                                  </li>
                                  <li>
                                    Direct Non-stop flights are generally the
                                    shortest in duration followed by direct
                                    flights with stops.
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                5. What are indirect flights?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  Indirect flights are those where transit via
                                  intermediate cities are involved. Indirect
                                  flights involve change of aircraft. No break
                                  of journey is permitted.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                6. What are stopovers?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  If the duration of transit is longer than 48
                                  hours and thus involving a break in journey,
                                  is termed a stopover. The cheapest
                                  international fares restrict en route
                                  stopovers. Higher fare levels may permit
                                  stopovers.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                              <Accordion.Header>
                                7. Can I make a group booking through your site?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  At the moment, a maximum of 7 people can be
                                  included in the same ticket. For making a bulk
                                  or group booking, contact airlines.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                              <Accordion.Header>
                                8. Can I book and hold a reservation and pay
                                later?
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  No. Hold reservation is not possible through
                                  our website.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Col>
                        <div className="clearfix"></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default FAQContent;
