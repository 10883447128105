// transferMarkupData.js
import { useState } from 'react';

const initialData = [
  {
    id: 175,
    amountType: "percentage",
    amount: "100",
    date: "13 02 2023 20:54 PM",
    editLink: "/agent/transfermarkup/edit/175",
  },
  {
    id: 176,
    amountType: "percentage",
    amount: "200",
    date: "14 02 2023 20:54 PM",
    editLink: "/agent/transfermarkup/edit/176",
  },
];

export const useTransferMarkupData = () => {
  const [transferMarkupData, setTransferMarkupData] = useState(initialData);

  const addTransferMarkup = (newMarkupData) => {
    const updatedData = transferMarkupData.push(newMarkupData);
    setTransferMarkupData(updatedData);
  };

  const deleteTransferMarkup = (id) => {
    const updatedData = transferMarkupData.filter(item => item.id !== id);
    setTransferMarkupData(updatedData);
  };

  const updateTransferMarkup = (updatedMarkupData) => {
    setTransferMarkupData(updatedMarkupData);
  };

  return { transferMarkupData, addTransferMarkup, deleteTransferMarkup, updateTransferMarkup };
};
