// hotelMarkupData.js
import { useState } from 'react';

const initialData = [
  {
    id: 165,
    amountType: "percentage",
    amount: "10",
    date: "13 02 2023 20:54 PM",
    editLink: "/agent/hotelmarkup/edit/165",
  },
  {
    id: 166,
    amountType: "percentage",
    amount: "20",
    date: "14 02 2023 20:54 PM",
    editLink: "/agent/hotelmarkup/edit/166",
  },
];

export const useHotelMarkupData = () => {
  const [hotelMarkupData, setHotelMarkupData] = useState(initialData);

  const addHotelMarkup = (newMarkupData) => {
    const updatedData = hotelMarkupData.push(newMarkupData);
    setHotelMarkupData(updatedData);
  };

  const deleteHotelMarkup = (id) => {
    const updatedData = hotelMarkupData.filter(item => item.id !== id);
    setHotelMarkupData(updatedData);
  };

  const updateHotelMarkup = (updatedMarkupData) => {
    setHotelMarkupData(updatedMarkupData);
  };

  return { hotelMarkupData, addHotelMarkup, deleteHotelMarkup, updateHotelMarkup };
};
