import React from "react";
import "./PluginsSection.css";
import { Col, Row } from "react-bootstrap";
import {
  FaCheck,
  FaGlobe,
  FaMobileAlt,
  FaHeadset,
  FaLock,
  FaCalendarAlt,
  FaUserFriends,
  FaAward,
  FaMoneyBillWave,
  FaThumbsUp,
  FaCode,
} from "react-icons/fa";

const PluginsSection = () => {
  const services = [
    {
      name: "Hotel Booking",
      description: "Book hotels for your stay.",
      icon: "https://tripoholidays.com/public/img/hotel-icon.png",
    },
    {
      name: "Transfers",
      description: "Arrange transportation for your travels.",
      icon: "https://tripoholidays.com/public/img/taxi-icon.png",
    },
    {
      name: "Sightseeing",
      description: "Explore local attractions and landmarks.",
      icon: "https://tripoholidays.com/public/img/binocular-icon.png",
    },
    {
      name: "Tour Packages",
      description: "Discover curated tour packages for your trip.",
      icon: "https://tripoholidays.com/public/img/bag-icon.png",
    },
  ];

  const plugins = [
    {
      name: "Easy Customization",
      description: "Tailor your booking experience to suit your needs.",
      icon: <FaGlobe />,
    },
    {
      name: "Responsive Design",
      description: "Enjoy seamless booking on any device, from anywhere.",
      icon: <FaMobileAlt />,
    },
    {
      name: "24/7 Support",
      description: "Get assistance whenever you need it, day or night.",
      icon: <FaHeadset />,
    },
    {
      name: "Secure Payment",
      description: "Rest assured that your payments are safe and secure.",
      icon: <FaLock />,
    },
    {
      name: "Flexible Booking Options",
      description:
        "Choose from a variety of booking options that fit your schedule.",
      icon: <FaCalendarAlt />,
    },
    {
      name: "User-Friendly Interface",
      description:
        "Easily navigate our platform with a simple and intuitive interface.",
      icon: <FaUserFriends />,
    },
    {
      name: "Instant Confirmation",
      description:
        "Receive instant confirmation for your bookings, hassle-free.",
      icon: <FaAward />,
    },
    {
      name: "Exclusive Deals",
      description:
        "Access exclusive deals and discounts for our loyal customers.",
      icon: <FaMoneyBillWave />,
    },
    {
      name: "Multiple Payment Methods",
      description:
        "Conveniently pay for your bookings using various payment methods.",
      icon: <FaThumbsUp />,
    },
    {
      name: "Expert Recommendations",
      description:
        "Benefit from expert recommendations tailored to your preferences.",
      icon: <FaCode />,
    },
  ];
  return (
    <div
      id="plugins"
      className="nicdark_section nicdark_display_none_all_responsive pluginpg"
    >
      {/* <div className="nicdark_section nicdark_height_80"></div> */}
      <div className="nicdark_container nicdark_clearfix">
        <Col md={12} className="grid grid_12 nicdark_text_align_center nicdark_position_relative">
          {/* <h1
            style={{
              zIndex: -1,
              left: "50%",
              marginLeft: "-290px",
              top: "30px",
              color: "#f7f7f7",
            }}
            className="nicdark_position_absolute nicdark_top_0 nicdark_left_0 nicdark_third_font nicdark_font_weight_lighter nicdark_font_size_180"
          >
            Additional Info
          </h1> */}
          <h1 className="nicdark_letter_spacing_1 nicdark_font_size_50">
            The <span style={{ color: "#a1c849" }}>BEST</span> Compatibility
          </h1>
        </Col>

        <div className="nicdark_section nicdark_height_40"></div>

        <Row>
          <Col md={6} className="grid grid_6">
            <div className="nicdark_section nicdark_height_5"></div>
            <div className="nicdark_display_table">
              <div className="nicdark_display_table_cell nicdark_vertical_align_middle">
                <img
                  className="nicdark_padding_right_10"
                  width="30"
                  src="http://www.nicdarkthemes.com/themes/hotel/wp/demo/intro/img/icons/ico-check.png"
                  alt=""
                />
              </div>
              <div className="nicdark_display_table_cell nicdark_vertical_align_middle">
                <h3 className="nicdark_font_size_16 nicdark_letter_spacing_2 nicdark_padding_left_10 nicdark_text_transform_uppercase nicdark_color_grey">
                  Wide Range of Accommodation Options
                </h3>
              </div>
            </div>
            <div className="nicdark_section nicdark_height_20"></div>
            <div className="nicdark_display_table">
              <div className="nicdark_display_table_cell nicdark_vertical_align_middle">
                <img
                  className="nicdark_padding_right_10"
                  width="30"
                  src="http://www.nicdarkthemes.com/themes/hotel/wp/demo/intro/img/icons/ico-check.png"
                  alt=""
                />
              </div>
              <div className="nicdark_display_table_cell nicdark_vertical_align_middle">
                <h3 className="nicdark_font_size_16 nicdark_letter_spacing_2 nicdark_padding_left_10 nicdark_text_transform_uppercase nicdark_color_grey">
                  Personalized Travel Itineraries
                </h3>
              </div>
            </div>
            <div className="nicdark_section nicdark_height_30"></div>
            <p>
              Here are some additional reasons to choose our services and
              benefits you'll enjoy.
            </p>
          </Col>

          <Col md={6} className="grid grid_6 nicdark_text_align_center">
            <Row
              style={{ backgroundColor: "#a1c849" }}
              className="nicdark_section nicdark_padding_40 nicdark_box_sizing_border_box"
            >
              {services.map((service, index) => (
                <Col xl={3} lg={3} md={3} sm={6} xs={12} className="nicdark_float_left nicdark_width_25_percentage">
                  <img width="38" src={service.icon} alt="" />
                  <div className="nicdark_section nicdark_height_15 d-none d-lg-block"></div>
                  <h6 className="nicdark_font_size_12 nicdark_letter_spacing_2 nicdark_color_white nicdark_line_height_22">
                    {service.description}
                  </h6>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        {/* Rest of the content */}
        <Row>
          {plugins.map((plugin, index) => (
            <Col xs={12} sm={6} md={4} className="grid grid_4">
              <div className="nicdark_section nicdark_position_relative">
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "#a1c849",
                    top: "5px",
                    alignContent:"center"
                  }}
                  className="nicdark_position_absolute nicdark_left_0"
                >
                  {/* <img
                    style={{ top: "29px", left: "20px" }}
                    className="nicdark_position_absolute"
                    width="40"
                    src="http://www.nicdarkthemes.com/themes/hotel/wp/demo/intro/img/icons/ico-1.png"
                    alt=""
                  /> */}
                  {plugin.icon}
                </div>
                <div className="nicdark_section nicdark_padding_left_100 nicdark_box_sizing_border_box">
                  <h3 className="nicdark_letter_spacing_1">{plugin.name}</h3>
                  {/* <div className="nicdark_section nicdark_height_15"></div> */}
                  <p className="" style={{letterSpacing:"unset"}}>{plugin.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="nicdark_section nicdark_height_70"></div>
    </div>
  );
};

export default PluginsSection;
