const sightBookingsData = [
    {
      id: 23,
      bookingDate: '2023-12-02 15:00:48',
      packageName: 'Cappadocia: Full-Day Private Cappadocia Tour',
      lang: 'English',
      city: 'Nevsehir',
      departureDate: '2023-12-28',
      status: 'Pending',
      price: '8000',
      detailLink: 'https://tripoholidays.com/agent/bookings/sightseeing/detail/IixDLGAKYAo='
    },
    {
      id: 23,
      bookingDate: '2023-12-02 15:00:48',
      packageName: 'Cappadocia: Full-Day Private Cappadocia Tour',
      lang: 'English',
      city: 'Nevsehir',
      departureDate: '2023-12-28',
      status: 'Pending',
      price: '8000',
      detailLink: 'https://tripoholidays.com/agent/bookings/sightseeing/detail/IixDLGAKYAo='
    },
    {
      id: 23,
      bookingDate: '2023-12-02 15:00:48',
      packageName: 'Cappadocia: Full-Day Private Cappadocia Tour',
      lang: 'English',
      city: 'Nevsehir',
      departureDate: '2023-12-28',
      status: 'Pending',
      price: '8000',
      detailLink: 'https://tripoholidays.com/agent/bookings/sightseeing/detail/IixDLGAKYAo='
    },
    {
      id: 23,
      bookingDate: '2023-12-02 15:00:48',
      packageName: 'Cappadocia: Full-Day Private Cappadocia Tour',
      lang: 'English',
      city: 'Nevsehir',
      departureDate: '2023-12-28',
      status: 'Pending',
      price: '8000',
      detailLink: 'https://tripoholidays.com/agent/bookings/sightseeing/detail/IixDLGAKYAo='
    },
    {
      id: 23,
      bookingDate: '2023-12-02 15:00:48',
      packageName: 'Cappadocia: Full-Day Private Cappadocia Tour',
      lang: 'English',
      city: 'Nevsehir',
      departureDate: '2023-12-28',
      status: 'Pending',
      price: '8000',
      detailLink: 'https://tripoholidays.com/agent/bookings/sightseeing/detail/IixDLGAKYAo='
    },
    
    
  ];
  export default sightBookingsData;