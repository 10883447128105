import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./CustomBreadcrumb.css"; // Import your custom CSS for styling
import { FaAngleRight } from "react-icons/fa";

const CustomBreadcrumb = ({ items }) => {
  return (
    <Container className="breadcrumb_container">
      <div className="cus_breadcrumb">
        <ul>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <li>
                {item.link ? (
                  <Link to={item.link}>{item.text}</Link>
                ) : (
                  <p>{item.text}</p>
                )}
              </li>
              {index !== items.length - 1 && (
                <li>
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
              )}
            </React.Fragment>
          ))}

          {/* // <li><p>Contact Us</p></li> */}
        </ul>
      </div>
    </Container>
  );
};

export default CustomBreadcrumb;
