import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFileExcel, FaSearch } from "react-icons/fa";
import * as XLSX from "xlsx"; // Importing xlsx library


const TransactionLog = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredTransactionData, setFilteredTransactionData] = useState([]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission
    // Filter transaction data based on date range if both fromDate and toDate are selected
    if (fromDate && toDate) {
      const filteredData = transactionData.filter((transaction) => {
        const transactionDate = new Date(transaction.date);
        return transactionDate >= fromDate && transactionDate <= toDate;
      });
      setFilteredTransactionData(filteredData);
    } else {
      // If fromDate or toDate is not selected, display all transaction data
      setFilteredTransactionData(transactionData);
    }
  };

  const exportToExcel = () => {
    const fileName = "Transaction_Log.xlsx";
    const worksheet = XLSX.utils.json_to_sheet(filteredTransactionData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transaction_Log");
    XLSX.writeFile(workbook, fileName);
  };

  const transactionData = [
    {
      id: 1,
      detail: "Transaction 1",
      cr: 100,
      dr: 0,
      balance: 100,
      date: "2024-02-17",
    },
    {
      id: 2,
      detail: "Transaction 2",
      cr: 0,
      dr: 50,
      balance: 50,
      date: "2024-02-18",
    },
    // Add more dummy data objects as needed
  ];

  useState(() => {
    setFilteredTransactionData(transactionData);
  }, []);

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Transaction Log</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Transaction Log</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <Form
                      method="GET"
                      action="https://tripoholidays.com/agent/transaction_log"
                      acceptCharset="UTF-8"
                      name="search-form"
                      autoComplete="off"
                      onSubmit={handleSearch}>
                      <Row>
                        <Col md={4}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="fromdate">From</Form.Label>
                            <DatePicker
                              selected={fromDate}
                              onChange={handleFromDateChange}
                              dateFormat="yyyy-MM-dd"
                              className="form-control"
                              placeholderText="From Date"
                              maxDate={toDate || new Date()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="todate">To</Form.Label>
                            <DatePicker
                              selected={toDate}
                              onChange={handleToDateChange}
                              dateFormat="yyyy-MM-dd"
                              className="form-control"
                              placeholderText="To Date"
                              minDate={fromDate}
                              maxDate={new Date()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} className="text-left">
                          <ul className="mb-0 list-inline">
                            <li className="list-inline-item">
                              <Button
                                type="submit"
                                className="btn btn-theme"
                                fdprocessedid="f44o3c">
                                <FaSearch /> Search
                              </Button>
                            </li>
                            <li className="list-inline-item">
                            <Button
                                className="btn btn-theme"
                                onClick={exportToExcel}>
                                <FaFileExcel /> Export
                              </Button>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Header>
                  <Card.Body className="table-responsive">
                    <table
                      id=""
                      className="table table-bordered table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th className="no-sort">#</th>
                          <th className="no-sort">Detail</th>
                          <th className="no-sort">Cr</th>
                          <th className="no-sort">Dr</th>
                          <th className="no-sort">Balance</th>
                          <th className="no-sort">Date</th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {/* Map through the transaction data */}
                        {filteredTransactionData.map((transaction, index) => (
                          <tr key={index}>
                            <td>{transaction.id}</td>
                            <td>{transaction.detail}</td>
                            <td>{transaction.cr}</td>
                            <td>{transaction.dr}</td>
                            <td>{transaction.balance}</td>
                            <td>{transaction.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default TransactionLog;
