// Header.js
import React from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Button,
  Dropdown,
  DropdownToggle,
} from "react-bootstrap";
import {
  FaBed,
  FaStar,
  FaSuitcaseRolling,
  FaCar,
  FaUser,
  FaSuitcase,
  FaPhoneAlt,
  FaUserTie,
  FaHeadset,
  FaWhatsapp,
} from "react-icons/fa"; // Import Font Awesome icons
import "./Header.css"; // Import Header.css for additional styles
import { FiLogIn } from "react-icons/fi";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      // variant="light"
      className="custom-navbar"
    >
      <Container className="navcontainer">
        <Navbar.Brand as={Link} to="/agent/hotels">
          <img
            src="https://tripoholidays.com/public/img/profile_imgs/20221010_051756_0000_4pPvJXhCle.png"
            alt="Logo"
            className="brand-image"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Dropdown
              // as={Nav.Item}
              // className="nav-link"
              show={dropdownOpen}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <DropdownToggle
                as={Nav.Link}
                // as={Link}
                // className="nav-link"
              >
                <div className="icon-container">
                  <FaUser className="icon" />
                </div>
                <span className="text" style={{display:"inline-block"}}>Our Products</span>{" "}
              </DropdownToggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/agent/hotels">
                  <div className="icon-container">
                    <FaBed className="icon" />
                  </div>
                  <span className="text">Hotel</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/agent/sightseeing">
                  <div className="icon-container">
                    <FaStar className="icon" />
                  </div>
                  <span className="text">Sightseeing</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/agent/tour">
                  <div className="icon-container">
                    <FaSuitcaseRolling className="icon" />
                  </div>
                  <span className="text">Tour Packages</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/agent/transfers">
                  <div className="icon-container">
                    <FaCar className="icon" />
                  </div>
                  <span className="text">Transfers</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link as={Link} to="/about" className="nav-link">
              <div className="icon-container">
                <FaUser className="icon" />
              </div>
              <span className="text">About Us</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/career" className="nav-link">
              <div className="icon-container">
                <FaSuitcase className="icon" />
              </div>
              <span className="text">Careers</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-link">
              <div className="icon-container">
                <FaPhoneAlt className="icon" />
              </div>
              <span className="text">Contact Us</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className="buttons-container">
          {/* <Button className="mr-2"> */}
          <Dropdown className="mr-2">
            <DropdownToggle>
              <FaHeadset className="btn-icon" />{" "}
              <span className="hidden">Support</span>
            </DropdownToggle>
            <Dropdown.Menu>
              <Dropdown.Item href="tel:1800 212 8892">
                <FaPhoneAlt /> +91 9555446008
              </Dropdown.Item>
              <Dropdown.Item href="tel:+91 9555446008">
                <FaWhatsapp /> +91 9555446008
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/contact">
                <FaPhoneAlt /> Contact us
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* </Button> */}

          <Link to="/agent/login">
            <Button>
              <FiLogIn className="btn-icon" />{" "}
              <span className="hidden">LogIn</span>
            </Button>
          </Link>

          <Link to="/agent-registration">
            <Button>
              <FaUserTie className="btn-icon" />{" "}
              <span className="hidden">Become Partner</span>
            </Button>
          </Link>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="menu-icon"
          />
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
