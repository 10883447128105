import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CustomBanner.css'; 

const CustomBanner = ({ title, imageUrl }) => {

const bannerBeforeStyle={
  backgroundImage: `url(${imageUrl})`,
}

  return (
    <div className="custom_bannerr">
      <div className='banner-before' style={bannerBeforeStyle}></div>
      <div className='banner-overlay'></div>
      <Container>
        <Row>
          <Col sm={12} className='column'>
            <div className="banner_text">
              <div className="title">
                <h3>{title}</h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomBanner;
