import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "./CustomBanners.css";
import { MdDirectionsCar } from "react-icons/md";
import AsyncSelect from "react-select/async";
import {
  FaBed,
  FaCalendarAlt,
  FaSearch,
  FaStar,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { nationalityOptions, cityOptions } from "./Options";

const HotelCustomBanners = () => {
  // const MOBILE_BREAKPOINT = 767;
  // const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
  // console.log(isMobile);

  useEffect(() => {
    // Add event listener for window resize
    const handleResize = () => {
      // Call a function to determine and set breakpoints
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    // Call setBreakpoints initially
    setBreakpoints();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768; // Small screen breakpoint
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992; // Medium screen breakpoint
    // You can define additional breakpoints as needed

    // Set different numberOfMonths based on screen size
    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30); // For small screens, show 1 month
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60); // For medium screens, show 2 months
    } else {
      setDateRangeConfig(2, 40, 50); // For larger screens, show 3 months
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize, verticalSpacing) => {
    // Set the number of months to show in the DateRangePicker
    setNumberOfMonths(numberOfMonths);
    setDaySize(daysize);
    setVaerticalSpacing(verticalSpacing);
  };

  const [numberOfMonths, setNumberOfMonths] = useState(2); // Default number of months
  const [daysize, setDaySize] = useState(40); // Default number of months
  const [verticalSpacing, setVaerticalSpacing] = useState(50); // Default number of months

  const [nationality, setNationality] = useState("");
  const handleNationalityChange = (e) => {
    setNationality(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const [dateRange, setDateRange] = useState({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const [hoveredDate, setHoveredDate] = useState(null); // Define hoveredDate state


  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(false);

  const fetchCities = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = cityOptions.filter((city) =>
        city.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions.map((city) => ({ value: city, label: city })));
    });
  };

  useEffect(() => {
    fetchCities("").then((data) => setOptions(data));
  }, []);

  const [rooms, setRooms] = useState([{ adults: 2, children: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);

  const addRoom = () => {
    if (rooms.length < 6) {
      setRooms([...rooms, { adults: 2, children: 0 }]);
    }
  };

  const removeRoom = (index) => {
    if (rooms.length > 1) {
      const updatedRooms = [...rooms];
      updatedRooms.splice(index, 1);
      setRooms(updatedRooms);
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const navigate = useNavigate();

  const handleHotelSearch = (e) => {
    e.preventDefault();
    // Perform any necessary form validation or data processing here

    // Navigate to "/agent/hotellist"
    navigate("/agent/hotellist");
  };

  return (
    <section className="custom_banner" id="banner">
      <div className="banner-parallax">
        <div
          className="bg-element"
          data-stellar-background-ratio="0.2"
          style={{
            backgroundImage:
              "url(https://tripoholidays.com/public/images/hotel-banner-bg.jpg)",
          }}></div>
        <div className="banner_search hotel_banner">
          <Container className="conn">
            <Row>
              <Col md={12}>
                <div className="banner_search_box">
                  <div className="section-tab">
                    <ul>
                      <li className="list_item active">
                        <Link to="/agent/hotels">
                          <FaBed className="tabicon" />{" "}
                          <span className="d-none d-lg-block">Hotel</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/sightseeing">
                          <FaStar className="tabicon" />
                          <span className="d-none d-lg-block">Sightseeing</span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/tour">
                          <FaSuitcaseRolling className="tabicon" />
                          <span className="d-none d-lg-block">
                            Tour Package
                          </span>
                        </Link>
                      </li>
                      <li className="list_item">
                        <Link to="/agent/transfers">
                          <MdDirectionsCar className="tabicon" />
                          <span className="d-none d-lg-block">Transfer</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="tab_content">
                    <div className="custom_tabs_sec">
                      <div className="custom_form_action">
                        {" "}
                        <Form
                          onSubmit={handleSubmit}
                          className="booking_search_form hotel_booking_search">
                          <div className="inner_form_action">
                            <div className="cus_field location_field hotel_location_field searchcity">
                              <div className="input-box">
                                <label
                                  htmlFor="fromCity"
                                  className="label-text">
                                  <span className="span_label sel_type">
                                    City
                                  </span>
                                  {/* <input
                                    name="onewayfromtext"
                                    className="form-control"
                                    type="text"
                                    placeholder="Select City or Hotel"
                                  /> */}
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={fetchCities}
                                    loadingMessage={() => "Loading..."}
                                    noOptionsMessage={() => "No cities found"}
                                    placeholder="Select City or Hotel"
                                    options={options}
                                    value={selectedOption}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    onChange={(option) => {
                                      setSelectedOption(option);
                                    }}
                                  />
                                </label>
                              </div>
                            </div>

                            <div className="cus_field calendar_field checkinout_field">
                              <div className="merge_field">
                                <div className="input-box input_chkin">
                                  <DateRangePicker
                                    startDate={dateRange.startDate}
                                    startDateId="start_date"
                                    endDate={dateRange.endDate}
                                    endDateId="end_date"
                                    onDatesChange={handleDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={(focusedInput) =>
                                      setFocusedInput(focusedInput)
                                    }
                                    showClearDates={false}
                                    isOutsideRange={(day) =>
                                      day.isBefore(moment(), "day")
                                    } // Disable days before today
                                    small
                                    verticalSpacing={verticalSpacing}
                                    numberOfMonths={numberOfMonths}
                                    daySize={daysize}
                                    onDayMouseEnter={(day) =>
                                      setHoveredDate(day)
                                    }
                                    onDayMouseLeave={() => setHoveredDate(null)}
                                    dayPickerProps={{
                                      modifiers: {
                                        hovered: hoveredDate,
                                        // Add more modifiers if needed
                                      },
                                    }}
                                  />
                                  <label
                                    htmlFor="checkin"
                                    className="label-text negmt"
                                    onClick={() =>
                                      setFocusedInput("startDate")
                                    }>
                                    <span className="span_label">
                                      <FaCalendarAlt /> Check-In
                                    </span>
                                    <p
                                      className="sr_date checkin mydepdate"
                                      style={{ fontSize: "30px" }}>
                                      {dateRange.startDate
                                        ? dateRange.startDate.format(
                                            "DD MMM 'YY"
                                          )
                                        : "Select Date"}
                                    </p>
                                  </label>
                                </div>
                                <div className="cus_space"></div>
                                <div className="no_of_night">
                                  <span>
                                    {dateRange.startDate && dateRange.endDate
                                      ? dateRange.endDate.diff(
                                          dateRange.startDate,
                                          "days"
                                        )
                                      : "0"}
                                  </span>{" "}
                                  Night
                                </div>
                                <div className="input-box flo_rgt input_chkout">
                                  <label
                                    htmlFor="checkout"
                                    className="label-text"
                                    onClick={() => setFocusedInput("endDate")}>
                                    <span className="span_label">
                                      <FaCalendarAlt /> Check-Out
                                    </span>
                                    <p
                                      className="sr_date checkout myretdate"
                                      style={{ fontSize: "30px" }}>
                                      {dateRange.endDate
                                        ? dateRange.endDate.format("DD MMM 'YY")
                                        : "Select Date"}
                                    </p>
                                  </label>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <input
                                type="hidden"
                                value={
                                  dateRange.startDate
                                    ? dateRange.startDate.format("DD/MM/YYYY")
                                    : ""
                                }
                                id="departuredate"
                              />
                              <input
                                type="hidden"
                                value={
                                  dateRange.endDate
                                    ? dateRange.endDate.format("DD/MM/YYYY")
                                    : ""
                                }
                                id="returndate"
                              />
                            </div>

                            <div className="cus_field traveler_field cus_passenger_field">
                              <div className="input-box">
                                <label
                                  id="travelersdetail"
                                  htmlFor="travellers"
                                  className="label-text"
                                  onClick={() =>
                                    setLabelClicked(!labelClicked)
                                  }>
                                  <span className="span_label">
                                    Rooms / Guests
                                  </span>
                                  {/* <input
                                    className="form-control show-dropdown-passengers roundpessanger"
                                    id="guest"
                                    autoComplete="off"
                                    name="guest"
                                    type="text"
                                    readOnly
                                  /> */}
                                  <p className="sr_travel">
                                    <span id="guestcount">
                                      {rooms.reduce(
                                        (total, room) =>
                                          total + room.adults + room.children,
                                        0
                                      )}
                                    </span>{" "}
                                    <span>Persons in</span>{" "}
                                    <span id="guestroom">{rooms.length}</span>{" "}
                                    <span>
                                      {rooms.length === 1 ? "Room" : "Rooms"}
                                    </span>
                                  </p>
                                </label>
                                <div
                                  className="onlytraveller normaltraveller"
                                  style={{
                                    display: labelClicked ? "block" : "none",
                                    maxHeight: "460px",
                                    overflow: "auto",
                                    scrollbarWidth: "thin",
                                  }}>
                                  <ul className="traveller_list">
                                    {rooms.map((room, index) => (
                                      <li>
                                        <div className="list-persons-count">
                                          <div id="roomshtml">
                                            <div
                                              className="box"
                                              key={`divroom${index + 1}`}
                                              id={`divroom${index + 1}`}>
                                              <div className="roomTxt">
                                                <span>Room {index + 1}:</span>
                                              </div>
                                              <div className="left pull-left">
                                                <span className="txt">
                                                  <span id="Label7">
                                                    Adult{" "}
                                                    <em>(Above 12 years)</em>
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="right pull-right">
                                                <div
                                                  id="field1"
                                                  className="PlusMinusRow">
                                                  <a
                                                    type="button"
                                                    id="Adults_room_1_1_minus"
                                                    className="sub hoteladultclass"
                                                    onClick={() =>
                                                      updateRoom(
                                                        index,
                                                        "adults",
                                                        Math.max(
                                                          room.adults - 1,
                                                          1
                                                        )
                                                      )
                                                    }>
                                                    -
                                                  </a>
                                                  <span
                                                    id="Adults_room_1_1"
                                                    className="PlusMinus_number">
                                                    {room.adults}
                                                  </span>
                                                  <a
                                                    type="button"
                                                    id="Adults_room_1_1_plus"
                                                    className="add hoteladultclass"
                                                    onClick={() =>
                                                      updateRoom(
                                                        index,
                                                        "adults",
                                                        Math.min(
                                                          room.adults + 1,
                                                          6
                                                        )
                                                      )
                                                    }>
                                                    +
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="spacer"></div>
                                              <div className="left pull-left">
                                                <span className="txt">
                                                  <span id="Label9">
                                                    Child{" "}
                                                    <em>(Below 12 years)</em>
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="right pull-right">
                                                <div
                                                  id="field2"
                                                  className="PlusMinusRow">
                                                  <a
                                                    type="button"
                                                    id="Children_room_1_1_minus"
                                                    className="sub hotelchildclass"
                                                    onClick={() =>
                                                      updateRoom(
                                                        index,
                                                        "children",
                                                        Math.max(
                                                          room.children - 1,
                                                          0
                                                        )
                                                      )
                                                    }>
                                                    -
                                                  </a>
                                                  <span
                                                    id="Children_room_1_1"
                                                    className="PlusMinus_number">
                                                    {room.children}
                                                  </span>
                                                  <a
                                                    type="button"
                                                    id="Children_room_1_1_plus"
                                                    className="add hotelchildclass"
                                                    onClick={() =>
                                                      updateRoom(
                                                        index,
                                                        "children",
                                                        Math.min(
                                                          room.children + 1,
                                                          6
                                                        )
                                                      )
                                                    }>
                                                    +
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="clear"></div>
                                              {room.children > 0 && (
                                                <div className="childresAgeTxt">
                                                  Age(s) of Children
                                                </div>
                                              )}
                                              {room.children > 0 &&
                                                [...Array(room.children)].map(
                                                  (_, childIndex) => (
                                                    <select key={childIndex}>
                                                      {[...Array(12)].map(
                                                        (age, index) => (
                                                          <option
                                                            key={index + 1}>
                                                            {index + 1}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  )
                                                )}
                                              <div className="clear"></div>
                                            </div>
                                          </div>

                                          <a
                                            id="addhotelRoom"
                                            href="#"
                                            className="cus_add_remove_btn addroom"
                                            style={{
                                              display:
                                                index === rooms.length - 1
                                                  ? "inline-block"
                                                  : "none",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              addRoom();
                                            }}>
                                            Add Room
                                          </a>
                                          <a
                                            id="removehotelRoom"
                                            href="#"
                                            className="cus_add_remove_btn removeroom"
                                            style={{
                                              display:
                                                rooms.length > 1
                                                  ? "inline-block"
                                                  : "none",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              removeRoom(index);
                                            }}>
                                            Remove Room
                                          </a>
                                          <a
                                            className="apply_btn"
                                            href="javascript:;"
                                            style={{
                                              display:
                                                index === rooms.length - 1
                                                  ? "inline-block"
                                                  : "none",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setLabelClicked(false); // Hide the onlytraveller or normaltraveller div
                                            }}>
                                            Done
                                          </a>

                                          <input
                                            type="hidden"
                                            id="hdnroom"
                                            value="1"
                                          />
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="booking_search_btn">
                            <div className="search_btn">
                              <div className="row">
                                <div
                                  className="col-md-9"
                                  style={{ textAlign: "center" }}>
                                  <Button
                                    type="submit"
                                    className="cus_btn btn_gradient roundformsearch"
                                    onClick={handleHotelSearch}
                                    style={{ marginBottom: "5px" }}>
                                    <FaSearch style={{ marginRight: "5px" }} />
                                    Search Hotel
                                  </Button>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    as="select"
                                    id="nationalitydrop"
                                    onChange={handleNationalityChange}>
                                    {nationalityOptions.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default HotelCustomBanners;
