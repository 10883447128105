import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './CareerPage.css';

const CareerPage = () => {
  return (
    <div className="inner_page career_page">
      <section>
        <Container>
          <Row className="d-flex align-items-center-lg flex-wrap">
            <Col md={6} className='column6'>
              <h3><strong>Unlock Your Passion for Travel at Tripoholidays: Join Our Journey!</strong></h3>
              <p>At Tripoholidays, we don't just plan vacations; we craft unforgettable adventures, create lifelong memories, and ignite wanderlust in the hearts of our travelers. If you share our passion for exploring the world, connecting with cultures, and transforming ordinary trips into extraordinary journeys, then we want you to be a part of our travel-loving family.</p>
            </Col>
            <Col md={6} className='column6'>
              <img className="shadow_img" src="https://tripoholidays.com/public/images/join-our-team.jpg" alt="Join Us" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-light">
        <Container>
          <h3 className="text-center"><strong>Why Choose a Career at Tripoholidays?</strong></h3>
          <Row>
            <Col md={4}>
              <div className="career_card">
                <img src="https://tripoholidays.com/public/images/adventurer.png" alt="Join Us" />
                <h5>Endless Exploration</h5>
                <p>Working at Tripoholidays means you'll embark on a never-ending adventure. Every day is an opportunity to learn about new destinations, cultures, and travel trends, making your work a continuous exploration of the world.</p>
              </div>
            </Col>

            <Col md={4}>
              <div className="career_card">
                <img src="https://tripoholidays.com/public/images/passionate.png" alt="Join Us" />
                <h5>Passion-Driven Culture</h5>
                <p>Our team is made up of travel enthusiasts, storytellers, and dream-weavers. We believe that the key to delivering exceptional experiences to our clients is fostering a work environment.</p>
              </div>
            </Col>

            <Col md={4}>
              <div className="career_card">
                <img src="https://tripoholidays.com/public/images/global-connection.png" alt="Join Us" />
                <h5>Global Connections</h5>
                <p>With clients and partners worldwide, we're in the business of connecting people and places. You'll be at the heart of a vast network of international relationships, expanding your own global horizons.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <div className="text-center">
            <h3><strong>Join Us and Make Your Mark</strong></h3>
            <p>If you're ready to turn your passion for travel into a rewarding career, Tripoholidays is the place to be. Come join our journey, where every day is an adventure, every task is a step towards a new destination, and every traveler's smile is a testament to your dedication.</p>
            <p>Discover your potential at Tripoholidays, and together, we'll explore the world, one extraordinary trip at a time.</p>
            <p><em>Begin your journey with Tripoholidays - Where Travel Dreams Become Reality!</em></p>
          </div>

          <div className="career_form">
            <h4>Join Our Team</h4>
            <Form>
              <Form.Group controlId="formName" className='form-group'>
                <Form.Label>Name <sup className="text-danger">*</sup></Form.Label>
                <Form.Control type="text" placeholder="Your Name" />
              </Form.Group>

              <Form.Group controlId="formEmail" className='form-group'>
                <Form.Label>Email <sup className="text-danger">*</sup></Form.Label>
                <Form.Control type="email" placeholder="Your Email" />
              </Form.Group>

              <Form.Group controlId="formPhoneNumber" className='form-group'>
                <Form.Label>Phone Number <sup className="text-danger">*</sup></Form.Label>
                <Form.Control type="number" placeholder="Your Number" />
              </Form.Group>

              <Form.Group controlId="formCV" className='form-group'>
                <Form.Label>CV <sup className="text-danger">*</sup></Form.Label>
                <Form.Control type="file" />
              </Form.Group>

              <Form.Group controlId="formPost" className='form-group'>
                <Form.Label>Post Applying For <sup className="text-danger">*</sup></Form.Label>
                <Form.Control as="select">
                  <option value="">Choose Job Post</option>
                  <option value="manager">Manager</option>
                  <option value="hr">HR</option>
                  <option value="salesexecutive">Sales Executive</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formTellMore" className='form-group'>
                <Form.Label>Tell us more</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Tell something about yourself" />
              </Form.Group>

              <Button variant="success" type="submit">Submit</Button>
            </Form>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CareerPage;
