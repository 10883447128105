import React from "react";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TripDetailsSection = ({ packageName }) => {
  return (
    <section id="description">
      <Row>
      <Col sm={12} className="slidersection gallery_section flex_col">
        {/* React Responsive Carousel */}
        <Carousel id="pack_carousel">
          {packageName.package_gallery.map((image, index) => (
            <div key={index}>
              <img
                className="d-block w-100"
                src={image.image}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Carousel>
      </Col>
    </Row>
      <Row>
        <Col sm={12} className="flex_col">
          <div className="trip_inclusion">
            <ul className="bullets">
              {packageName.package_topinclusions.map((tag) => (
                <li key={tag.id}>
                  <img width="20" height="20" src={tag.image} alt={tag.name} />
                  {tag.name}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default TripDetailsSection;
