import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Breadcrumb,
  Dropdown,
  Pagination,
  Form,
} from "react-bootstrap";
import { FaEye, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";

// CardCustomMenu component
const CardCustomMenu = ({ toggleFilter }) => {
  return (
    <div className="full-container">
      <div>
        {/* <ul className="custom-menu-container">
          <li className="active">
            <Link to="/agent/bookings/hotel">Hotel</Link>
          </li>
          <li>
            <Link to="/agent/bookings/tour">Package</Link>
          </li>
          <li>
            <Link to="/agent/bookings/sightseeing">Sightseeing</Link>
          </li>
          <li>
            <Link to="/agent/bookings/transfer">Transfer</Link>
          </li>
        </ul> */}
      </div>
      <div>
        <Button
          variant="theme"
          size="sm"
          className="filter_btn"
          onClick={toggleFilter}>
          <FaFilter className="filter-icon" /> Filter
        </Button>
      </div>
    </div>
  );
};

// Filter component
const Filter = ({ onFilter }) => {
  const [filters, setFilters] = useState({
    bookId: "",
    amdId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter(filters);
  };

  return (
    <div className="filter_panel">
      <h4>Filter By Details</h4>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="type" value="b2c" />
        <Row>
        <Col md={6}>
            <Form.Group>
              <Form.Label>Book ID</Form.Label>
              <Form.Control
                autoComplete="off"
                placeholder="Enter Book ID"
                name="bookId"
                type="text"
                value={filters.bookId}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>AMD ID</Form.Label>
              <Form.Control
                autoComplete="off"
                placeholder="Enter AMD ID"
                name="amdId"
                type="text"
                value={filters.amdId}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Button
              variant="theme"
              size="md"
              type="submit"
              className="search-filter-btn"
            >
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const SightAmendmentTable = ({ heading, data }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleFilter = (filters) => {
    const { bookId, amdId } = filters;
    const filteredData = data.filter((amendment) => {
      let matchesFilter = true;

      if (bookId && amendment.bookId !== parseInt(bookId)) {
        matchesFilter = false;
      }

      if (amdId && amendment.amdId !== parseInt(amdId)) {
        matchesFilter = false;
      }

      return matchesFilter;
    });

    setFilteredData(filteredData);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">{heading}</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{heading}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <div className="card_custom_menu">
                      <CardCustomMenu toggleFilter={toggleFilter} />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {showFilter && <Filter onFilter={handleFilter} />}
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr>
                              <th className="no-sort sorting_disabled">
                                Book ID
                              </th>
                              <th className="no-sort sorting_disabled">
                                AMD ID
                              </th>
                              <th>AMD Type</th>
                              <th>Booking Type</th>
                              <th>Entry Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {currentEntries.map((amendment, index) => (
                              <tr
                                key={index}
                                role="row"
                                className={index % 2 === 0 ? "odd" : "even"}>
                                <td>{amendment.bookId}</td>
                                <td>{amendment.amdId}</td>
                                <td>{amendment.amdType}</td>
                                <td>{amendment.bookingType}</td>
                                <td>{amendment.entryDate}</td>
                                <td>
                                  <div className="check_status">
                                    <Button
                                      variant="link"
                                      className={`chk_stat_btn ${
                                        amendment.status === "Pending"
                                          ? "yellow_clr"
                                          : amendment.status === "Success"
                                          ? "green_clr"
                                          : "red_clr"
                                      }`}>
                                      {amendment.status}
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {currentEntries.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="6">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>

                    <Col md={12}>
                      {/* Pagination */}
                      <Row>
                        <Col sm={12} md={5}>
                          <div
                            className="dataTables_info"
                            id="invoicetable_info"
                            role="status"
                            aria-live="polite">
                            {filteredData.length > 0
                              ? `Showing ${indexOfFirstEntry + 1} to ${Math.min(
                                  indexOfLastEntry,
                                  filteredData.length
                                )} of ${filteredData.length} entries`
                              : "No data to show"}
                          </div>
                        </Col>
                        <Col sm={12} md={7}>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="invoicetable_paginate">
                            <Pagination>
                              {/* Previous page button */}
                              <Pagination.Prev
                                onClick={() => paginate(currentPage - 1)}
                                disabled={
                                  currentPage === 1 || filteredData.length === 0
                                }>
                                Previous
                              </Pagination.Prev>
                              {/* Page numbers */}
                              {Array.from({ length: totalPages }).map(
                                (_, index) => (
                                  <Pagination.Item
                                    key={index}
                                    active={currentPage === index + 1}
                                    onClick={() => paginate(index + 1)}>
                                    {index + 1}
                                  </Pagination.Item>
                                )
                              )}
                              {/* Next page button */}
                              <Pagination.Next
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                  currentPage === totalPages ||
                                  filteredData.length === 0
                                }>
                                Next
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default SightAmendmentTable;
