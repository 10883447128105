import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LedgerReport.css";
import { FaFileExcel, FaFilePdf, FaPrint, FaRupeeSign } from "react-icons/fa";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const LedgerReport = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [refId, setRefId] = useState("");
  const [filteredLedgerData, setFilteredLedgerData] = useState([]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleSearchByRefId = (event) => {
    event.preventDefault();
    // Logic to filter ledgerData by refId
    const filteredData = ledgerData.filter(
      (ledger) => ledger.refNumber === refId
    );
    setFilteredLedgerData(filteredData);
  };

  const handleSearchByDateRange = (event) => {
    event.preventDefault();
    if (fromDate && toDate) {
      const filteredData = ledgerData.filter((ledger) => {
        const ledgerDate = new Date(ledger.date);
        return ledgerDate >= fromDate && ledgerDate <= toDate;
      });
      setFilteredLedgerData(filteredData);
    } else {
      setFilteredLedgerData(ledgerData);
    }
  };

  // const handleExportToExcel = () => {
  //   const table = document.getElementById("ledgerTablee");
  //   const tableHTML = table.outerHTML;
  //   const blob = new Blob([tableHTML], { type: "application/vnd.ms-excel" });
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "ledger_report.xls");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleExportToExcel = () => {
    const table = document.getElementById("ledgerTablee");
    const wb = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
    XLSX.writeFile(wb, "ledger_report.xlsx");
  };

  const handleExportToPDF = () => {
    const input = document.getElementById("ledgerTablee");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      // pdf.text("Ledger Report", 10, 10); // Add title "Ledger Report" at coordinates (10, 10)
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("ledger_report.pdf");
    });
  };

//   const handleExportToPDF = () => {
//     const table = document.getElementById("ledgerTablee");

//     const pdf = new jsPDF();
//     pdf.text("Ledger Report", 10, 10); // Add title "Ledger Report" at coordinates (10, 10)
    
//     html2canvas(table).then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");
//         const imgWidth = 210;
//         const imgHeight = (canvas.height * imgWidth) / canvas.width;
//         pdf.addImage(imgData, "PNG", 0, 20, imgWidth, imgHeight);
//         pdf.save("ledger_report.pdf");
//     });
// };


  // const handleExportToPDF = () => {
  //   const table = document.getElementById("ledgerTablee");

  //   // Add border styles to the table
  //   table.style.border = "1px solid black";
  //   table.style.borderCollapse = "collapse";
  //   table.style.width = "100%";

  //   const pdf = new jsPDF();
  //   pdf.text("Ledger Report", 10, 10);
  //   html2canvas(table).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const imgWidth = 210;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(imgData, "PNG", 0, 20, imgWidth, imgHeight);
  //     pdf.save("ledger_report.pdf");
  //   });
  // };

  // const handlePrint = () => {
  //   window.print();
  // };

  // const handlePrintTable = () => {
  //   const table = document.getElementById("ledgerTablee").outerHTML;
  //   const newWin = window.open("");
  //   newWin.document.write(table);
  //   newWin.print();
  //   newWin.close();
  // };

  const handlePrintTable = () => {
    const table = document.getElementById("ledgerTablee");
    const tableHTML = table.outerHTML;
    const newWin = window.open("");
    newWin.document.write(`
      <html>
        <head>
          <style>
            /* Add border to table */
            table {
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
            }
          </style>
        </head>
        <body>
          ${tableHTML}
        </body>
      </html>
    `);
    newWin.print();
    newWin.close();
  };

  const ledgerData = [
    {
      date: "2024-02-13",
      refNumber: "12345",
      particulars: "Sale of Product A",
      credit: "1000",
      debit: "0",
      runningBalance: "1000",
    },
    {
      date: "2024-02-16",
      refNumber: "67890",
      particulars: "Sale of Product B",
      credit: "1000",
      debit: "0",
      runningBalance: "2000",
    },
  ];

  useState(() => {
    setFilteredLedgerData(ledgerData);
  }, []);

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Ledger Report</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Ledger Report</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <div className="ledger_report common_report">
                      <p>For Checking Today's transactions</p>
                      <div className="print_export">
                        <ul>
                          <li className="export">
                            <Button onClick={handleExportToExcel}>
                              <FaFileExcel /> Excel Export
                            </Button>
                          </li>
                          <li className="export">
                            <Button onClick={handleExportToPDF}>
                              <FaFilePdf /> PDF Export
                            </Button>
                          </li>
                          <li className="print">
                            <Button
                              // onClick={handlePrint}
                              onClick={handlePrintTable}>
                              <FaPrint /> Print
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="clearfix"></div>
                      <div className="search_ref_id">
                        <Form
                          method="get"
                          action="https://tripoholidays.com/agent/report/ledger"
                          autoComplete="off"
                          onSubmit={handleSearchByRefId}>
                          <Row>
                            <Col sm={4}>
                              <Form.Group className="form-group">
                                <Form.Label>
                                  Search Booking By Reference ID
                                </Form.Label>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="form-group">
                                <Form.Control
                                  type="text"
                                  value={refId}
                                  required=""
                                  name="ref"
                                  placeholder=""
                                  onChange={(e) => setRefId(e.target.value)}
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <div className="ledger_btn text-right">
                                <Button type="submit" className="cus_btn">
                                  Lookup Booking
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                      <div className="ledger_inner">
                        <h4>Refine Your Results</h4>
                        <div className="cus_report_field">
                          <Form
                            method="get"
                            action="https://tripoholidays.com/agent/report/ledger"
                            autoComplete="off"
                            onSubmit={handleSearchByDateRange}>
                            <Row>
                              <Col sm={4}>
                                <Form.Group className="form-group">
                                  <Form.Label>Submission Start Date</Form.Label>
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control commondate"
                                    placeholderText="mm/dd/yyyy"
                                    maxDate={toDate || new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={4}>
                                <Form.Group className="form-group">
                                  <Form.Label>Submission End Date</Form.Label>
                                  <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control commondate"
                                    placeholderText="mm/dd/yyyy"
                                    minDate={fromDate}
                                    maxDate={new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12}>
                                <div className="ledger_btn">
                                  <Button type="submit" className="cus_btn">
                                    Search Order
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <Row>
                          <Col sm={12}>
                            <div className="table-responsive">
                              <Table
                                id="ledgerTablee"
                                bordered
                                hover
                                className="domesticdata leger_table dataTable no-footer">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Ref. Number</th>
                                    <th>Particulars</th>
                                    <th className="text-right">Credit</th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">
                                      Running Balance
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="trbg">
                                    <td colSpan="6">
                                      <span>
                                        <b>Company Name: </b>Trip Navigate,
                                      </span>
                                      <br />
                                      <span>
                                        <b>Company Address: </b>112, 112, Near,
                                        Gurudwara Medical College Road, Sindhi
                                        Colony, Sector-E, Shastri Nagar,
                                        Rajasthan{" "}
                                      </span>
                                      <br />
                                      <span>
                                        <b>Credit Limit:</b> 0.00
                                      </span>{" "}
                                      <br />
                                      <span>
                                        <b>Cash A/c Balance:</b> 2000.00
                                      </span>
                                      <br />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="3"
                                      style={{ textAlign: "right" }}>
                                      Opening Balance
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        fontWeight: 600,
                                      }}>
                                      ₹2000
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        fontWeight: 600,
                                      }}>
                                      ₹
                                    </td>
                                    <td></td>
                                  </tr>
                                  {filteredLedgerData.map((ledger, index) => (
                                    <tr key={index}>
                                      <td>{ledger.date}</td>
                                      <td>{ledger.refNumber}</td>
                                      <td>{ledger.particulars}</td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                          color: "green",
                                        }}>
                                        {ledger.credit}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                          color: "red",
                                        }}>
                                        {ledger.debit}
                                      </td>
                                      <td className="text-right">
                                        <FaRupeeSign />
                                        <b>{ledger.runningBalance}</b>
                                      </td>
                                    </tr>
                                  ))}{" "}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default LedgerReport;
