import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Breadcrumb,
  Dropdown,
  Form,
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useSightMarkupData } from "./SightMarkupData";
import { Link, useNavigate } from "react-router-dom";

const CardCustomMenu = () => {
  return (
    <div className="full-container">
      <div>
        <ul className="custom-menu-container">
          <li>
            <Link to="/agent/hotelmarkup">Hotel</Link>
          </li>
          <li>
            <Link to="/agent/packagemarkup">Package</Link>
          </li>
          <li className="active">
            <Link to="/agent/sightseeingmarkup">Sightseeing</Link>
          </li>
          <li>
            <Link to="/agent/transfermarkup">Transfer</Link>
          </li>
        </ul>
      </div>
      <div>
        <Button
          variant="theme"
          size="md"
          className="filter_btn"
          as={Link}
          to="/agent/sightseeingmarkup/create">
          <FaPlus /> New Markup
        </Button>
      </div>
    </div>
  );
};

const SightMarkupTable = () => {
  const { sightMarkupData, deleteSightMarkup, updateSightMarkup } =
    useSightMarkupData();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  useEffect(() => {

    const initialSelectedCheckboxes = {};
    sightMarkupData.forEach((_, index) => {
      initialSelectedCheckboxes[index] = false;
    });
    setSelectedCheckboxes(initialSelectedCheckboxes);
  }, [sightMarkupData]);

  const toggleSelectAll = () => {
    const newSelectedCheckboxes = {};
    if (Object.values(selectedCheckboxes).every((value) => value)) {
      Object.keys(selectedCheckboxes).forEach((key) => {
        newSelectedCheckboxes[key] = false;
      });
    } else {
      sightMarkupData.forEach((_, index) => {
        newSelectedCheckboxes[index] = true;
      });
    }
    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const toggleCheckbox = (index) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [amount, setAmount] = useState("");
  const [serviceType, setServiceType] = useState("");

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const handleUpdateSelected = (e) => {
    e.preventDefault();
    const updatedData = sightMarkupData.map((booking, index) => {
      if (selectedCheckboxes[index]) {
        const updatedAmount = amount ? amount : booking.amount;
        const updatedServiceType = serviceType
          ? serviceType
          : booking.amountType;
        return {
          ...booking,
          amount: updatedAmount,
          amountType: updatedServiceType,
        };
      }
      return booking;
    });
    updateSightMarkup(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleDelete = (id) => {
    deleteSightMarkup(id);
  };

  const navigate = useNavigate();
  const handleEdit = (editLink) => {
    navigate(editLink);
  };

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Manage Markup</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Manage Markup</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <div className="card_custom_menu">
                      <CardCustomMenu />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Col md={12}>
                      <div className="commission_amount filter_panel">
                        <Form onSubmit={handleUpdateSelected}>
                          <Row>
                            <Col xs={12} sm={6} md={4}>
                              <Form.Group>
                                <Form.Control
                                  className="mb-md-0 mb-1"
                                  type="text"
                                  placeholder="Amount (Rs.)"
                                  value={amount}
                                  onChange={handleAmountChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                              <Form.Group>
                                <Form.Control
                                  className="mb-md-0 mb-1"
                                  as="select"
                                  value={serviceType}
                                  onChange={handleServiceTypeChange}>
                                  <option value="">Service Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="fixed">Fixed</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={4}
                              className="d-flex justify-content-md-start justify-content-center">
                              <Button
                                type="submit"
                                variant="theme"
                                className="update_btn">
                                Update Selected
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr>
                              <th className="no-sort">
                                <input
                                  type="checkbox"
                                  id="domrcheckedAll"
                                  onChange={toggleSelectAll}
                                  checked={
                                    Object.values(selectedCheckboxes).every(
                                      (value) => value
                                    ) && sightMarkupData.length > 0
                                  }
                                />{" "}
                                Select All
                              </th>
                              <th>Amount Type</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {sightMarkupData.map((booking, index) => (
                              <tr
                                key={index}
                                role="row"
                                id={`id_${booking.id}`}
                                className={index % 2 === 0 ? "odd" : "even"}>
                                <td
                                >
                                  <input
                                    className="checkSingle domesticids"
                                    type="checkbox"
                                    name="allcheckbox[]"
                                    value={booking.id}
                                    onChange={() => toggleCheckbox(index)}
                                    checked={selectedCheckboxes[index] || false}
                                  />
                                </td>
                                <td>{booking.amountType}</td>
                                <td>{`₹ ${booking.amount}.00`}</td>
                                <td>{booking.date}</td>
                                <td>
                                  <div className="nav-item dropdown action_dropdown cus_action_btn">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="primary"
                                        size="sm"
                                        className="action_btn btn btn-primary btn-rounded btn-xs"
                                        id={`dropdown_${booking.id}`}>
                                        Action <span className="caret"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        x-placement="bottom-start"
                                        style={{
                                          position: "absolute",
                                          willChange: "transform",
                                          top: "0px",
                                          left: "0px",
                                          transform:
                                            "translate3d(0px, 24px, 0px)",
                                        }}>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleEdit(booking.editLink)
                                          }>
                                          <FaEdit className="action-icon" />{" "}
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDelete(booking.id)
                                          }>
                                          <FaTrash className="action-icon" />{" "}
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {sightMarkupData.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="6">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default SightMarkupTable;
