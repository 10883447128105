import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { FaAngleLeft, FaAngleRight, FaWhatsapp } from "react-icons/fa";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import "./AmazingDealsCarousel.css";

const AmazingDealsCarousel = () => {
  const [autoWidth, setAutoWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Update autoWidth based on screen width
      if (window.innerWidth > 768) {
        setAutoWidth(true);
      } else {
        setAutoWidth(false);
      }
    };

    // Initial call to handleResize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Options for the Owl Carousel
  const options = {
    // items: 1,
    autoplay: true,
    autoplayHoverPause: false,
    autoplayTimeout: 3000,
    dots: false,
    nav: false,
    // navText: [
    //   <FaAngleLeft className="icon" />,
    //   <FaAngleRight className="icon" />
    // ],
    loop: true,
    // autoWidth: autoWidth,
    autoWidth: false,
    items: 1,
    // fluidSpeed: 100,
    // margin: 15,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },
    //   576: {
    //     items: 2,
    //   },
    // },
  };

  // Sample data for amazing deals
  const amazingDeals = [
    // {
    //   imageUrl:
    //     "https://tripoholidays.com/public/img/cmspage/images (29)_MbOtKUXnbT.jpeg",
    //   alt: "Male",
    //   content: "Male",
    // },
    {
      imageUrl:
        "https://tripoholidays.com/public/img/cmspage/1 (6)_ntLXxugcbi.jpg",
      alt: "News dashboard",
      content: "News dashboard",
    },
    {
      imageUrl:
        "https://tripoholidays.com/public/img/cmspage/4 (3)_YQPgUQQxq1.jpg",
      alt: "Flight",
      content: "Flight",
    },
  ];

  return (
    <Container className="amazing-container">
        <Card className="offer_sec">
          <Card.Header>Amazing Deals</Card.Header>
          <div className="owl-carousel owl-theme agent_other_offer_carousel cus_offer_carousel owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div className="owl-stage"></div>
              <OwlCarousel className="owl-theme" {...options}>
                {amazingDeals.map((deal, index) => (
                  <div key={index} className="item">
                    <div className="offer_item">
                      <img
                        src={deal.imageUrl}
                        alt={deal.alt}
                        // style={{ maxHeight: "235px" }}
                      />
                      {/* <div className="offer_content">
                      <h5>
                        <a href="#">
                          <FaWhatsapp className="icon" />
                        </a>{" "}
                        {deal.content}
                      </h5>
                    </div> */}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
          {/* <div className="owl-nav">
            <div className="owl-prev">
              <FaAngleLeft className="icon" />
            </div>
            <div className="owl-next">
              <FaAngleRight className="icon" />
            </div>
          </div> */}
          <div className="owl-dots disabled"></div>
        </Card>
    </Container>
  );
};

export default AmazingDealsCarousel;
