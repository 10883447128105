import React from "react";
import "./BookingSystem.css";

const BookingSystem = () => {
  const features = [
    {
      number: 1,
      title: "HOTEL BOOKING",
      description: "Find your perfect accommodation",
    },
    {
      number: 2,
      title: "TRANSFER BOOKING",
      description: "Book your transportation hassle-free",
    },
    {
      number: 3,
      title: "SIGHTSEEING",
      description: "Explore exciting destinations",
    },
    {
      number: 4,
      title: "TOUR PACKAGES",
      description: "Discover curated travel experiences",
    },
    {
      number: 5,
      title: "REAL-TIME AVAILABILITY",
      description: "Instant confirmation for your bookings",
    },
  ];

  const rightFeatures = [
    {
      number: 6,
      title: "USER LOGIN",
      description: "Access your account easily",
    },
    {
      number: 7,
      title: "REGISTRATION",
      description: "Join our community and start booking",
    },
    {
      number: 8,
      title: "SECURE PAYMENT",
      description: "Multiple payment options for peace of mind",
    },
    {
      number: 9,
      title: "USER PROFILE",
      description: "Manage your bookings and preferences",
    },
    {
      number: 10,
      title: "NOTIFICATIONS",
      description: "Stay informed with booking updates",
    },
  ];

  return (
    <div
      className="nicdark_section nicdark_position_relative nicdark_display_none_all_responsive d-none d-xl-block"
      // style={{background:"#fff"}}
    >
      <h1
        style={{
          zIndex: -1,
          left: "50%",
          top: "-165px",
          marginLeft: "-320px",
          color: "#f7f7f7",
        }}
        className="nicdark_position_absolute nicdark_third_font nicdark_font_weight_lighter nicdark_font_size_180"
      >
        Booking
      </h1>
      <div className="nicdark_section nicdark_text_align_center">
        <div className="nicdark_margin_auto nicdark_display_inline_block">
          {/* Left Section */}
          <div
            style={{ width: "308px", marginLeft: "32px" }}
            className="nicdark_float_left nicdark_text_align_left nicdark_plugin_real_left"
          >
            <div className="nicdark_section">
              <div className="nicdark_section nicdark_height_90 nicdark_plugin_real_feature_first_space"></div>
              {/* Features */}
              {features.map((feature, index) => (
                <div key={index}>
                  <div className="nicdark_section nicdark_position_relative nicdark_plugin_real_feature">
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#c7a383",
                        top: "0px",
                        left: "5px",
                      }}
                      className="nicdark_custom_gradient_2 nicdark_border_radius_100_percentage nicdark_position_absolute nicdark_left_0 nicdark_text_align_center"
                    >
                      <span className="nicdark_color_white nicdark_font_size_20 nicdark_line_height_40">
                        {feature.number}
                      </span>
                    </div>
                    <div className="nicdark_section nicdark_padding_left_70 nicdark_box_sizing_border_box">
                      <h3 className="nicdark_letter_spacing_2 nicdark_font_size_16">
                        <span className="nicdark_plugin_real_feature_little_number nicdark_display_none">
                          {feature.number}
                        </span>
                        {feature.title}
                      </h3>
                      <div className="nicdark_section nicdark_height_5"></div>
                      <p className="">{feature.description}</p>
                    </div>
                  </div>

                  <div className="nicdark_section nicdark_height_100 nicdark_plugin_real_feature_space"></div>
                </div>
              ))}
              {/* More features */}
            </div>
          </div>
          {/* Center Section */}
          <div
            style={{ width: "880px" }}
            className="nicdark_float_left nicdark_box_shadow_0_7_20_000 nicdark_plugin_real"
          >
            <div className="nicdark_plugin_real_one nicdark_section nicdark_text_align_center nicdark_position_relative">
              <div className="nicdark_fadein_fadeout nicdark_section nicdark_position_relative">
                <img
                  style={{}}
                  alt=""
                  className="nicdark_transition_all_08_ease nicdark_section"
                  // src="http://www.nicdarkthemes.com/themes/hotel/wp/demo/intro/img/parallax/plugin-3-new.jpg"
                  src="/Images/homepage.png"
                />
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div
            style={{ width: "290px", marginLeft: "50px" }}
            className="nicdark_float_left nicdark_text_align_left nicdark_plugin_real_right"
          >
            <div className="nicdark_section">
              <div className="nicdark_section nicdark_height_90 nicdark_plugin_real_feature_first_space"></div>
              {/* Features */}

              {rightFeatures.map((feature, index) => (
                <div key={index}>
                  {" "}
                  <div className="nicdark_section nicdark_position_relative nicdark_plugin_real_feature">
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#c7a383",
                        top: "0px",
                        left: "5px",
                      }}
                      className="nicdark_custom_gradient_2 nicdark_border_radius_100_percentage nicdark_position_absolute nicdark_left_0 nicdark_text_align_center"
                    >
                      <span className="nicdark_color_white nicdark_font_size_20 nicdark_line_height_40">
                        {feature.number}
                      </span>
                    </div>
                    <div className="nicdark_section nicdark_padding_left_70 nicdark_box_sizing_border_box">
                      <h3 className="nicdark_letter_spacing_2 nicdark_font_size_16">
                        <span className="nicdark_plugin_real_feature_little_number nicdark_display_none">
                          {feature.number}
                        </span>
                        {feature.title}
                      </h3>
                      <div className="nicdark_section nicdark_height_5"></div>
                      <p className="">{feature.description}</p>
                    </div>
                  </div>
                  <div className="nicdark_section nicdark_height_100 nicdark_plugin_real_feature_space"></div>
                </div>
              ))}
              {/* More features */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSystem;
